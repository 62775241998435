import { FormInstance, Select, Spin, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import classes from './../CruiseDetailsForm.module.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { CruiseRouteType, CruiseRouteUpdatePortType } from '../../../../types/cruiseType'
import { sortBy } from 'lodash'
import { selectCurrentCruise } from '../../../../store/cruisesReducer'
import { GetAllPortsThunk, selectPortList, setPorts } from '../../../../store/portsReducer'
import axios from './../../../../helpers/axiosHelper'
import { selectCruiseFetchParams } from '../../../../store/appStatusReducer'
import redDot from './../../../../img/icons/redDot.png'
import { PortTypeType } from '../../../../types/portTypes'

const RoutesBlock: React.FC<RoutesBlockPropTypes> = ({form}) => {
  const currentCruise = useAppSelector(selectCurrentCruise)
  const fetchParams = useAppSelector(selectCruiseFetchParams)

  const [updatedRoutePorts, setUpdatedRoutePorts] = useState<PortChangedValue[]>([])
  const [addedRoutePorts, setAddedRoutePorts] = useState<PortChangedValue[]>([])
  const [editingRouteId, setEditingRouteId] = useState(0)
  const [distanceDataPairs, setDistanceDataPairs] = useState<DistancePairDataType[]>([])

  const sortedRoutes = sortBy(currentCruise?.cruise_routes, r => Number(r.sequence))

  useEffect(() => {
    const getShowDistanceStartEndId = (index: number): DistancePairDataType | undefined => {
      if (!!fetchParams?.min_port_distance && sortedRoutes?.[index]?.distance_to_next_route >= fetchParams?.min_port_distance) {
        const getEndRouteId = (routeIndex: number):string => {
          if (sortedRoutes?.[routeIndex]?.port?.id) {
            return String(sortedRoutes?.[routeIndex].id)
          } else {
            return getEndRouteId(routeIndex + 1)
          }
        }
        return {
          startRoute: String(sortedRoutes?.[index]?.id),
          endRoute: getEndRouteId(index + 1),
          distance: String(Math.round((sortedRoutes?.[index]?.distance_to_next_route + Number.EPSILON) * 100) / 100)
        }
      } else {
        return
      }
    }

    if (!distanceDataPairs?.length && !!fetchParams?.min_port_distance && !!sortedRoutes?.some(r => r?.distance_to_next_route >= fetchParams?.min_port_distance!)) {
      const distancePairs = sortedRoutes?.reduce((accumulator: any[], route: any, index: number) => {
        const showDistanceStartEndId = getShowDistanceStartEndId(index)
        return !!showDistanceStartEndId?.startRoute ? accumulator = [...accumulator, showDistanceStartEndId] : accumulator
      }, [])
      setDistanceDataPairs(distancePairs)
    }
  }, [fetchParams, sortedRoutes, distanceDataPairs])

  const handlePortChange = (portData: PortChangedValue) => {
    const withNoPort = !currentCruise?.cruise_routes?.find(route => route.id === portData.cruise_route)?.port?.id
    const isAlreadyAdded = addedRoutePorts?.some(r => r.cruise_route === portData.cruise_route)
    if (isAlreadyAdded || withNoPort) {
      const data = isAlreadyAdded
        ? addedRoutePorts.map(r => r.cruise_route === portData.cruise_route ? portData : r)
        : [...addedRoutePorts, portData]
      setAddedRoutePorts(data)
      form.setFieldValue('addPorts', data)
    } else {
      const isAlreadyUpdated = updatedRoutePorts.some(r => r.cruise_route === portData.cruise_route)
      const data = isAlreadyUpdated
        ? updatedRoutePorts.map(r => r.cruise_route === portData.cruise_route ? portData : r)
        : [...updatedRoutePorts, portData]
      setUpdatedRoutePorts(data)
      form.setFieldValue('changePorts', data)
    }
  }

  return (
    <div className={classes.formAreaWrapper}>
      <div className={classes.formBlock}>
        <div className={classes.blockTitle}>
          Cruise routes
        </div>
        {sortedRoutes?.map((route) => {
          const updatedRoutePortData = updatedRoutePorts?.find(r => r.cruise_route === route.id)
          const addedRoutePortData = addedRoutePorts?.find(r => r.cruise_route === route.id)
          const routeData = {
            ...route,
            port: {
              ...(route?.port || {}),
              ...(updatedRoutePortData ? updatedRoutePortData : {}),
              ...(addedRoutePortData ? addedRoutePortData : {})
            }
          }
          const showDistanceLineData = distanceDataPairs?.find(pair => +pair.startRoute === route?.id)
          return (
            <div key={routeData.id}>
              <div className={classes.routeLabel}>
                {routeData.sequence === '1'
                  ? `Port start ${routeData?.port?.code ? '| code' : ''}`
                  : routeData.sequence === String(currentCruise?.cruise_routes?.length)
                    ? `Port end ${routeData?.port?.code ? '| code' : ''}`
                    : `Name of port ${routeData?.port?.code ? '| code' : ''}`
                }
              </div>
              <div className={classes.routeItem} id={String(route?.id)}>
                <div style={{color: '#4F5B67', position: 'relative'}}>
                  {showDistanceLineData?.startRoute && +showDistanceLineData?.startRoute === route.id &&
                    <DistanceConnectionLine showDistanceLineData={showDistanceLineData}/>
                  }
                  Day {routeData.day}.
                </div>
                <PortItem 
                  route={routeData}
                  setEditingRouteId={setEditingRouteId}
                  handlePortChange={handlePortChange}
                  editingId={editingRouteId}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const DistanceConnectionLine: React.FC<{showDistanceLineData: DistancePairDataType}> = ({showDistanceLineData}) => {
  const [lineHeight, setLineHeight] = useState(0)

  const additionalDistanceForHeight = 15//25

  useEffect(() => {
    const calculateHeights = () => {
      const startDiv = document.getElementById(showDistanceLineData.startRoute)
      const endDiv = document.getElementById(showDistanceLineData.endRoute)

      if (startDiv && endDiv) {
        const startDivRect = startDiv.getBoundingClientRect()
        const endDivRect = endDiv.getBoundingClientRect()

        const heightBetweenDivs = endDivRect.top - startDivRect.bottom
        setLineHeight(heightBetweenDivs)
      }
    }
    calculateHeights()
    window.addEventListener('resize', calculateHeights)

    return () => {
      window.removeEventListener('resize', calculateHeights)
    }
  // eslint-disable-next-line
  }, [])

  return (
    <Tooltip
      color='#FEF3F2'
        title={
        <div
          style={{
            display: 'flex',
            padding: '2px 8px 2px 6px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '6px',
            fontSize: '12px',
            fontWeight: '500',
            color: 'red',
          }}
        >
          <img src={redDot} alt='' style={{width: '5px'}} />
          {showDistanceLineData?.distance} km
        </div>
      }
    >
      <div
        style={{
          position: 'absolute',
          left: '-10px',
          top: '16px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={redDot} alt='' style={{width: '5px'}} />
        <svg height={lineHeight + additionalDistanceForHeight + 'px'} width="2">
          <line x1="1" y1="0" x2="1" y2="100%" stroke="red" strokeWidth="1.2" strokeDasharray="5 5" />
        </svg>
        <img src={redDot} alt='' style={{width: '5px'}} />
      </div>
    </Tooltip>
  )
}

const PortItem:React.FC<PortFieldPropTypes> = ({route, setEditingRouteId, handlePortChange, editingId}) => {
  if (editingId === route.id) {
    return (
      <PortField
        route={route}
        setEditingRouteId={setEditingRouteId}
        handlePortChange={handlePortChange}
        editingId={editingId}
      />
    )
  }
  return (
    <>
      {route?.port?.name && !route.port?.is_unk && 
        <>
          <div>
            {route?.port?.name} {route.port?.code ? '| ' + route.port?.code : ''} ({String(route.port?.port_type) === 'null' ? 'Port' : route.port?.port_type?.toLowerCase()?.charAt(0)?.toUpperCase() + route.port?.port_type?.toLowerCase()?.slice(1)})
          </div>
          <div
            style={{color: '#3A36DB', fontWeight: 600, cursor: 'pointer'}}
            onClick={() => setEditingRouteId(route.id)}
          >
            Edit
          </div>
          <div
            style={{color: '#3A36DB', fontWeight: 600, cursor: 'pointer'}}
            onClick={() => handlePortChange({
              cruise_route: route.id,
              old_port_id: route?.port?.id,
              new_port_id: route?.port?.id,
              name: route?.port?.name,
              code: route?.port?.code,
              is_unk: true
            })}
          >
            Unknown
          </div>
        </>
      }
      {!!route?.port?.is_unk && 
        <>
          <div style={{color: '#4F5B67'}}>
            {route?.name} (UNK)
          </div>
          <div
            style={{color: '#3A36DB', fontWeight: 600, cursor: 'pointer'}}
            onClick={() => setEditingRouteId(route.id)}
          >
            Edit
          </div>
          <div
            style={{color: '#3A36DB', fontWeight: 600, cursor: 'pointer'}}
            onClick={() => handlePortChange({
              cruise_route: route.id,
              old_port_id: route?.port?.id,
              new_port_id: route?.port?.id,
              name: route?.port?.name,
              code: route?.port?.code,
              is_unk: false
            })}
          >
            Unset UNK
          </div>
        </>
      }
      {!Object.keys(route?.port || {})?.length &&
        <>
          <div style={{color: '#4F5B67'}}>
            {route?.name} (No port defined)
          </div>
          <div
            style={{color: '#3A36DB', fontWeight: 600, cursor: 'pointer'}}
            onClick={() => setEditingRouteId(route.id)}
          >
            Edit
          </div>
        </>
      }
    </>
  )
}

const PortField: React.FC<PortFieldPropTypes> = ({route, setEditingRouteId, handlePortChange}) => {
  const dispatch = useAppDispatch()
  const ports = useAppSelector(selectPortList)
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()

  const [portSearchVal, setPortSearchVal] = useState('')
  const [isLoading,  setIsLoading] = useState(false)
  const [changedValue, setChangedValue] = useState<PortChangedValue>({} as PortChangedValue)

  useEffect(() => {
    if (!!portSearchVal.length) {
      setIsLoading(true)
      dispatch(GetAllPortsThunk({
        fetchParams: {
          search_filter: 'ALL',
          is_cruise: null,
          name: portSearchVal,
          search_param: null
        }, 
        source
      }))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, portSearchVal])

  const selectPort = (data: PortChangedValue) => {
    setChangedValue(data)
    setPortSearchVal('')
    dispatch(setPorts([]))
  }

  const cancelEditing = () => {
    setChangedValue({} as PortChangedValue)
    setEditingRouteId(0)
  }

  return (
    <>
      <Select
        showSearch
        style={{ width: '400px' }}
        placeholder='Start typing port name or LOCODE'
        // onSearch={searchQuery => /^[a-zA-Z0-9 ]*$/.test(searchQuery) && setPortSearchVal(searchQuery)}
        onSearch={searchQuery => setPortSearchVal(searchQuery)}
        onSelect={(_: any, option: {value: number, children: string[], key: string}) => {
          selectPort({
            cruise_route: route.id,
            old_port_id: route?.port?.id,
            new_port_id: option.value,
            name: option.children[option.children.length - 2],
            code: option.children[0],
            port_type: option.key?.split('type:')[1] as PortTypeType,
            is_unk: false
          })
        }}
        searchValue={portSearchVal}
        labelInValue
        filterOption={false}
        notFoundContent={isLoading ? (
          <Spin size='small' />
        ) : (
          <>
            {!!portSearchVal.length && !ports.length && 'No results found'}
            {!portSearchVal.length && !ports.length && 'Start typing port name or LOCODE'}
          </>
        )}
      >
        {ports.map((p:any) => (
          <Select.Option key={p.id + 'type:' + p?.port_type} value={p.id}>
            {p.code} ({p.name})
          </Select.Option>
        ))}
      </Select>
      <div
        style={{color: '#3A36DB', fontWeight: 600, cursor: 'pointer'}}
        onClick={() => cancelEditing()}
      >
        Cancel
      </div>
      <div
        style={{color: '#3A36DB', fontWeight: 600, cursor: 'pointer'}}
        onClick={() => {
          if (!!Object.keys(changedValue)?.length) {
            handlePortChange(changedValue)
          }
          cancelEditing()
        }}
      >
        Save
      </div>
    </>
  )
}

export default RoutesBlock

interface RoutesBlockPropTypes {
  form: FormInstance
}

interface PortFieldPropTypes {
  setEditingRouteId: (id: number) => void 
  handlePortChange: (data: PortChangedValue) => void
  editingId: number
  route: CruiseRouteType
}

interface PortChangedValue extends CruiseRouteUpdatePortType {
  name?: string
  code?: string
  port_type?: PortTypeType
}

interface DistancePairDataType {
  startRoute: string
  endRoute: string
  distance: string
}
