import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CancelTokenSource } from 'axios'
import { terminalAPI } from '../app/api'
import { NodeType } from '../types/serviceTypes'
import { NodeOptionType, TerminalFetchParams, TerminalType } from '../types/terminalTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

export const terminalNodeTypes = [
  {title: 'Airport', value: 'Airport'},
  {title: 'Flight', value: 'Flight'},
  {title: 'City', value: 'City'},
  {title: 'Airline', value: 'Airline'},
  {title: 'Country', value: 'Country'},
  {title: 'Service', value: 'Service'},
  {title: 'Service Category', value: 'ServiceCategory'},
  {title: 'Port', value: 'Port'},
  {title: 'Railway Station', value: 'RailwayStation'},
  {title: 'Bus Station', value: 'BusStation'}
]

interface InitialStateType {
  terminals: TerminalType[]
  terminals_total_count: number
  current_terminal: TerminalType
  node_options: NodeType[]
}

const initialState: InitialStateType = {
  terminals: [],
  terminals_total_count: 0,
  current_terminal: {} as TerminalType,
  node_options: []
}

export const terminalsSlice = createSlice({
  name: 'terminals',
  initialState,
  reducers: {
    setTerminals: (state, action: PayloadAction<TerminalType[]>) => {state.terminals = action.payload},
    setCurrentTerminal: (state, action: PayloadAction<TerminalType>) => {state.current_terminal = action.payload},
    setNodeOptions: (state, action: PayloadAction<NodeType[]>) => {state.node_options = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllTerminalsThunk.fulfilled, (state, action) => {
        state.terminals = action.payload.terminals
        state.terminals_total_count = action.payload.total_count
      })
      .addCase(GetTerminalByIdThunk.fulfilled, (state, action) => {
        state.current_terminal = action.payload
      })
      .addCase(CreateTerminalThunk.fulfilled, (state, action) => {
        state.terminals = [...state.terminals, action.payload]
      })
      .addCase(EditTerminalThunk.fulfilled, (state, action) => {
        state.terminals = state.terminals.map(terminal => {
          return terminal.id === action.payload.id ? action.payload : terminal
        })
      })
      .addCase(DeleteTerminalThunk.fulfilled, (state, action) => {
        state.terminals = state.terminals.filter(terminal => terminal.id !== action.payload)
      })
      .addCase(GetNodeOptionsThunk.fulfilled, (state, action) => {
        state.node_options = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.terminals = []
        state.current_terminal = {} as TerminalType
      })
  }
})

export const { setTerminals, setCurrentTerminal, setNodeOptions } = terminalsSlice.actions

export const selectTerminalList = (state: RootState): TerminalType[] => state.terminals.terminals
export const selectTerminalsTotalCount = (state: RootState): number => state.terminals.terminals_total_count
export const selectCurrentTerminal = (state: RootState): TerminalType => state.terminals.current_terminal
export const selectNodeOptions = (state: RootState): NodeType[] => state.terminals.node_options

export const GetAllTerminalsThunk = createAsyncThunk<{terminals: TerminalType[], total_count: number}, {fetchParams: TerminalFetchParams, source: any}, AsyncThunkConfig>(
  'terminals/getAllTerminals',
  async (fetchParamsData, thunkAPI) => {
    try {
      const { status, data } = await terminalAPI.getAllTerminals(fetchParamsData.fetchParams, fetchParamsData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetTerminalByIdThunk = createAsyncThunk<TerminalType, number, AsyncThunkConfig>(
  'terminals/getTerminalById',
  async (terminalId, thunkAPI) => {
    try {
      const { status, data } = await terminalAPI.getTerminalById(terminalId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateTerminalThunk = createAsyncThunk<TerminalType, any, AsyncThunkConfig> (
  'terminals/createTerminal',
  async (terminalData, thunkAPI) => {
    try {
      const { status, data } = await terminalAPI.createTerminal(terminalData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Terminal has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditTerminalThunk = createAsyncThunk<TerminalType, {terminal_request: any, terminalId: number}, AsyncThunkConfig> (
  'terminals/editTerminal',
  async (terminalData, thunkAPI) => {
    try {
      const { status, data } = await terminalAPI.editTerminal(terminalData.terminal_request, terminalData.terminalId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Terminal has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteTerminalThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'terminals/deleteTerminal',
  async (terminalId, thunkAPI) => {
    try {
      const { status, data } = await terminalAPI.deleteTerminal(terminalId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(terminalId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetNodeOptionsThunk = createAsyncThunk<NodeType[], {name: string, type: NodeOptionType, source: CancelTokenSource}, AsyncThunkConfig> (
  'terminals/getNodeOptions',
  async (nodeData, thunkAPI) => {
    try {
      const { status, data } = await terminalAPI.getNodeOptions(nodeData.name, nodeData.type, nodeData.source)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data.nodes, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default terminalsSlice.reducer
 