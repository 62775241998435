import { useCallback, useEffect, useState } from 'react'
import { FormInstance, Select, Spin } from 'antd'
import classes from './../ConnectionField.module.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetNodesByCodeThunk, selectNodeList, setNodeList } from '../../../../store/servicesReducer'
import { debounce } from 'lodash'
import axios from './../../../../helpers/axiosHelper'
import { ServiceType } from '../../../../types/serviceTypes'
import { AdvertisementType } from '../../../../types/advertisingTypes'

const SelectNode: React.FC<ConnectionOptionPropTypes> = ({
  form,
  currentEditingData,
  isEditing,
  initialValues,
  disabled,
  handleNodeDeselect,
  handleNodeSelect
}) => {
  const dispatch = useAppDispatch()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const nodes = useAppSelector(selectNodeList)

  const [searchQuery,  setSearchQuery] = useState('')
  const [selectedValues, setSelectedValues] = useState<{label: string, value: number}[]>([])
  const [isLoading,  setIsLoading] = useState(false)

  useEffect(() => {
    if (!!searchQuery.length) {
      setIsLoading(true)
      dispatch(GetNodesByCodeThunk({code: searchQuery, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, searchQuery])

  useEffect(() => {
    if (!!isEditing && !!currentEditingData?.nodes?.length && currentEditingData?.nodes?.[0]?.label?.toLowerCase() !== 'city' && currentEditingData?.nodes?.[0]?.label?.toLowerCase() !== 'country') {
      setSelectedValues(currentEditingData?.nodes!.map(node => ({label: `${node.code} (${node.name})`  || '', value: node.id})))
      form.setFieldsValue({
        nodes: currentEditingData?.nodes?.map(n => n.id),
        node_id_list: currentEditingData?.nodes?.map(n => n.id)
      })
    } else {
      setSelectedValues([])
    }
  }, [currentEditingData, isEditing, form])

  const handleSelect = (selectedValue: {value: number, label: string}) => {
    handleNodeSelect && handleNodeSelect(selectedValue.value, (nodeTypes?: string[]) => nodeTypes!.some(t => t === 'City' || t === 'Country' || t === 'Cruise'))
    const updatedValues = [...selectedValues, selectedValue]
    form.setFieldsValue({...initialValues, node_id_list: updatedValues.map(val => val.value)})
    setSelectedValues(updatedValues)
    setSearchQuery('')
    dispatch(setNodeList([]))
  }

  const handleDeselect = ({value}: {value: number}) => {
    !!handleNodeDeselect && handleNodeDeselect(value)
    const updatedValues = selectedValues.filter(val => val.value !== value)
    form.setFieldsValue({...initialValues, node_id_list: updatedValues.map(val => val.value)})
    setSelectedValues(updatedValues)
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchQuery: string) => {
      setSearchQuery(searchQuery)
    }, 350),
    []
  )

  return (
    <>
      <div className={classes.explanationText}>
        Select Node Codes (You can enter one or many codes)
      </div>
      <Select
        placeholder='Please select codes (example: TGV, ISA, YTZ)'
        showSearch
        mode='multiple'
        onSelect={(_: any, option: {value: number, children: string}) => handleSelect({value: option.value, label: option.children})}
        onDeselect={handleDeselect}
        onSearch={handleSearchDebounce}
        style={{width: '65%'}}
        filterOption={false}
        value={selectedValues}
        labelInValue
        disabled={disabled}
        notFoundContent={isLoading ? (
          <Spin size='small' />
        ) : (
          <>
            {!!searchQuery.length && !nodes.length && 'No results found'}
            {!searchQuery.length && !nodes.length && 'Start typing node code'}
          </>
        )}
      >
        {nodes.map(node => (
          <Select.Option value={node.id} key={node.id}>
            {node.code} ({node.name})
          </Select.Option>
        ))}
      </Select>
    </>
  )
}

interface ConnectionOptionPropTypes {
  form: FormInstance
  isEditing: boolean
  initialValues: any
  currentEditingData?: ServiceType | AdvertisementType | null
  disabled?: boolean
  handleNodeDeselect?: (nodeId: number) => void
  handleNodeSelect?: (nodeId: number, removeCopiedNodes?: (nodeTypes?: string[]) => boolean) => void
}

export default SelectNode
