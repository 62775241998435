import { useLocation } from 'react-router-dom'
import classes from './GTFSLogList.module.css'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetGTFSLogListThunk, selectGtfsLogList, selectGtfsLogListFetchParams, selectGtfsLogListTotalCount, setGtfsLogListFetchParams } from '../../../store/gtfsReducer'
import axios from './../../../helpers/axiosHelper'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import ItemList, { GetAdditionalColumnType } from '../../common/ItemList/ItemList'

const GTFSLogList = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const gtfsLogList = useAppSelector(selectGtfsLogList)
  const gtfsLogListTotalCount = useAppSelector(selectGtfsLogListTotalCount)
  const fetchParams = useAppSelector(selectGtfsLogListFetchParams)

  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    // pathname example: /gtfs/logs/111
    const id = location.pathname.split('/gtfs/logs/')[1]

    setIsDataLoading(true)
    dispatch(GetGTFSLogListThunk({fileId: +id, fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  return (
    <>
      <div className={classes.pageHeader}>
        <Breadcrumbs />
        <h1>
          {gtfsLogList?.[0]?.archive_name}
        </h1>
      </div>
      <ItemList
        items={gtfsLogList || []}
        loading={isDataLoading}
        pagination={fetchParams}
        setPagination={(pagination: any) => dispatch(setGtfsLogListFetchParams({...fetchParams, ...pagination}))}
        total={gtfsLogListTotalCount}
        columnList={[]}
        rowIdKey='file_id'
        getAdditionalColumns={getAdditionalTableColumns}
      />
    </>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'File name',
      dataIndex: 'file_name',
      key: 'file_name',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('file_name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('file_name', column.sortOrder)
      }),
      width: 230
    },
    {
      title: 'Row index',
      dataIndex: 'row_index',
      key: 'row_index',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('row_index'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('row_index', column.sortOrder)
      }),
      width: 130
    },
    {
      title: 'Message',
      dataIndex: 'log_message',
      key: 'log_message',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('log_message'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('log_message', column.sortOrder)
      }),
    },
  ])
}

export default GTFSLogList
