import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CreateAboutPageThunk, GetAboutPageThunk, UpdateAboutPageThunk, selectAboutPage } from '../../../store/blogReducer'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './AboutPageDetailsForm.module.css'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Divider, Input, Popover, Spin } from 'antd'
import ReactQuill from 'react-quill'
import UploadFileField from '../../common/UploadFileField/UploadFileField'
import { getImageUrl } from '../../../helpers/files_helper'
import { ReactComponent as QuestionMarkIcon } from './../../../img/icons/questionMark.svg'
import { AboutPageType } from '../../../types/blogTypes'

const AboutPageDetailsForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const pageData = useAppSelector(selectAboutPage)

  const [parentBlock, setParentBlock] = useState<AboutPageType>()
  const [blocks, setBlocks] = useState<AboutPageType[]>()
  // const [images, setImages] = useState<({uid: string, url: string, file: any} | undefined)[]>([])
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (pageData === null) {
     dispatch(GetAboutPageThunk())
    } else if (pageData?.id) {
      setParentBlock({
        ...pageData,
        image_title: pageData?.image_list?.[0]?.image_title,
        image_comment: pageData?.image_list?.[0]?.comment
      })
      setBlocks(pageData.child_sections.map(section => ({
        ...section,
        image_title: section?.image_list?.[0]?.image_title,
        image_comment: section?.image_list?.[0]?.comment
      })))
    } else {
      setParentBlock({
        type: 'ABOUT',
        title: '',
        content: '',
        section_order: 0,
        child_sections: [],
        image_list: [{
          image_title: '',
          comment: '',
          list_index: 0
        }]
      })
      setBlocks([{
        type: 'ABOUT',
        title: '',
        content: '',
        section_order: 1,
        child_sections: [],
        image_list: [{
          image_title: '',
          comment: '',
          list_index: 1
        }]
      }])
    }
   }, [dispatch, pageData])

  const addBlock = () => {
    const orderNumber = blocks?.filter(b => b?.action !== 'DELETE')?.length
    setBlocks([
      ...blocks || [], 
      {
        type: 'ABOUT',
        title: '',
        content: '',
        section_order: (orderNumber ? orderNumber + 1 : 1),
        child_sections: [],
        image_list: [{
          image_title: '',
          comment: '',
          list_index: (orderNumber ? orderNumber + 1 : 1)
        }]
      }
    ])
  }

  const removeBlock = (index: number) => {
    if (pageData?.id && !!blocks?.[index - 1]?.id) {
      setBlocks(blocks?.map((b, i) => i + 1 === index
        ? {...b, action: 'DELETE'}
        : i + 1 > index ? {...b, section_order: i} : b
      ))
    } else {
      setBlocks(blocks?.filter((b, i) => i + 1 !== index))
    }
  }

  const addBannerImage = async(file:any, index: number) => {
    const imageUrl = await getImageUrl(file)
    if (index === 0) {
      setParentBlock({
        ...parentBlock!,
        image_list: [
          {
            image_title: parentBlock!.image_list?.[0]?.image_title || '',
            comment: parentBlock!.image_list?.[0]?.comment || '',
            file: {uid: file.uid, url: imageUrl, file}
          },
          ...(parentBlock?.image_list?.some(i => i?.image_url)
            ? parentBlock!.image_list?.filter(i => i?.image_url)?.map(i => ({...i, action: 'DELETE' as 'DELETE'}))
            : []
          )
        ]
      })
    } else {
      setBlocks(blocks?.map((b, i) => i + 1 === index
        ? {
          ...b,
          image_list: [
            {
              image_title: b!.image_list?.[0]?.image_title || '',
              comment: b!.image_list?.[0]?.comment || '',
              file: {uid: file.uid, url: imageUrl, file}
            },
            ...(blocks?.[i]?.image_list?.some(i => i?.image_url)
              ? b!.image_list?.filter(i => i?.image_url)?.map(i => ({...i, action: 'DELETE' as 'DELETE'}))
              : []
            )
          ]
        }
        : b
      ))
    }
  }

  const deleteBannerImage = (blockIndex: number, imageUrl?: string) => {
    if (blockIndex === 0) {
      setParentBlock({
        ...parentBlock!,
        image_list: imageUrl
          ? parentBlock!.image_list.map(i => i?.image_url === imageUrl ? {...i, action: 'DELETE'} : i)
          : []
      })
    } else {
      setBlocks(blocks?.map((b, i) => i+1 === blockIndex 
        ? {
          ...b,
          image_list: imageUrl
            ? b.image_list.map(i => i?.image_url === imageUrl ? {...i, action: 'DELETE'} : {...i})
            : []
        }
        : b
      ))
    }
  }

  const getValidationError = (values: AboutPageType[]) => {
    if (values?.some(v => !v?.content?.length)) {
      return 'Make sure all blocks have content!'
    } else if (values?.some(v => !!v?.image_list?.[0]?.file?.url && !v?.image_comment?.length)) {
      return 'Make sure all images have alternative text!'
    }
  }

  const getFormattedFormData = (): {parentBlockUpdated: AboutPageType, images: any} => {
    const images: any[] = []
    const updatedParentBlock = {
      ...parentBlock!,
      // action: 'DELETE' as 'DELETE',
      image_list: parentBlock!.image_list.map(i => !!i?.file ? {
        ...i,
        list_index: 0,
        file: undefined,
        image_title: parentBlock?.image_title || '',
        comment: parentBlock?.image_comment || ''
      } : {
        ...i,
        image_title: parentBlock?.image_title || '',
        comment: parentBlock?.image_comment || '',
        list_index: undefined
      }),
      image_title: undefined,
      image_comment: undefined
    }
    if (!!parentBlock?.image_list?.some(i => !!i?.file)) {
      images.push(parentBlock?.image_list?.find(i => !!i?.file)?.file?.file)
    }
    const updatedBlocks = blocks?.map(section => {
      if (!!section?.image_list?.some(i => !!i?.file?.url)) {
        images.push(section?.image_list?.find(i => !!i?.file?.url)?.file?.file)
      }
      return {
        ...section,
        image_list: section.image_list?.map(i => i.file?.url ? {
          ...i,
          list_index: images?.length - 1,
          file: undefined,
          image_title: section?.image_title || '',
          comment: section?.image_comment || ''
        } : {
          ...i,
          image_title: section?.image_title || '',
          comment: section?.image_comment || '',
          list_index: undefined
        }),
        image_title: undefined,
        image_comment: undefined
      } 
    })

    return {
      images,
      parentBlockUpdated: {
        ...updatedParentBlock!,
        child_sections: updatedBlocks || []
      }, 
    }
  }

  const saveRequest = async(formattedData: {parentBlockUpdated: AboutPageType, images: any}) => {
    if (pageData?.id) {
      return dispatch(UpdateAboutPageThunk({parentSectionId: pageData?.id, parentBlock: formattedData.parentBlockUpdated, images: formattedData.images}))
    } else {
      return dispatch(CreateAboutPageThunk({blocks: formattedData.parentBlockUpdated, images: formattedData.images}))
    }
  }

  const saveChanges = () => {
    const validationError = getValidationError([parentBlock!, ...blocks || []])
    if (validationError?.length) {
      setError(validationError)
      return
    } else {
      setError('')
      const formattedData = getFormattedFormData()
      setIsSaving(true)
      saveRequest(formattedData)
        .then((resp) => {
          setIsSaving(false)
          !resp?.type.includes('rejected') && navigate('/about-page')
        })
    }
  }

  if (parentBlock?.section_order === undefined) {
    return <Spin style={{width: '100%'}}/>
  }

  return (
    <div className={classes.wrapper}>
      <div>
        <Breadcrumbs />
        <h1>
          Edit About page
        </h1>
      </div>

      <div className={classes.formBlock}>
        <BlockForm
          value={parentBlock!}
          textLabel='Title for the Post'
          imgLabel='Post cover'
          onChange={(updatedValues: {[key: string]:any}) => setParentBlock({...parentBlock!, ...updatedValues})}
          addBannerImage={addBannerImage}
          deleteBannerImage={deleteBannerImage}
        />

        {blocks?.map((block, index) => (
          <BlockForm
            key={block?.id || index + 1}
            value={block!}
            textLabel={`Block ${block.section_order} content`}
            imgLabel={`Block ${block.section_order} image`}
            removeBlock={removeBlock}
            index={index + 1}
            onChange={(updatedValues: {[key: string]:any}, index?: number) => {
              setBlocks(blocks.map((b, i) => {
                if (i + 1 === index) {
                  return {...b, ...updatedValues}
                } else {
                  return b
                }
              }))
            }}
            addBannerImage={addBannerImage}
            deleteBannerImage={deleteBannerImage}
          />
        ))}

        <div className={classes.addBlockBtn} onClick={addBlock}>
          + Add more
        </div>

        {!!error?.length && 
          <div className={classes.error}>
            {error}
          </div>
        }
        <div className={classes.buttons}>
          <Link to='/about-page'>
            <Button>
              Cancel
            </Button>
          </Link>
          <Button
            type='primary'
            loading={isSaving}
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  )
}

const BlockForm: React.FC<BlockFormPropTypes> = ({
  value,
  textLabel,
  imgLabel,
  removeBlock,
  index,
  onChange,
  addBannerImage,
  deleteBannerImage
}) => {
  if (value?.action === 'DELETE') {
    return <></>
  }

  return ((
    <div className={classes.sectionWrapper}>
      <div>
        <div className={classes.label}>
          {textLabel}
        </div>
        <ReactQuill
          theme='snow'
          onChange={(val) => onChange(
            {
              content: val,
            },
            index
          )}
          value={value.content}
          className={classes.textEditor}
          placeholder='Enter section content here...'
        />
      </div>
      <div>
        <div className={classes.label}>
          {imgLabel}
        </div>
        <UploadFileField
          addBannerImage={(file: any) => addBannerImage(file, index || 0)}
          removeBannerImage={() => deleteBannerImage(index || 0, value?.image_list?.[0]?.action === 'DELETE' ? undefined : value?.image_list?.[0]?.image_url)}
          preview={value?.image_list?.some(i => i.file?.url)
            ? value?.image_list?.find(i => i.file?.url)?.file!
            : !!value?.image_list?.[0]?.image_url && value?.image_list?.[0]?.action !== 'DELETE'
              ? {uid: value?.image_list?.[0]?.image_url || '', url: value?.image_list?.[0]?.image_url || ''}
              : null
          }
          disabled={false}
        />
      </div>
      <div>
        <div className={classes.label}>
          Image description
          <Popover content={'The text placed under the image (author of the photo, location etc.)'}>
            <QuestionMarkIcon style={{marginLeft: '5px', cursor: 'pointer', width: '10px', position: 'absolute'}}/>
          </Popover>
        </div>
        <Input
          placeholder='Image description'
          value={value?.image_title || ''}
          onChange={(e) => onChange(
            {image_title: e.target.value},
            index
          )}
        />
      </div>
      <div>
        <div className={classes.label}>
          Image alternative text
          <Popover content={'The text for screen readers and SEO optimization'}>
            <QuestionMarkIcon style={{marginLeft: '5px', cursor: 'pointer', width: '10px', position: 'absolute'}}/>
          </Popover>
        </div>
        <Input
          placeholder='Image alternative text'
          value={value?.image_comment || ''}
          onChange={(e) => onChange(
            {image_comment: e.target.value},
            index
          )}
        />
      </div>
      {!!removeBlock && index !== undefined &&
        <div className={classes.deleteBlock} onClick={() => removeBlock(index)}>
          Delete block
        </div>
      }
      <Divider style={{gridColumn: '1/3'}}/>
    </div>
  ))
}

interface BlockFormPropTypes {
  value: AboutPageType
  textLabel: string
  imgLabel: string
  removeBlock?: (index: number) => void
  index?: number
  onChange: (updatedValues: {[key: string]:any}, index?: number) => void
  addBannerImage: (file: any, index: number) => Promise<void>
  deleteBannerImage: (index: number, imageUrl?: string) => void
}

export default AboutPageDetailsForm
