import { 
  Input,
  Select,
  // Button, Popconfirm, Spin, 
  Tag,
  //  Tooltip
} from 'antd'
import { debounce } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import classes from './EventList.module.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import axios from '../../helpers/axiosHelper'
import { DeleteEventThunk, GetEventListThunk, selectEventList, selectEventListFetchParams, selectEventListTotalCount, setEventListFetchParams } from '../../store/eventReducer'
import { EventType } from '../../types/eventTypes'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'

const EventList = () => {
  const dispatch = useAppDispatch()
  const eventList = useAppSelector(selectEventList)
  const eventListTotalCount = useAppSelector(selectEventListTotalCount)
  const fetchParams = useAppSelector(selectEventListFetchParams)

  // const getViewItemLink = (event: EventType) => {
  //   return '/events/view/' + event.code
  // }

  const deleteItem = (event: EventType): void => {
    dispatch(DeleteEventThunk(+event.code))
  }

  const editItemLink = `/events/edit/`

  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetEventListThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      dispatch(setEventListFetchParams({
        ...fetchParams,
        filter: {...fetchParams.filter, search_value: searchRequest},
        pagination: {...fetchParams.pagination!, page: 1}
      }))
    }, 350),
    [fetchParams]
  )

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Events
        </h1>
        <div className={classes.tools}>
          <div>
            <Select
              value={fetchParams.filter.event_kind}
              onChange={(val) => dispatch(setEventListFetchParams({
                ...fetchParams,
                filter: {...fetchParams.filter, event_kind: val},
                pagination: {...fetchParams.pagination!, page: 1}
              }))}
              style={{width: '175px', marginRight: '10px'}}
              className={classes.resultsFilter}
            >
              <Select.Option value={null}>All formats</Select.Option>
              <Select.Option value={'Location'}>Location</Select.Option>
              <Select.Option value={'Cruise'}>Cruise</Select.Option>
            </Select>
          </div>
          <div>
            <Select
              value={fetchParams.filter.event_type}
              onChange={(val) => dispatch(setEventListFetchParams({
                ...fetchParams,
                filter: {...fetchParams.filter, event_type: val},
                pagination: {...fetchParams.pagination!, page: 1}
              }))}
              style={{width: '175px', marginRight: '10px'}}
              className={classes.resultsFilter}
            >
              <Select.Option value={null}>All types</Select.Option>
              <Select.Option value={'Public'}>Public</Select.Option>
              <Select.Option value={'Private'}>Private</Select.Option>
              <Select.Option value={'Corporate'}>Corporate</Select.Option>
            </Select>
          </div>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.filter.search_value as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
        </div>
      </div>
      <ItemList
        items={eventList || []}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => dispatch(setEventListFetchParams({...fetchParams, pagination}))}
        total={eventListTotalCount}
        columnList={['actions']}
        rowIdKey='code'
        // getViewItemLink={getViewItemLink}
        getAdditionalColumns={getAdditionalTableColumns}
        deleteItem={deleteItem}
        editItemLink={editItemLink}
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Event name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('name', column.sortOrder)
      }),
    },
    {
      title: 'Format',
      dataIndex: 'event_kind',
      key: 'event_kind',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('event_kind'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('event_kind', column.sortOrder)
      }),
    },
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      sorter: false,
      render: (_:any, item: EventType) => (<>{item?.event_location?.name}</>)
    },
    {
      title: 'Type',
      dataIndex: 'event_type',
      key: 'event_type',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('event_type'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('event_type', column.sortOrder)
      }),
      render: (_:any, item: EventType) => (<Tag color='#E6FBD9' style={{color: '#0F5B1D'}}>{item.event_type}</Tag>)
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('code'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('code', column.sortOrder)
      }),
    },
    {
      title: 'Date',
      dataIndex: 'start_date',
      key: 'start_date',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('start_date'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('start_date', column.sortOrder)
      }),
      render: (_:any, item: EventType) => (<>{moment(item.start_date).format('DD.MM.YYYY')} - {moment(item.end_date).format('DD.MM.YYYY')}</>)
    },
  ])
}

export default EventList
