import { Link } from 'react-router-dom'
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs'
import routes from '../../../app/routes'
import classes from './Breadcrumbs.module.css'

const Breadcrumbs: React.FC<BreadcrumbsProps>  = ({className}) => {
  // @ts-ignore
  const breadcrumbs = useReactRouterBreadcrumbs(routes, { excludePaths: ['/']})

  return (
    <>
      {breadcrumbs.map(({
        match,
        breadcrumb
      }, index) => (
        <span key={match.pathname} className={classes.wrapper}>
          {index + 1 < breadcrumbs.length ? (
            <Link to={match.pathname} className={classes.crumb}>
              {breadcrumb} <span className={classes.separator}>/</span>
            </Link>
          ) : (
            <span className={classes.currentCrumb}>
              {breadcrumb}
            </span>
          )}
        </span>
      ))}
    </>
  )
}

interface BreadcrumbsProps {
  className?: string
}

export default Breadcrumbs
