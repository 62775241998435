import { Button, Select, Input } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from './../../helpers/axiosHelper'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import classes from './BusStationList.module.css'
import ItemList from '../common/ItemList/ItemList'
import { TransportCategoryFetchParams } from '../../types/appTypes'
import { selectBusStationFetchParams, setBusStationFetchParams } from '../../store/appStatusReducer'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'
import { debounce } from 'lodash'
import { GetAllBusStationsThunk, selectBusStationList, selectBusStationTotalCount } from '../../store/busStationsReducer'
import { BusStationType } from '../../types/busStationTypes'

const BusStationList = () => {
  const dispatch = useAppDispatch()
  const bus_stations = useAppSelector(selectBusStationList)
  const bus_stations_total_count = useAppSelector(selectBusStationTotalCount)
  const fetchParams = useAppSelector(selectBusStationFetchParams)

  const [tableData, setTableData] = useState<BusStationType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllBusStationsThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(bus_stations)
  }, [bus_stations])

  // const deleteItem = (busStation: BusStationType): void => {
  //   dispatch(DeleteBusStationThunk(busStation.id))
  // }

  const setFetchParams = (params: TransportCategoryFetchParams) => {
    dispatch(setBusStationFetchParams(params))
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, name: encodeURI(searchRequest)})
    }, 350),
    [fetchParams]
  )

  const editItemLink = '/bus-stations/edit/'

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Bus Station List
        </h1>
        <Link to='/bus-stations/create'>
          <Button type='primary'>+ Add New</Button>
        </Link>
      </div>
      <div className={classes.tools}>
        <div>
          <Select
            value={fetchParams.search_filter}
            onChange={(val) => setFetchParams({...fetchParams, search_filter: val})}
            style={{width: '200px'}}
            className={classes.resultsFilter}
          >
            <Select.Option value={'ALL'}>Show all</Select.Option>
            <Select.Option value={'NOT_APPROVED'}>Not approved only</Select.Option>
            {/* <Select.Option value={'DONT_DISPLAY'}>Don't display only</Select.Option> */}
          </Select>
        </div>
        <div>
          <Input.Group compact style={{display:'flex'}}>
            <Select
              style={{ width: '187px' }}
              value={fetchParams.search_param}
              onChange={(val) => setFetchParams({...fetchParams, search_param: val as 'NAME' | 'CODE'})}
            >
              <Select.Option value={'NAME'}>By name</Select.Option>
              <Select.Option value={'CODE'}>By code</Select.Option>
              <Select.Option value={null}>By both</Select.Option>
            </Select>
            <Input
              placeholder='Search'
              suffix={<SearchIcon />}
              defaultValue={decodeURIComponent(fetchParams.name as string)}
              onChange={(e) => handleSearchDebounce(e.target.value.trim())}
              style={{ width: '240px' }}
            />
          </Input.Group>
        </div>
      </div>
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        // deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={bus_stations_total_count}
        columnList={['name', 'code', 'country.name', 'location', 'actions']}
        rowIdKey='id'
        transportType='bus'
      />
    </div>
  )
}

export default BusStationList
