import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { airlineAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { AirlineFetchParamsType, AirlineType, AllianceType } from '../types/airlineTypes'

interface InitialStateType {
  airlines: AirlineType[]
  airlines_total_count: number
  currentAirline: AirlineType

  alliances: AllianceType[]
}

const initialState: InitialStateType = {
  airlines: [],
  airlines_total_count: 0,
  currentAirline: {} as AirlineType,

  alliances: []
}

export const airlinesSlice = createSlice({
  name: 'airlines',
  initialState,
  reducers: {
    setAirlines: (state, action: PayloadAction<AirlineType[]>) => {state.airlines = action.payload},
    setCurrentAirline: (state, action: PayloadAction<AirlineType>) => {state.currentAirline = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllAirlinesThunk.fulfilled, (state, action) => {
        state.airlines = action.payload.airlines
        state.airlines_total_count = action.payload.total_count
      })
      .addCase(GetAirlineByIdThunk.fulfilled, (state, action) => {
        state.currentAirline = action.payload
      })
      .addCase(CreateAirlineThunk.fulfilled, (state, action) => {
        state.airlines = [...state.airlines, action.payload]
      })
      .addCase(EditAirlineThunk.fulfilled, (state, action) => {
        state.airlines = state.airlines.map(airline => {
          return airline.id === action.payload.id ? action.payload : airline
        })
      })
      .addCase(DeleteAirlineThunk.fulfilled, (state, action) => {
        state.airlines = state.airlines.filter(airline => airline.id !== action.payload)
      })
      .addCase(GetAllAlliancesThunk.fulfilled, (state, action) => {
        state.alliances = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.airlines = []
        state.airlines_total_count = 0
        state.currentAirline = {} as AirlineType
      })
  }
})

export const { setAirlines, setCurrentAirline } = airlinesSlice.actions

export const selectAirlineList = (state: RootState): AirlineType[] => state.airlines.airlines
export const selectAirlineTotalCount = (state: RootState): number => state.airlines.airlines_total_count
export const selectCurrentAirline = (state: RootState): AirlineType => state.airlines.currentAirline
export const selectAllianceList = (state: RootState): AllianceType[] => state.airlines.alliances

export const GetAllAirlinesThunk = createAsyncThunk<{airlines: AirlineType[], total_count: number}, {fetchParams: AirlineFetchParamsType, source: any}, AsyncThunkConfig>(
  'airlines/getAllAirlineList',
  async (fetchParamsData, thunkAPI) => {
    try {
      const { status, data } = await airlineAPI.getAllAirlines(fetchParamsData.fetchParams, fetchParamsData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetAirlineByIdThunk = createAsyncThunk<AirlineType, number, AsyncThunkConfig>(
  'airlines/getAirlineById',
  async (airlineId, thunkAPI) => {
    try {
      const { status, data } = await airlineAPI.getAirlineById(airlineId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateAirlineThunk = createAsyncThunk<AirlineType, any, AsyncThunkConfig> (
  'airlines/createAirline',
  async (airlineData, thunkAPI) => {
    try {
      const { status, data } = await airlineAPI.createAirline(airlineData)
      if (status === 201 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Airline has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditAirlineThunk = createAsyncThunk<AirlineType, {airlineData: any, airlineId: number}, AsyncThunkConfig> (
  'airlines/editAirline',
  async (airlineData, thunkAPI) => {
    try {
      const { status, data } = await airlineAPI.editAirline(airlineData.airlineData, airlineData.airlineId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Airline has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteAirlineThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'airlines/deleteAirline',
  async (airlineId, thunkAPI) => {
    try {
      const { status, data } = await airlineAPI.deleteAirline(airlineId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(airlineId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAllAlliancesThunk = createAsyncThunk<AllianceType[], void, AsyncThunkConfig>(
  'airlines/getAllAllianceList',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await airlineAPI.getAllianceList()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.alliances, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default airlinesSlice.reducer
 