import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './FAQEntryForm.module.css'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { Button, Input } from 'antd'
import ReactQuill from 'react-quill'
import { CreateFaqEntryThunk, EditFaqEntryThunk, GetFaqEntryByIdThunk, selectCurrentFaqEntry, setCurrentFaqEntry } from '../../../store/faqReducer'
import { getImageUrl } from '../../../helpers/files_helper'
import { FaqImageType, NewFaqEntryType } from '../../../types/faqTypes'
import UploadImage from './UploadImage/UploadImage'

const FAQEntryForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentEntry = useAppSelector(selectCurrentFaqEntry)

  const questionMaxLength = 70

  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState('')
  const [formValues, setFormValues] = useState<NewFaqEntryType>({} as NewFaqEntryType)

  useEffect(() => {
    if (isEditing) {
      // pathname example: /faq/edit/1111
      const id = location.pathname.split('edit/')[1]
      dispatch(GetFaqEntryByIdThunk(+id))
    }
    return () => {dispatch(setCurrentFaqEntry(null))}
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && currentEntry?.id) {
      setFormValues({
        ...formValues,
        id: currentEntry?.id,
        title: currentEntry.title,
        content: currentEntry.content,
        image_list: currentEntry?.image_list?.map(image => ({
          preview: {uid: image?.image_url!, url: image?.image_url!},
          image_html: image?.image_html || '',
          id: image?.id,
        })) || [],
      })
    } else if (!formValues?.type?.length) {
      setFormValues({
        type: 'FAQ' as "FAQ",
        title: '',
        content: '',
        section_order: 1,
        image_list: [],
      })
    }
    // eslint-disable-next-line
  }, [isEditing, currentEntry])

  const addImage = async(file:any) => {
    const imageUrl = await getImageUrl(file)
    setFormValues({
      ...formValues,
      image_list: [
        ...formValues.image_list || [],
        {
          file,
          preview: {uid: file.uid, url: imageUrl},
          image_html: '',
          image_title: file.originFileObj?.name || '',
        }
      ]
    })
  }

  const removeImage = async(uid: string, isNew: boolean) => {
    const updatedImages = formValues?.image_list?.map(img => img?.preview?.uid === uid ? {...img, action: 'DELETE' as "DELETE"} : img)
    setFormValues({
      ...formValues,
      image_list: isNew ? [] : updatedImages,
    })
  }

  const saveChanges = () => {
    if (!formValues?.title?.length) {
      setError('Please enter a title')
      return
    } else if (!formValues?.content.replace(/<\/?[^>]+(>|$)/g, '').trim()?.length) {
      setError('Please enter a response')
      return
    } else {
      setError('')
      setIsSaving(true)
      if (isEditing) {
        const images: any[] = []
        const updatedEntry = {
          ...formValues,
          image_list: formValues!.image_list?.map((i:any) => ({
            list_index: !!i?.file ? 0 : undefined,
            file: undefined,
            image_title:  i?.image_title || '',
            image_html: i?.image_html || '',
            id: !!i?.file ? undefined : i?.id,
            action: !!i?.file ? undefined : i?.action,
          }))
        }
        if (!!formValues?.image_list?.some((i:any) => !!i?.file)) {
          images.push(formValues?.image_list?.find((i: any) => !!i?.file)?.file)
        }
        dispatch(EditFaqEntryThunk({entryId: currentEntry?.id!, faqEntry: updatedEntry, images}))
        .then((resp) => {
          setIsSaving(false)
          !resp?.type.includes('rejected') && navigate('/faq')
        })
      } else {
        const images = formValues.image_list
        const content = {
          ...formValues,
          image_list: formValues.image_list?.map((i, index) => ({
            image_title: i?.file?.originFileObj?.name || '',
            image_html: i?.image_html,
            list_index: index,
          }))
        }
        dispatch(CreateFaqEntryThunk({content, images}))
        .then((resp) => {
          setIsSaving(false)
          !resp?.type.includes('rejected') && navigate('/faq')
        })
      }
    }
  }

  return (
    <>
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentEntry?.title : 'Adding a new question'}
        </h1>
      </div>

      <div className={classes.formBlock}>
        <div className={classes.fieldBlock}>
          <div className={classes.label}>
            Title (Question)
          </div>
          <Input
            placeholder='Enter question'
            onChange={(e) => setFormValues({...formValues, title: e.target.value})}
            value={formValues.title}
            maxLength={questionMaxLength}
            showCount
          />

          <div className={classes.label} style={{marginTop: '20px'}}>
            Content (Answer)
          </div>
          <ReactQuill
            theme='snow'
            onChange={(val) => {
              if (!isEditing || formValues?.id) {
                setFormValues({...formValues, content: val})
              }
            }}
            value={formValues.content}
            className={classes.textEditor}
            placeholder='Enter answer content here...'
          />
        </div>

        <div className={classes.label} style={{marginTop: '20px'}}>
          Image
        </div>

        <UploadImage
          addPhoto={addImage}
          removePhoto={(uid: string, isNew: boolean) => removeImage(uid, isNew)}
          images={formValues.image_list || []}
          updateImagesData={(images: FaqImageType[]) => setFormValues({
            ...formValues,
            image_list: images.map(i => ({...i, image_html: i?.image_html || '', action: i?.action}))
          })}
        />

        {!!error?.length && 
          <div className={classes.error}>
            {error}
          </div>
        }
        <div className={classes.buttons}>
          <Link to='/faq'>
            <Button>
              Cancel
            </Button>
          </Link>
          <Button
            type='primary'
            loading={isSaving}
            onClick={saveChanges}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default FAQEntryForm
