import { DatePicker, Form, FormInstance, Select, Spin } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetCruiseAvailableDatesThunk, GetCruiseLineOptionsThunk, GetCruisesByLineIdThunk, selectCruiseDateOptions, selectCruiseLineOptions, selectCruiseOptions, selectCurrentEvent, setCruiseDateOptions, setCruiseLineOptions, setCruiseOptions } from '../../../store/eventReducer'
import { debounce, sortBy, uniqWith } from 'lodash'
import { CruiseType } from '../../../types/cruiseType'
import axios from 'axios'
import moment, { Moment } from 'moment'
import classes from './EventDetailsPage.module.css'

const CruiseEventFields: React.FC<{form: FormInstance}> = ({form}) => {
  const dispatch = useAppDispatch()
  const availableDates = useAppSelector(selectCruiseDateOptions)
  const currentEvent = useAppSelector(selectCurrentEvent)
  
  const [isLoading, setIsLoading] = useState(false)
  const [lineId, setLineId] = useState(0)
  const [cruiseId, setCruiseId] = useState(0)

  useEffect(() => {
    if (lineId === 0 && !!currentEvent?.event_cruise?.cruise_line_id) {
      setLineId(currentEvent?.event_cruise?.cruise_line_id)
    }
  }, [currentEvent, lineId])

  useEffect(() => {
    return () => {
      dispatch(setCruiseOptions(null))
      dispatch(setCruiseLineOptions(null))
      dispatch(setCruiseDateOptions(null))
    }
  }, [dispatch])

  const getAvailableDates = (lineId: number, cruiseId?: number[]) => {
    if (!cruiseId && lineId) {
      dispatch(GetCruiseAvailableDatesThunk({cruise_line: lineId}))
    } else if (cruiseId && lineId) {
      dispatch(GetCruiseAvailableDatesThunk({cruise_line: lineId, cruise_id_list: cruiseId}))
    }
  }

  useEffect(() => {
    if (!availableDates?.some(d => moment(d?.departure_date).isSame(form.getFieldValue('cruiseDate'), 'day'))) {
      form.setFieldsValue({date: undefined})
    }
  }, [availableDates, form])

  const checkIsDateAvailable = (current: Moment):boolean => {
    return current < moment().startOf('day') || !availableDates?.some(d => moment(d.departure_date).format('YYYY-MM-DD') === current.format('YYYY-MM-DD'))
  }

  return (
    <>
      <CruiseLineField
        form={form}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        setLineId={setLineId}
        getAvailableDates={getAvailableDates}
      />
      <CruiseField
        form={form}
        getAvailableDates={getAvailableDates}
        isLoading={isLoading}
        lineId={lineId}
        placeholder={!lineId ? 'Select cruise line first' : 'Start typing cruise name'}
        setCruiseId={(val:any) => setCruiseId(val)}
        disabled={!lineId}
      />
      <AvailableDatesDatePicker
        form={form}
        checkIsDateAvailable={(current) => checkIsDateAvailable(current)}
        datePickerPlaceholder={isLoading 
          ? 'Loading options'
          : availableDates === null
            ? 'Select cruise line first'
            : !!cruiseId ? 'No available dates found' : 'Select cruise first'
        }
        datePickerDisabled={!availableDates?.length || isLoading || !cruiseId}
        closestAvailableDate={availableDates?.[0] ? moment(availableDates?.[0]?.departure_date) : undefined}
      />
    </>
  )
}

export const CruiseLineField = ({
  setLineId,
  form,
  getAvailableDates,
  isLoading,
  setIsLoading,
  style,
  disabled,
}:any) => {
  const dispatch = useAppDispatch()
  const [lineName, setLineName] = useState('')
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const cruiseLineOptions = useAppSelector(selectCruiseLineOptions)

  const [isSelectOpen, setIsSelectOpen] = useState(false)

  useEffect(() => {
    if (!!lineName.length) {
      dispatch(setCruiseLineOptions(null))
      setIsLoading(true)
      dispatch(GetCruiseLineOptionsThunk({name: lineName, source}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    }
    return () => {source.cancel()}
  // eslint-disable-next-line
  }, [dispatch, lineName])

  const selectCruiseLine = (lineId: number) => {
    setLineId(lineId)
    getAvailableDates(lineId)
    form.setFieldsValue({
      cruiseName: undefined,
      cruiseLine: lineId,
      cruiseLineName: cruiseLineOptions?.find(c => c.id === lineId)?.name,
      cruiseDate: undefined
    })
  }

  // eslint-disable-next-line
  const onSearch = useCallback(
    debounce((val) => {
      setLineName(val)
    }, 400), []
  )

  return (
    <div id='cruise-line-select' style={{width: '100%'}}>
      <Form.Item
        name='cruiseLine'
        style={{width: '100%'}}
        rules={[{required: true, message: 'Please select cruise line!'}]}
      >
        <Select
          placeholder='Enter the cruise line'
          showSearch
          onSelect={(lineData: any) => selectCruiseLine(lineData.value)}
          onSearch={onSearch}
          style={{width: '100%', height: '56px', fontSize: '18px', ...style}}
          filterOption={false}
          getPopupContainer={() => document.getElementById('cruise-line-select')!}
          labelInValue
          disabled={disabled}
          open={isSelectOpen}
          autoClearSearchValue={false}
          onDropdownVisibleChange={(isOpen => setIsSelectOpen(isLoading || isOpen))}
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!lineName.length && !cruiseLineOptions?.length && 'No results found'}
              {!lineName.length && !cruiseLineOptions?.length && 'Start typing line name'}
            </>
          )}
        >
          {cruiseLineOptions?.map((line:any) => (
            <Select.Option value={line.id} key={line.id}>
              {line.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export const CruiseField = ({
  form,
  getAvailableDates,
  isLoading,
  lineId,
  placeholder,
  setCruiseId,
  disabled,
  style,
  className
}:any) => {
  const dispatch = useAppDispatch()
  const cruiseOptions = useAppSelector(selectCruiseOptions)

  const [cruiseName, setCruiseName] = useState('')
  const [isCruiseOptionsLoading, setIsCruiseOptionsLoading] = useState(false)
  const [options, setOptions] = useState<CruiseType[] | null>(null)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if (cruiseName?.length) {
      setIsCruiseOptionsLoading(true)
      dispatch(GetCruisesByLineIdThunk({
        lineId,
        data: {cruise_name: cruiseName, with_cruise_routes: false},
        source
      })).then(() => setIsCruiseOptionsLoading(false))
      return() => {source.cancel()}
    }
    // eslint-disable-next-line
  }, [dispatch, cruiseName])

  // eslint-disable-next-line
  const onCruiseSearch = useCallback(
    debounce((name: string) => {
      setCruiseName(name)
    }, 400), []
  )

  useEffect(() => {
    setOptions(getOnlyUniqueCruiseName(sortBy(cruiseOptions, cruise => cruise.name)))
    // eslint-disable-next-line
  }, [cruiseOptions])

  const getOnlyUniqueCruiseName:(opt: CruiseType[]) => CruiseType[] = (cruiseNameOptions: CruiseType[]) => {
    const uniqNames = uniqWith(
      cruiseNameOptions,
      (optA, optB) => optA.name.trim() === optB.name.trim() && optA?.display_info === optB?.display_info
    )
    // return uniqNames
    if (cruiseName?.length) {
      return uniqNames.filter(n => n.name?.toLowerCase().includes(cruiseName?.toLowerCase()))
    } else {
      return uniqNames
    }
  }

  const selectCruise = (val: any) => {
    const name = val?.label[0] || ''
    const display_info = val?.label[1]?.props?.children || ''
    const id = val?.value
    form.setFieldsValue({cruiseName: val?.label?.[0], cruiseDate: undefined})
    const duplicatedCruiseId = cruiseOptions
      ?.filter(c => c.name === name && c.display_info === display_info && c.id !== +id)
      ?.map(c => c.id) || []
    getAvailableDates(lineId, [+id, ...duplicatedCruiseId])
  }

  const deselectCruise = () => {
    form.setFieldsValue({cruiseName: undefined})
    getAvailableDates(lineId)
  }

  return (
    <div id='cruise-select' style={{width: '100%', ...style}} className={className}>
      <Form.Item
        name='cruiseName'
        style={{width: '100%'}}
      >
        <Select
          placeholder={isCruiseOptionsLoading// || isDynamicSearchLoading
            ? 'Loading options'
            : placeholder ? placeholder : !lineId
              ? 'Select cruise line first'
              : 'Enter the cruise name'
          }
          showSearch
          onClear={undefined}
          onSelect={(val:any) => {
            selectCruise(val)
            setCruiseId(val)
          }}
          onDeselect={deselectCruise}
          style={{width: '100%', height: '56px', ...style}}
          filterOption={false}
          labelInValue
          // onSearch={v => {
          // const filtered = cruiseOptions?.filter(o => o?.name?.toLowerCase().includes(v.toLowerCase()))
          //   setOptions(getOnlyUniqueCruiseName(sortBy(filtered, cruise => cruise.name)))
          // }}
          onSearch={v => onCruiseSearch(v)}
          getPopupContainer={() => document.getElementById('cruise-select')!}
          autoClearSearchValue={false}
          disabled={disabled !== undefined ? disabled : !lineId || isLoading || isCruiseOptionsLoading}
          notFoundContent={isCruiseOptionsLoading ? ( //|| isDynamicSearchLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {cruiseOptions === null ? 'Enter the cruise name' : 'No results found'}
            </>
          )}
        >
          {options?.map((cruise:any) => (
            <Select.Option
              className={classes.cruiseNameOption}
              value={cruise.id}
              key={cruise.id + cruise?.display_info}
            >
              {cruise.name.trim()} 
              <span style={{color: 'gray', fontSize: '14px', marginLeft: '7px'}}>
                {!!cruise?.display_info?.length && cruise?.display_info}
              </span>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

interface AvailableDatesDatePickerPropTypes {
  form: FormInstance
  hasFormatError?: boolean
  checkIsDateAvailable: (current: Moment) => boolean
  datePickerPlaceholder?: string
  datePickerDisabled: boolean
  dateIsLoading?: boolean
  closestAvailableDate: Moment | undefined
  style?: object
}

export const AvailableDatesDatePicker:React.FC<AvailableDatesDatePickerPropTypes> = ({
  form,
  hasFormatError,
  checkIsDateAvailable,
  datePickerPlaceholder,
  datePickerDisabled,
  dateIsLoading,
  closestAvailableDate,
  style
}) => {
  const availableDates = useAppSelector(selectCruiseDateOptions)

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)

  const disableDatesWithNoResults = (current: any) => {
    if (!!checkIsDateAvailable) {
      return checkIsDateAvailable(current)
    } else {
      return current < moment().startOf('day')
    }
  }

  return (
      <Form.Item
        name='cruiseDate'
        style={{width: '100%', fontSize: '16px'}}
        rules={[{required: true, message: `Please select cruise date!`}]}
      >
        <DatePicker
          onSelect={(v) => {
            form.setFieldValue('cruiseDeparture', availableDates?.find(o => o.departure_date === v.format('YYYY-MM-DD'))?.name)
          }}
          placeholder={datePickerPlaceholder && datePickerDisabled
            ? datePickerPlaceholder
            : `Select the date of your cruise`
          }
          suffixIcon={dateIsLoading && <Spin />}
          style={{width: '100%', ...style}}
          disabledDate={(current) => {
            return disableDatesWithNoResults(current)
          }}
          showToday={false}
          disabled={datePickerDisabled || !!hasFormatError}
          open={isDatePickerOpen}
          allowClear={false}
          onOpenChange={(isOpen) => setIsDatePickerOpen(isOpen)}
          renderExtraFooter={() => (
            <div
              className={classes.datePickerFooter}
              onClick={async() => {
                form.setFieldValue('cruiseDeparture', availableDates?.find(o => o.departure_date === moment(closestAvailableDate).format('YYYY-MM-DD'))?.name)
                await Promise.resolve(form.setFieldValue('cruiseDate', moment(closestAvailableDate)))
                setIsDatePickerOpen(false)
              }}
            >
              {'Select closest available date:\n'}{moment(closestAvailableDate).format('YYYY-MM-DD')}
            </div>
          )}
          dateRender={current => {
            const style: React.CSSProperties = {}
            if (!disableDatesWithNoResults(current)) {
              style.backgroundColor = '#191587'
              style.color = 'white'
              style.borderColor = '#191587'
              style.cursor = 'pointer'
            }
            return (
              <div style={style}>
                {moment(current).format('DD')}
              </div>
            )
          }}
        />
      </Form.Item>
  )
}

export default CruiseEventFields
