import { useEffect, useState } from 'react'
import { Button, Form, Spin } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { pickBy } from 'lodash'
import classes from './ServiceDetailsForm.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CreateServiceThunk, EditServiceThunk, GetAllServiceCategoriesThunk, GetServiceByIdThunk, selectCurrentService, setCurrentService } from '../../../store/servicesReducer'
import { ServiceType } from '../../../types/serviceTypes'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import { GetAllCountriesThunk, GetDistanceUnitsThunk } from '../../../store/locationsReducer'
import ConnectionFields from './ConnectionFields/ConnectionFields'
import ContactFields from './ContactFields/ContactFields'
import MainData from './MainData/MainData'
import { getConnectionValidationError, getUpdateSettings } from '../../../helpers/serviceConnectionsHelper'
import { getHTMLValidationError } from '../../../helpers/files_helper'

export enum ConnectionOptions {
  Nodes,
  Coordinates,
  City,
  Country,
  Global
}

const ServiceDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const currentService = useAppSelector(selectCurrentService)
  const [form] = Form.useForm()

  const [editingServiceId, setEditingServiceId] = useState(0)
  const [selectedConnectionOption, setSelectedConnectionOption] = useState(ConnectionOptions.Nodes)
  const [deleteLogo, setDeleteLogo] = useState(false)
  const [bannerColor, setBannerColor] = useState('rgba(87,190,192,0.1)')
  const [selectedBannerOption, setSelectedBannerOption] = useState<'generated' | 'html'>('generated')
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (isEditing) {
      // pathname example: /services/edit/47895
      const id = location.pathname.split('edit/')[1]
      setEditingServiceId(+id)
      dispatch(GetServiceByIdThunk(+id))
    }
    dispatch(GetAllServiceCategoriesThunk())
    dispatch(GetAllCountriesThunk())
    dispatch(GetDistanceUnitsThunk())
    return () => {dispatch(setCurrentService({} as ServiceType))}
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && !!Object.keys(currentService)?.length) {
      currentService.banner_background_color !== null && setBannerColor(currentService.banner_background_color)
      form.setFieldsValue({
        ...currentService,
        ...(currentService?.nodes?.length 
          ? {
            nodes: currentService?.nodes?.map(n => n.id),
            node_id_list: currentService?.nodes?.map(n => n.id)
          }
          : {}
        ),
        allowed_user_location: currentService.allowed_user_location || [],
        is_active: currentService.is_active === false ? false : true
      })
      setSelectedBannerOption(currentService.active_banner_type || 'generated')
      if (!!currentService?.latitude_north || !!currentService?.latitude || !!currentService?.radius_area) {
        setSelectedConnectionOption(ConnectionOptions.Coordinates)
      } else if (!!currentService?.nodes?.length) {
        if (currentService.nodes[0]?.label?.toLowerCase() === 'city') {
          setSelectedConnectionOption(ConnectionOptions.City)
        } else if (currentService.nodes[0]?.label?.toLowerCase() === 'country') {
          setSelectedConnectionOption(ConnectionOptions.Country)
        } else {
          setSelectedConnectionOption(ConnectionOptions.Nodes)          
        }
      } else {
        setSelectedConnectionOption(ConnectionOptions.Global)
      }
    } else if (!isEditing) {
      form.setFieldsValue({is_active: true})
    }
  }, [currentService, form, isEditing, dispatch])

  const sendData = async(formData: any) => {
    if (isEditing) {
      return dispatch(EditServiceThunk({serviceData: formData, serviceId: editingServiceId}))
    } else {
      return dispatch(CreateServiceThunk({serviceData: formData}))
    }
  }

  const handleSave = async() => {
    const formValues = form.getFieldsValue(true)
    const error = getConnectionValidationError(formValues, selectedConnectionOption, ConnectionOptions)
    const bannerValidationError = selectedBannerOption === 'html' ? getHTMLValidationError(formValues.banner_html) : ''

    if (error?.length || bannerValidationError?.length) {
      setError(error || bannerValidationError) 
      return
    } else {
      setError('')
    }
    const connectionKeys=['nodes', 'node_id_list']
    const serviceData: any = {
      ...pickBy(formValues, (_, key) => key !== 'logo_url' && key !== 'logo'),
      phones: formValues?.phones?.filter((p: string) => p.length > 0) || [],
      ...(formValues?.node_id_list?.length ? {node_id_list: formValues?.node_id_list} : {}),
      banner_background_color: bannerColor
    }

    const formData = new FormData()
    !!formValues?.logo && formData.append('logo', formValues.logo.originFileObj)
 
    if (isEditing) {
      const {
        add_relations_with_node_ids,
        delete_relations_with_node_ids,
      } = getUpdateSettings(serviceData, currentService)

      formData.append('update_settings', new Blob([JSON.stringify({
        delete_logo: deleteLogo,
        add_relations_with_node_ids,
        delete_relations_with_node_ids,
        delete_point_coordinates: !!serviceData?.nodes?.length || !!serviceData.is_global || (!serviceData?.radius_area && !serviceData?.address) || !!serviceData.node_id_list?.length,
        delete_area_coordinates: !!serviceData?.nodes?.length || !!serviceData.is_global || !!serviceData?.address || !!serviceData.node_id_list?.length,
        delete_radius: !serviceData?.radius_area
      }, null, 2)], {type: 'application/json'}))
    }
    formData.append('service_entity', new Blob([JSON.stringify(isEditing ? (
      {...pickBy(serviceData, (_, key) => !connectionKeys.includes(key))} 
    ) : (
      serviceData
    ), null, 2)], {type: 'application/json'}))

    setIsSaving(true)
    sendData(formData)
      .then((resp) => {
        setIsSaving(false)
        !resp?.type.includes('rejected') && navigate('/services')
      })
  }

  if (isEditing && !Object.keys(currentService).length) {
    return <Spin className={classes.spinner} />
  }

  return (
    <Form
      name='serviceDetailsForm'
      onFinish={handleSave}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentService?.name : 'Adding a new service'}
        </h1>
      </div>

      <div className={classes.mainInfoArea}>
        <MainData
          form={form}
          isEditing={isEditing}
          setDeleteLogo={setDeleteLogo}
          setBannerColor={setBannerColor}
          selectedBannerOption={selectedBannerOption}
          setSelectedBannerOption={setSelectedBannerOption}
        />

        <ContactFields
          isEditing={isEditing}
          form={form}
          bannerColor={bannerColor}
          selectedBannerOption={selectedBannerOption}
        />
      </div>

      <div className={classes.formBlock}>
        <ConnectionFields
          selectedConnectionOption={selectedConnectionOption}
          setSelectedConnectionOption={setSelectedConnectionOption}
          form={form}
          isEditing={isEditing}
          setError={setError}
        />
      </div>

      {!!error.length && <div className={classes.error}>{error}</div>}

      <div className={classes.buttonsAreaWrapper}>
        <Link to='/services'>
          <Button>
            Cancel
          </Button>
        </Link>
        <Button
          type='primary'
          htmlType='submit'
          loading={isSaving}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

export default ServiceDetailsForm
