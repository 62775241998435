import { TokenDataHelperType, UserDataHelperType } from '../types/localStorageDataTypes'

export const tokenDataHelper: TokenDataHelperType = {
  setTokenData(data) {
    localStorage.setItem('tokenData', JSON.stringify(data))
  },
  getTokenData() {
    const tokenData = localStorage.getItem('tokenData')
    return !!tokenData && JSON.parse(tokenData)
  },
  removeTokenData() {
    return localStorage.removeItem('tokenData')
  },
}

export const userDataHelper: UserDataHelperType = {
  setUserData(data) {
    localStorage.setItem('userData', JSON.stringify(data))
  },
  setUserAvatar(avatarUrl) {
    const userData = localStorage.getItem('userData')
    if (!!userData && !!avatarUrl) {
      localStorage.setItem('userData', JSON.stringify({...JSON.parse(userData), photo_url: avatarUrl}))
    }
  },
  getUserData() {
    const userData = localStorage.getItem('userData')
    return !!userData && JSON.parse(userData)
  },
  removeUserData() {
    return localStorage.removeItem('userData')
  }
}
