import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input } from 'antd'
import { debounce } from 'lodash'
import axios from './../../helpers/axiosHelper'
import classes from './AirlineList.module.css'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GetAllAirlinesThunk, selectAirlineList, selectAirlineTotalCount } from '../../store/airlinesReducer'
import { selectAirlineFetchParams, setAirlineFetchParams } from '../../store/appStatusReducer'
import { AirlineFetchParamsType, AirlineType } from '../../types/airlineTypes'

const AirlineList = () => {
  const dispatch = useAppDispatch()
  const airlines = useAppSelector(selectAirlineList)
  const airlines_total_count = useAppSelector(selectAirlineTotalCount)
  const fetchParams = useAppSelector(selectAirlineFetchParams)

  const [tableData, setTableData] = useState<AirlineType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllAirlinesThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(airlines)
  }, [airlines])

  // const deleteItem = (airline: AirlineType): void => {
  //   dispatch(DeleteAirlineThunk(airline.id))
  // }

  const setFetchParams = (params: AirlineFetchParamsType) => {
    dispatch(setAirlineFetchParams(params))
  }
  
  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, name: encodeURI(searchRequest)})
    }, 350),
    [fetchParams]
  )

  const editItemLink = '/airlines/edit/'

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Airline List
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.name as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
          <Link to='/airlines/create'>
            <Button type='primary'>+ Add New</Button>
          </Link>
        </div>
      </div>      
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        // deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={airlines_total_count}
        columnList={['actions']}
        rowIdKey='id'
        getAdditionalColumns={getAdditionalTableColumns}
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Short Name ',
      dataIndex: 'short_name',
      key: 'short_name',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('short_name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('short_name', column.sortOrder)
      }),
    },
    {
      title: 'IATA',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('code'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('code', column.sortOrder)
      }),
    },
    {
      title: 'ICAO',
      dataIndex: 'icao_code',
      key: 'icao_code',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('extended_code'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('extended_code', column.sortOrder)
      }),
    },
    {
      title: 'Website',
      dataIndex: 'url',
      key: 'url',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('url'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('url', column.sortOrder)
      }),
      render: (_:any, item: AirlineType) => (
        item?.url ? (
          <a className={item?.url?.length ? classes.link : ''} href={item?.url} target='_blank' rel='noreferrer'>
            {item?.url}
          </a>
        ) : (
          <></>
        )
      )
    },
    {
      title: 'Alliance',
      dataIndex: 'alliance_name',
      key: 'alliance_name',
      ellipsis: true,
      sorter: false,
    },
  ])
}

export default AirlineList
