import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cruiseAPI } from '../app/api'
import { CruiseFetchParamsType, CruiseRouteShortDataType, CruiseRouteUpdatePortType, CruiseType } from '../types/cruiseType'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

interface InitialStateType {
  cruises: CruiseType[]
  cruiseTotalCount: number
  currentCruise: CruiseType

  cruiseRoutesByLocode: null | CruiseRouteShortDataType[]
}

const initialState: InitialStateType = {
  cruises: [],
  cruiseTotalCount: 0,
  currentCruise: {} as CruiseType,

  cruiseRoutesByLocode: null
}

export const cruisesSlice = createSlice({
  name: 'cruises',
  initialState,
  reducers: {
    setCruises: (state, action: PayloadAction<CruiseType[]>) => {state.cruises = action.payload},
    setCruiseTotalCount: (state, action: PayloadAction<number>) => {state.cruiseTotalCount = action.payload},
    setCurrentCruise: (state, action: PayloadAction<CruiseType>) => {state.currentCruise = action.payload},
    setCruiseRoutesByLocode: (state, action: PayloadAction<null | CruiseRouteShortDataType[]>) => {state.cruiseRoutesByLocode = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCruisesThunk.fulfilled, (state, action) => {
        state.cruises = action.payload
      })
      .addCase(GetCruiseListThunk.fulfilled, (state, action) => {
        state.cruises = action.payload.cruises
        state.cruiseTotalCount = action.payload.total_count
      })
      .addCase(GetCruiseByIdThunk.fulfilled, (state, action) => {
        state.currentCruise = action.payload
      })
      .addCase(GetCruiseRoutesByLocodeThunk.fulfilled, (state, action) => {
        state.cruiseRoutesByLocode = action.payload || []
      })
      .addCase(GetCruiseRoutesByLocodeThunk.rejected, (state, action) => {
        state.cruiseRoutesByLocode = []
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.cruises = []
        state.cruiseTotalCount = 0
        state.currentCruise = {} as CruiseType
        state.cruiseRoutesByLocode = null
      })
  }
})

export const {
    setCruises,
    setCruiseTotalCount,
    setCurrentCruise,
    setCruiseRoutesByLocode
  } = cruisesSlice.actions

export const selectCruiseList = (state: RootState): CruiseType[] => state.cruises.cruises
export const selectCruiseTotalCount = (state: RootState): number => state.cruises.cruiseTotalCount
export const selectCurrentCruise = (state: RootState): CruiseType => state.cruises.currentCruise
export const selectCruiseRoutesByLocode = (state: RootState): null | CruiseRouteShortDataType[] => state.cruises.cruiseRoutesByLocode

export const GetAllCruisesThunk = createAsyncThunk<CruiseType[], {name: string, source: any}, AsyncThunkConfig>(
  'cruiseLines/getAllCruises',
  async (requestData, thunkAPI) => {
    try {
      const { status, data } = await cruiseAPI.getAllCruises(requestData.name, requestData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruises, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetCruiseListThunk = createAsyncThunk<{cruises: CruiseType[], total_count: number}, {fetchParams: CruiseFetchParamsType, source: any}, AsyncThunkConfig>(
  'cruiseLines/getCruiseList',
  async (requestData, thunkAPI) => {
    try {
      const { status, data } = await cruiseAPI.getCruiseList(requestData.fetchParams, requestData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetCruiseByIdThunk = createAsyncThunk<CruiseType, {id: number, startDate?: string}, AsyncThunkConfig>(
  'cruises/getCruiseById',
  async (requestData, thunkAPI) => {
    try {
      const { status, data } = await cruiseAPI.getCruiseById(requestData.id, requestData?.startDate)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const CreateCruiseThunk = createAsyncThunk<CruiseType, CruiseType, AsyncThunkConfig>(
  'cruises/createCruise',
  async (cruiseData, thunkAPI) => {
    try {
      const { status, data } = await cruiseAPI.createCruise(cruiseData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const EditCruiseThunk = createAsyncThunk<CruiseType, CruiseType, AsyncThunkConfig>(
  'cruises/createCruise',
  async (cruiseData, thunkAPI) => {
    try {
      const { status, data } = await cruiseAPI.editCruise(cruiseData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const UpdateCruiseRoutePortsThunk = createAsyncThunk<null, {change_ports: CruiseRouteUpdatePortType[], is_all_itineraries: boolean}, AsyncThunkConfig>(
  'cruises/updateCruiseRoutePorts',
  async (routesData, thunkAPI) => {
    try {
      const { status, data } = await cruiseAPI.updateCruiseRoutePorts(routesData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(null, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const AddPortToCruiseRouteThunk = createAsyncThunk<null, {change_ports: CruiseRouteUpdatePortType[], is_all_itineraries: boolean}, AsyncThunkConfig>(
  'cruises/addPortToCruiseRoute',
  async (routesData, thunkAPI) => {
    try {
      const { status, data } = await cruiseAPI.addPortToCruiseRoute(routesData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(null, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetCruiseRoutesByLocodeThunk = createAsyncThunk<CruiseRouteShortDataType[], number, AsyncThunkConfig>(
  'cruises/getCruiseRoutesByLocode',
  async (portId, thunkAPI) => {
    try {
      const { status, data } = await cruiseAPI.getCruiseRoutesByLocode(portId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruise_routes, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const SwitchCruiseRoutesPortThunk = createAsyncThunk<null, {switch_from_id: number, switch_to_id: number, port_ids: number[]}, AsyncThunkConfig>(
  'cruises/switchCruiseRoutesPort',
  async (portsData, thunkAPI) => {
    try {
      const { status } = await cruiseAPI.switchCruiseRoutesPort(portsData)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(null, {appStatus: AppStatusType.succeeded, appMessage: 'Port for routes has been successfully changed!'})
      } else {
        return thunkAPI.rejectWithValue('Something went wrong')
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default cruisesSlice.reducer
 