import { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Button, Modal, Select, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetCruiseRoutesByLocodeThunk, SwitchCruiseRoutesPortThunk, selectCruiseRoutesByLocode } from '../../../../store/cruisesReducer'
import ItemList, { GetAdditionalColumnType } from '../../../common/ItemList/ItemList'
import { GetAllPortsThunk, selectCurrentPort, selectPortOptions, setPortOptions } from '../../../../store/portsReducer'
import classes from './../PortDetailsForm.module.css'
import { CruiseRouteShortDataType } from '../../../../types/cruiseType'
import axios from './../../../../helpers/axiosHelper'
import { debounce } from 'lodash'

const CruiseRoutesTab = () => {
  const dispatch = useAppDispatch()
  const cruiseRoutes = useAppSelector(selectCruiseRoutesByLocode)
  const currentPort = useAppSelector(selectCurrentPort)

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [selectedRoutes, setSelectedRoutes] = useState<CruiseRouteShortDataType[]>([])
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    if (cruiseRoutes === null && !isDataLoading) {
      setIsDataLoading(true)
      dispatch(GetCruiseRoutesByLocodeThunk(currentPort?.id))
        .then(() => setIsDataLoading(false))
    }
  }, [dispatch, currentPort, cruiseRoutes, isDataLoading])

  return (
    <div>
      <div style={{marginBottom: '10px'}}>
        Select Routes to switch between the ports
      </div>

      <ItemList
        items={cruiseRoutes || []}
        loading={isDataLoading}
        pagination={{page: 1, size: cruiseRoutes?.length || 1}}
        setPagination={() => {}}
        total={cruiseRoutes?.length || 0}
        columnList={[]}
        rowIdKey='port_id'
        rowSelection={{
          onChange: (_, selectedRows: CruiseRouteShortDataType[]) => setSelectedRoutes(selectedRows),
          selectionType: 'checkbox'
        }}
        getAdditionalColumns={getAdditionalTableColumns}
      />

      <div
        className={classes.buttons}
        style={{
          justifyContent: 'end',
          marginTop: '30px',
        }}
      >
        <Link to='/ports'>
          <Button className={classes.navigationBtn}>
            Cancel
          </Button>
        </Link>
        <Button
          type='primary'
          className={classes.navigationBtn}
          onClick={() => setIsModalOpen(true)}
          disabled={!selectedRoutes?.length}
        >
          Switch
        </Button>
      </div>
      <SwitchModal
        isModalOpen={isModalOpen}
        onModalClose={() => setIsModalOpen(false)}
        selectedRoutes={selectedRoutes}
      />
    </div>
  )
}

const SwitchModal:React.FC<{
  isModalOpen: boolean,
  onModalClose: () => void,
  selectedRoutes: CruiseRouteShortDataType[],
}> = ({isModalOpen, onModalClose, selectedRoutes}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const portOptions = useAppSelector(selectPortOptions)
  const currentPort = useAppSelector(selectCurrentPort)

  const [portSearch, setPortSearch] = useState<{query: string, selectedPortId: null | number}>({query: '', selectedPortId: null})
  const [portQueryVal, setPortQueryVal] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

    // eslint-disable-next-line
    const handleSearchDebounce = useCallback(
      debounce((searchRequest: string) => {
        setPortQueryVal(searchRequest)
      }, 350),
      [portQueryVal]
    )

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    if (portQueryVal?.length) {
      setIsSearching(true)
      dispatch(GetAllPortsThunk({
        fetchParams: {
          search_filter: 'ALL',
          is_cruise: null,
          name: portQueryVal,
          search_param: null
        },
        source,
        isOptionList: true
      }))
      .then((resp) => {
        resp?.type?.includes('fulfilled') && setIsSearching(false)
      })
    }
    return () => {
      source.cancel()
      setIsSearching(false)
    }
  }, [dispatch, portQueryVal])

  const switchPort = () => {
    setIsSaving(true)
    dispatch(SwitchCruiseRoutesPortThunk({
      switch_from_id: currentPort?.id!,
      switch_to_id: portSearch?.selectedPortId!,
      port_ids: selectedRoutes.map(r => r.port_id)
    })).then((resp) => {
      setIsSaving(false)
      setPortSearch({query: '', selectedPortId: null})
      !resp.type.includes('rejected') && navigate('/ports')
    })
  }

  return (
    <Modal
      visible={isModalOpen}
      // className={classes.modal}
      footer={false}
      closable={false}
      destroyOnClose
    >
      <div>
        <div style={{fontWeight: 600, marginBottom: '8px'}}>
          Change routes for:
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          {selectedRoutes?.map((route, index) => (
            <div
              key={route?.port_id+'-'+index}
              style={{
                marginRight: index < selectedRoutes?.length - 1 ? '8px' : '',
                fontSize: '14px',
                color: '#667085',
                marginBottom: '20px'
              }}
            >
              <span style={{fontSize: '14px', color: '#344054', fontWeight: 600}}>
                {route.name}
              </span>
              <span style={{fontSize: '14px', marginLeft: '5px'}}>
                {route?.port_id}
              </span>
              {index < selectedRoutes?.length - 1 && ';'}
            </div>
          ))}
        </div>

        <div style={{fontSize: '14px'}}>
          Enter Port name or LOCODE
        </div>
        <div id='port-select'>
          <Select
            onSearch={(e) => {
              setPortSearch({...portSearch, query: e.trim()})
              handleSearchDebounce(e.trim())
            }}
            searchValue={portSearch.query}
            onBlur={() => {
              setPortQueryVal('')
              dispatch(setPortOptions(null))
            }}
            showSearch
            labelInValue
            filterOption={false}
            getPopupContainer={() => document.getElementById('port-select')!}
            placeholder='Search Port name or LOCODE'
            value={portSearch.selectedPortId}
            onSelect={(val:any) => setPortSearch({...portSearch, selectedPortId: val.value})}
            style={{ width: '100%' }}
            notFoundContent={isSearching ? (
              <Spin size='small' />
            ) : (
              <>
                {!!portSearch?.query.length && !portOptions?.length && 'No results found'}
                {!portSearch?.query.length && 'Start typing port name or LOCODE'}
              </>
            )}
          >
            {portOptions?.map((p:any) => (
              <Select.Option key={p.id} value={p.id}>
                {p.name} ({p?.code ? p?.code : p?.port_type})
              </Select.Option>
            ))}
          </Select>
        </div>

        <div
          className={classes.buttons}
          style={{
            justifyContent: 'center',
            marginTop: '30px',
          }}
        >
          <Button
            className={classes.navigationBtn}
            onClick={() => {
              onModalClose()
              setPortSearch({query: '', selectedPortId: null})
            }}
            style={{width: '100%'}}
          >
            Cancel
          </Button>
          <Button
            type='primary'
            className={classes.navigationBtn}
            onClick={switchPort}
            disabled={!portSearch?.selectedPortId}
            loading={isSaving}
            style={{width: '100%'}}
          >
            Switch
          </Button>
        </div>
      </div>
    </Modal>
  )
}
const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Port_ID',
      dataIndex: 'port_id',
      key: 'port_id',
      ellipsis: true,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
    },
    {
      title: 'Latitude',
      dataIndex: 'latitude',
      key: 'latitude',
      ellipsis: true,
    },
    {
      title: 'Longitude',
      dataIndex: 'longitude',
      key: 'longitude',
      ellipsis: true,
    },
  ])
}

export default CruiseRoutesTab
