import { Input } from 'antd'
import { useEffect, useState } from 'react'

const CombinedCoordinatesInput: React.FC<CombinedCoordinatesInputPropTypes> = ({onChange, initialValue, errorMessage, disabled}) => {
  const [coordinateValues, setCoordinateValues] = useState(initialValue || '')

  useEffect(() => {
    !!initialValue?.length && setCoordinateValues(initialValue)
  }, [initialValue])

  const handleCoordinateValueChange = (coordinates: string) => {
    if (coordinates.includes(',') && !coordinates.includes(' ') && coordinates[coordinates.length - 1] !== ',') {
      return
    }
    // coordinates example: "50.49085592975587, 30.486457024483787"
    const containsOnlyAllowedSymbols = /[^a-zA-z]+$|^$/g.test(coordinates)
    const value = coordinates[coordinates.length - 1] === ',' ? coordinates + ' ': coordinates
    if (!!containsOnlyAllowedSymbols) {
      setCoordinateValues(value)
    }

    const isFullCoordinatesString = /-?(\d*\.)?\d+,\s*-?(\d*\.)\d+/.test(coordinates) && !!containsOnlyAllowedSymbols
    if (isFullCoordinatesString) {
      onChange(value, true, false)
    } else if (!coordinates.length) {
      onChange('', false, true)
    } else {
      onChange('', false, false)
    }
  }

  return (
    <div style={{marginBottom: '20px'}}>
      <div style={{fontSize: '14px'}}>
        Coordinates
      </div>
      <Input
        placeholder='Enter coordinates (example: 50.49085592975587, 30.486457024483787)'
        onChange={(e) => handleCoordinateValueChange(e.target.value)}
        value={coordinateValues}
        status={!!errorMessage?.length ? 'error' : ''}
        disabled={disabled}
      />
      {!!errorMessage?.length &&
        <div style={{color: '#ff4d4f'}}>
          {errorMessage}
        </div>
      }
    </div>
  )
}

interface CombinedCoordinatesInputPropTypes {
  onChange: (value: string, isValueCompleted: boolean, isValueEmpty: boolean) => void
  initialValue?: string
  errorMessage?: string
  disabled?: boolean
}

export default CombinedCoordinatesInput
