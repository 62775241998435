import { createSlice, PayloadAction, isRejected, isPending, isFulfilled } from '@reduxjs/toolkit'
import moment from 'moment'
import { TransportCategoryFetchParams } from '../types/appTypes'
import { CityFetchParamsType } from '../types/locationTypes'
import { ServiceFetchParamsType } from '../types/serviceTypes'
import { TerminalFetchParams } from '../types/terminalTypes'
import { TrackingFetchParamsType } from '../types/trackingTypes'
import { fulfilledMetaType, RootState } from './store'
import { AdvertisingFetchParamsType } from '../types/advertisingTypes'
import { CruiseFetchParamsType, CruiseLineFetchParamsType } from '../types/cruiseType'
import { AirlineFetchParamsType } from '../types/airlineTypes'

export enum AppStatusType {'idle', 'loading', 'succeeded', 'failed'}

interface InitialStateType {
  appStatus: AppStatusType,
  errorMessage: string | null,
  successMessage: string | null,

  serviceFetchParams: ServiceFetchParamsType,
  cityFetchParams: CityFetchParamsType,
  airportFetchParams: TransportCategoryFetchParams,
  portFetchParams: TransportCategoryFetchParams,
  busStationFetchParams: TransportCategoryFetchParams,
  railwayStationFetchParams: TransportCategoryFetchParams,
  terminalFetchParams: TerminalFetchParams
  cruiseLineFetchParams: CruiseLineFetchParamsType
  cruiseFetchParams: CruiseFetchParamsType
  trackingsFetchParams: TrackingFetchParamsType
  advertisingFetchParams: AdvertisingFetchParamsType
  airlineFetchParams: AirlineFetchParamsType
}

const initialState: InitialStateType = {
  appStatus: AppStatusType.idle,
  errorMessage: null,
  successMessage: null,

  serviceFetchParams: {pagination: {page: 1, size: 10}, name: ''},
  cityFetchParams: {pagination: {page: 1, size: 10}, name: '', country_id: undefined, state_name: ''},
  airportFetchParams: {pagination: {page: 1, size: 10}, search_filter: 'ALL', search_param: 'NAME', name: '', is_triple_digit: false},
  portFetchParams: {pagination: {page: 1, size: 10}, search_filter: 'ALL', search_param: 'NAME', name: '', port_type: null, is_cruise: null},
  busStationFetchParams: {pagination: {page: 1, size: 10}, search_filter: 'ALL', search_param: 'NAME', name: ''},
  railwayStationFetchParams: {pagination: {page: 1, size: 10}, search_filter: 'ALL', search_param: 'NAME', name: ''},
  terminalFetchParams: {pagination: {page: 1, size: 10}, search_filter: 'ALL', search_param: 'TERMINAL', search_value: '', node_type: null},
  cruiseLineFetchParams: {pagination: {page: 1, size: 10}, name: ''},
  cruiseFetchParams: {pagination: {page: 1, size: 10}, name: '', search_param: 'NAME'},
  trackingsFetchParams: {pagination: {page: 1, size: 10, sort: [{direction: "DESC", field: "action_date"}]}, start_period: moment().startOf('month').format('YYYY-MM-DD'), end_period: moment().format('YYYY-MM-DD'), searched_type: 'Search'},
  advertisingFetchParams: {pagination: {page: 1, size: 10}, search_value: ''},
  airlineFetchParams: {pagination: {page: 1, size: 10}, name: ''},
}

export const appStatusSlice = createSlice({
  name: 'appStatus',
  initialState,
  reducers: {
    setAppStatus: (state, action: PayloadAction<AppStatusType>) => {
      state.appStatus = action.payload
    },
    setErrorMessage: (state, action: PayloadAction<string | null>) => {
      state.errorMessage = action.payload
    },
    setSuccessMessage: (state, action: PayloadAction<string | null>) => {
      state.successMessage = action.payload
    },
    
    setServiceFetchParams: (state, action: PayloadAction<ServiceFetchParamsType>) => {
      state.serviceFetchParams = action.payload
    },
    setCityFetchParams: (state, action: PayloadAction<CityFetchParamsType>) => {
      state.cityFetchParams = action.payload
    },
    setAirportFetchParams: (state, action: PayloadAction<TransportCategoryFetchParams>) => {
      state.airportFetchParams = action.payload
    },
    setPortFetchParams: (state, action: PayloadAction<TransportCategoryFetchParams>) => {
      state.portFetchParams = action.payload
    },
    setBusStationFetchParams: (state, action: PayloadAction<TransportCategoryFetchParams>) => {
      state.busStationFetchParams = action.payload
    },
    setRailwayStationFetchParams: (state, action: PayloadAction<TransportCategoryFetchParams>) => {
      state.railwayStationFetchParams = action.payload
    },
    setTerminalFetchParams: (state, action: PayloadAction<TerminalFetchParams>) => {
      state.terminalFetchParams = action.payload
    },
    setCruiseLineFetchParams: (state, action: PayloadAction<CruiseLineFetchParamsType>) => {
      state.cruiseLineFetchParams = action.payload
    },
    setCruiseFetchParams: (state, action: PayloadAction<CruiseFetchParamsType>) => {
      state.cruiseFetchParams = action.payload
    },
    setTrackingFetchParams: (state, action: PayloadAction<TrackingFetchParamsType>) => {
      state.trackingsFetchParams = action.payload
    },
    setAdvertisingFetchParams: (state, action: PayloadAction<AdvertisingFetchParamsType>) => {
      state.advertisingFetchParams = action.payload
    },
    setAirlineFetchParams: (state, action: PayloadAction<AirlineFetchParamsType>) => {
      state.airlineFetchParams = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending, (state) => {
        state.appStatus = AppStatusType.loading
      })
      .addMatcher(isRejected, (state, action) => {
        state.appStatus = action.payload === 'canceled' ? AppStatusType.idle : AppStatusType.failed
        state.errorMessage = action.payload === 'canceled' ? null : action.payload as string || 'Something went wrong'
      })
      .addMatcher(isFulfilled, (state, action) => {
        const meta = action.meta as fulfilledMetaType
        meta.appStatus === AppStatusType.idle && (state.appStatus = AppStatusType.idle)
        if (meta.appStatus === AppStatusType.succeeded) {
          state.appStatus = AppStatusType.succeeded
          state.successMessage = meta?.appMessage || null
        }
      })
  },
})

export const {
  setAppStatus,
  setErrorMessage,
  setSuccessMessage,
  setServiceFetchParams,
  setCityFetchParams,
  setAirportFetchParams,
  setPortFetchParams,
  setBusStationFetchParams,
  setRailwayStationFetchParams,
  setTerminalFetchParams,
  setCruiseLineFetchParams,
  setCruiseFetchParams,
  setTrackingFetchParams,
  setAdvertisingFetchParams,
  setAirlineFetchParams,
} = appStatusSlice.actions

export const selectAppStatus = (state: RootState) => state.appStatus.appStatus
export const selectErrorMessage = (state: RootState) => state.appStatus.errorMessage
export const selectSuccessMessage = (state: RootState) => state.appStatus.successMessage

export const selectServiceFetchParams = (state: RootState) => state.appStatus.serviceFetchParams
export const selectCityFetchParams = (state: RootState) => state.appStatus.cityFetchParams
export const selectAirportFetchParams = (state: RootState) => state.appStatus.airportFetchParams
export const selectPortFetchParams = (state: RootState) => state.appStatus.portFetchParams
export const selectBusStationFetchParams = (state: RootState) => state.appStatus.busStationFetchParams
export const selectRailwayStationFetchParams = (state: RootState) => state.appStatus.railwayStationFetchParams
export const selectTerminalFetchParams = (state: RootState) => state.appStatus.terminalFetchParams
export const selectCruiseLineFetchParams = (state: RootState) => state.appStatus.cruiseLineFetchParams
export const selectCruiseFetchParams = (state: RootState) => state.appStatus.cruiseFetchParams
export const selectTrackingFetchParams = (state: RootState) => state.appStatus.trackingsFetchParams
export const selectAdvertisingFetchParams = (state: RootState) => state.appStatus.advertisingFetchParams
export const selectAirlineFetchParams = (state: RootState) => state.appStatus.airlineFetchParams

export default appStatusSlice.reducer
