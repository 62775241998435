import { Button, Checkbox, Form, FormInstance, Modal } from 'antd'
import classes from './../AdvertisingDetailsForm.module.css'
import localClasses from './AdvertisingPlacement.module.css'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { PlacementPreviewType, Screen } from './ScreensWithSlots'
import { ConnectionOptions } from '../../../Services/ServiceDetailsForm/ServiceDetailsForm'
import nearbyScreen from './../../../../img/advertising/nearbyScreen.svg'
import nodeListScreen from './../../../../img/advertising/nodeListScreen.svg'
import nodeDetailsScreen from './../../../../img/advertising/nodeDetailsScreen.svg'
import activitiesScreen from './../../../../img/advertising/activitiesScreen.svg'
import VIPScreen from './../../../../img/advertising/VIPScreen.svg'
import cruiseVIPScreen from './../../../../img/advertising/cruiseVIPScreen.svg'
import cruiseProgramScreen from './../../../../img/advertising/cruiseProgramScreen.svg'
import cruiseDescriptionScreen from './../../../../img/advertising/cruiseDescriptionScreen.svg'
import eventDetailsScreen from './../../../../img/advertising/eventDetailsScreen.svg'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { CheckOccupiedSlotsThunk, selectCurrentAdvertisement } from '../../../../store/advertisingReducer'
import { getAdvertisingLocationsValue, getConnectionValidationError } from '../../../../helpers/serviceConnectionsHelper'
import { ImagePreviewType } from '../../../../types/appTypes'

const screens = [
  {
    title: 'Nearby page',
    targetingType: [ConnectionOptions.Global, ConnectionOptions.City, ConnectionOptions.Country, ConnectionOptions.Coordinates],
    screenImage: nearbyScreen,
    slots: [
      {top:'34.5%', left:'14.4%', width:'33.6%', height: '6.2%', place_type: 'NEARBY_BEFORE_ACTIVITIES'},
      {top:'66.19%', left:'14.4%', width:'33.6%', height: '6.2%', place_type: 'NEARBY_AFTER_SERVICES', place_index: 1},
      {top:'72.65%', left:'14.4%', width:'33.6%', height: '6.2%', place_type: 'NEARBY_AFTER_SERVICES', place_index: 2},
      {top:'79.1%', left:'14.4%', width:'33.6%', height: '6.2%', place_type: 'NEARBY_AFTER_SERVICES', place_index: 3},
      {top:'79.1%', left:'50.4%', width:'33.6%', height: '6.2%', place_type: 'NEARBY_MAP'},
    ],
    isVIP: false,
  },
  {
    title: 'Node list page',
    targetingType: [ConnectionOptions.Nodes],
    screenImage: nodeListScreen,
    slots: [
      {top:'38%', left:'37.3%', width:'4.5%', height:'5%', place_type: 'NODE_LIST'},
      {top:'47.3%', left:'37.3%', width:'4.5%', height:'5%', place_type: 'NODE_LIST'},
      {top:'56.78%', left:'37.3%', width:'4.5%', height:'5%', place_type: 'NODE_LIST'},
    ],
    isVIP: false,
  },
  {
    title: 'Node details page',
    targetingType: [ConnectionOptions.Nodes],
    screenImage: nodeDetailsScreen,
    slots: [
      {top:'34.01%', left:'17.8%', width:'30.5%', place_type: 'NODE_DETAILS_BEFORE_ACTIVITIES'},
      {top:'44.78%', left:'17.8%', width:'30.5%', place_type: 'NODE_DETAILS_BEFORE_SERVICES'},
      {top:'69.46%', left:'17.8%', width:'30.5%', place_type: 'NODE_DETAILS_AFTER_SERVICES', place_index: 1},
      {top:'75.3%', left:'17.8%', width:'30.5%', place_type: 'NODE_DETAILS_AFTER_SERVICES', place_index: 2},
      {top:'81.1%', left:'17.8%', width:'30.5%', place_type: 'NODE_DETAILS_AFTER_SERVICES', place_index: 3},
      {top:'80.8%', left:'50.4%', width:'30.5%', place_type: 'NODE_DETAILS_MAP'},
    ],
    isVIP: false,
  },
  {
    title: 'Activities page',
    targetingType: [ConnectionOptions.Nodes, ConnectionOptions.City, ConnectionOptions.Country, ConnectionOptions.Global, ConnectionOptions.Coordinates],
    screenImage: activitiesScreen,
    slots: [
      {top:'41.5%', left:'14.1%', width:'34.3%', height: '6.2%', place_type: 'ACTIVITIES_LIST', place_index: 1},
      {top:'68.5%', left:'14.1%', width:'34.3%', height: '6.2%', place_type: 'ACTIVITIES_LIST', place_index: 2},
      {top:'78.35%', left:'50.2%', width:'34.4%', height: '6.2%', place_type: 'ACTIVITIES_LIST_MAP'},
    ],
    isVIP: false,
  },
  {
    title: 'Nearby page (VIP)',
    targetingType: [ConnectionOptions.Nodes, ConnectionOptions.City, ConnectionOptions.Country, ConnectionOptions.Global, ConnectionOptions.Coordinates],
    screenImage: VIPScreen,
    slots: [
      {top:'2.6%', left:'24.2%', width:'29.3%', height: '7.4%', place_type: 'NEARBY_VIP_HEADER'},
      {top:'28.9%', left:'6.7%', width:'40.8%', height: '7.6%', place_type: 'NEARBY_VIP_AFTER_FILTERS'},
      {top:'20.7%', left:'50.7%', width:'40.8%', height: '7.6%', place_type: 'NEARBY_VIP_MAP'},
    ],
    isVIP: true,
  },
]

const cruiseScreens = [
  {
    title: 'Cruise screen (VIP)',
    targetingType: [ConnectionOptions.Nodes, ConnectionOptions.City, ConnectionOptions.Country, ConnectionOptions.Global, ConnectionOptions.Coordinates],
    screenImage: cruiseVIPScreen,
    slots: [
      {top:'20.7%', left:'4.4%', width:'45.1%', height: '6.2%', place_type: 'CRUISE_VIP_BEFORE_INFO'},
      {top:'8.9%', left:'51.9%', width:'37.8%', height: '6.2%', place_type: 'CRUISE_VIP_MAP'},
    ],
    isVIP: true,
  },
  {
    title: 'Cruise description screen',
    targetingType: [ConnectionOptions.Nodes, ConnectionOptions.City, ConnectionOptions.Country, ConnectionOptions.Global, ConnectionOptions.Coordinates],
    screenImage: cruiseDescriptionScreen,
    slots: [
      {top:'44.4%', left:'3.4%', width:'46.1%', height: '6.3%', place_type: 'CRUISE_DESCRIPTION_AFTER_INFO', place_index: 1},
      {top:'51.4%', left:'3.4%', width:'46.1%', height: '6.3%', place_type: 'CRUISE_DESCRIPTION_AFTER_INFO', place_index: 2},
      {top:'58.4%', left:'3.4%', width:'46.1%', height: '6.3%', place_type: 'CRUISE_DESCRIPTION_AFTER_INFO', place_index: 3},
      {top:'74.8%', left:'51.6%', width:'38.6%', height: '6.4%', place_type: 'CRUISE_DESCRIPTION_MAP'},
    ],
    isVIP: false,
  },
  {
    title: 'Cruise program screen',
    targetingType: [ConnectionOptions.Nodes, ConnectionOptions.City, ConnectionOptions.Country, ConnectionOptions.Global, ConnectionOptions.Coordinates],
    screenImage: cruiseProgramScreen,
    slots: [
      {top:'67.6%', left:'5.6%', width:'43.9%', height: '6.2%', place_type: 'CRUISE_PROGRAM_AFTER_INFO', place_index: 1},
      {top:'74.3%', left:'5.6%', width:'43.9%', height: '6.2%', place_type: 'CRUISE_PROGRAM_AFTER_INFO', place_index: 2},
      {top:'80.9%', left:'5.6%', width:'43.9%', height: '6.2%', place_type: 'CRUISE_PROGRAM_AFTER_INFO', place_index: 3},
      {top:'80.3%', left:'51.8%', width:'36.8%', height: '6.2%', place_type: 'CRUISE_PROGRAM_MAP'},
    ],
    isVIP: false,
  }
]

const eventScreens = [
  {
    title: 'Event details screen',
    targetingType: [ConnectionOptions.Nodes, ConnectionOptions.City, ConnectionOptions.Country, ConnectionOptions.Global, ConnectionOptions.Coordinates],
    screenImage: eventDetailsScreen,
    slots: [
      {top:'11.7%', left:'12.0%', width:'37.5%', height: '6.5%', place_type: 'EVENT_DETAILS_BEFORE_INFO'},
      {top:'78.3%', left:'50.7%', width:'37.5%', height: '6.5%', place_type: 'EVENT_DETAILS_MAP'},
      {top:'56.3%', left:'12.0%', width:'37.5%', height: '6.5%', place_type: 'EVENT_DETAILS_AFTER_INFO', place_index: 1},
      {top:'63.6%', left:'12.0%', width:'37.5%', height: '6.5%', place_type: 'EVENT_DETAILS_AFTER_INFO', place_index: 2},
      {top:'70.8%', left:'12.0%', width:'37.5%', height: '6.5%', place_type: 'EVENT_DETAILS_AFTER_INFO', place_index: 3},
    ],
    isVIP: false,
  },
]

const getScreensCollection = (searchType: 'search' | 'cruise' | 'event') => {
  if (searchType === 'search') {
    return screens
  } else if (searchType === 'cruise') {
    return cruiseScreens
  } else {
    return eventScreens
  }
}

const getScreensBySettings = (targetingType: number, searchType: 'search' | 'cruise' | 'event', isVIP?: boolean):typeof screens => {
  const screensData = getScreensCollection(searchType)
  if (isVIP && searchType !== 'event') {
    return [screensData.find(s => s.isVIP)!]
  } else {
    return screensData.filter(screen => screen.targetingType.some((type) => type === targetingType) && !screen.isVIP)
  }
}

const AdvertisingPlacement: React.FC<AdvertisingPlacementPropTypes> = ({
  form,
  selectedConnectionOption,
  isEditing,
  isCopy,
  selectedBannerOption,
  uploadedBannerPreview,
  setIsTargetingFieldsDisabled
}) => {
  const currentAdvertisement = useAppSelector(selectCurrentAdvertisement)

  const dispatch = useAppDispatch()
  const [isPlacementModalOpen, setIsPlacementModalOpen] = useState(false)
  const [placementError, setPlacementError] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isVIPSelected, setIsVIPSelected] = useState(false)
  const [bannerPreview, setBannerPreview] = useState<PlacementPreviewType>({selectedBannerType: form.getFieldValue('selected_banner_type'), place_type: '', url: '', html: ''})

  const html = Form.useWatch('banner_html', form)
  const banner_comment = Form.useWatch('banner_comment', form)
  const banner_color = Form.useWatch('banner_color', form)
  const banner_bg_color = Form.useWatch('banner_bg_color', form)
  const searchType = Form.useWatch('search_type', form)
  useEffect(() => {
    setBannerPreview({
      ...bannerPreview,
      selectedBannerType: selectedBannerOption,
      ...(selectedBannerOption === 'uploaded' ? {url: uploadedBannerPreview?.url || ''} : {}),
      ...(selectedBannerOption === 'generated' ? {
        url: uploadedBannerPreview?.url || '',
        banner_comment: banner_comment || form.getFieldValue('banner_comment'),
        banner_color: banner_color || form.getFieldValue('banner_color'),
        banner_bg_color: banner_bg_color || form.getFieldValue('banner_bg_color'),
      } : {}),
      ...(selectedBannerOption === 'html' ? {html: form.getFieldValue('banner_html')} : {}),
    })
    // eslint-disable-next-line
  }, [selectedBannerOption, form, html, uploadedBannerPreview, banner_comment, banner_color, banner_bg_color])

  useEffect(() => {
    if (isEditing && !bannerPreview?.place_type) {
      const place_type = form.getFieldValue('place_type')
      const place_index = form.getFieldValue('place_index')
      const imgUrl = form.getFieldValue('banner_url')
      const banner_html = form.getFieldValue('banner_html')
      const selectedBannerType = form.getFieldValue('selected_banner_type') as 'generated' | 'uploaded' | 'html'
      setBannerPreview({selectedBannerType, url: imgUrl, html: banner_html, place_type, place_index})
    }
  }, [isEditing, form, bannerPreview])

  const openSelectPlacementModal = () => {
    const error = getConnectionValidationError(form.getFieldsValue(true), selectedConnectionOption, ConnectionOptions, form.getFieldValue('search_type') === 'search' ? undefined : searchType) 
    const hasUploadedBanner = selectedBannerOption === 'uploaded' && (!!form.getFieldValue('banner') || !!form.getFieldValue('banner_url'))
    const hasBannerCode = selectedBannerOption === 'html' && !!form.getFieldValue('banner_html')
    const hasGeneratedBanner = selectedBannerOption === 'generated' && (!!form.getFieldValue('banner') || !!form.getFieldValue('banner_url'))
    if (!hasUploadedBanner && !hasBannerCode && !hasGeneratedBanner) {
      setPlacementError('Please set banner data first')
    } else if (error) {
      setPlacementError(error)
    } else if (!form.getFieldValue('start_date') || !form.getFieldValue('end_date')) {
      setPlacementError('Please select start and end date first')
    } else {
      setIsLoading(true)
      const formValues = form.getFieldsValue(true)
      const advertisingLocations = !isCopy && !!form.getFieldValue('advertising_locations') ? form.getFieldValue('advertising_locations') : getAdvertisingLocationsValue(formValues)
      for (const [key] of Object.entries(advertisingLocations || {})) {
        delete advertisingLocations[key]?.advertising_location_id
      }
      const dataForSlotsCheck = new FormData()
      dataForSlotsCheck.append('advertising', new Blob([JSON.stringify({
        advertising_locations: isCopy && formValues?.exclude_advertising_location_id_list !== null
          ? []
          : advertisingLocations,
        user_locations: formValues?.allowed_user_location,
        start_date: formValues?.start_date.startOf('day'),
        end_date: formValues?.end_date.startOf('day'),
        ...(formValues?.node_type === 'Cruise' && formValues?.is_global ===  true ? {
          node_type: 'Cruise',
          is_global: true
        } : {}),
        ...(formValues?.search_type === 'event' ? {node_type: 'Event'} : {})
      }, null, 2)], {type: 'application/json'}))
      if (isCopy && formValues?.exclude_advertising_location_id_list !== null) {
        const advertisingSettings = {
          old_advertising_id: currentAdvertisement?.advertising_id,
          use_old_node: formValues?.exclude_advertising_location_id_list !== null,
          ...(formValues?.exclude_advertising_location_id_list !== null ? {exclude_advertising_location_id_list: formValues?.exclude_advertising_location_id_list || []} : {})
        }
        dataForSlotsCheck.append('advertising_settings', new Blob([JSON.stringify(advertisingSettings, null, 2)], {type: 'application/json'}))
      }
      dispatch(CheckOccupiedSlotsThunk(dataForSlotsCheck))
        .then(() => {
          setPlacementError('')
          setIsPlacementModalOpen(true)
          setIsLoading(false)
        })
    }
  }

  const selectSlot = async(place_type: string, place_index?: number) => {
    if (selectedBannerOption === 'generated' && place_type === 'NODE_LIST') {
      setBannerPreview({...bannerPreview, place_type, place_index, banner_comment: ''})
    } else {
      setBannerPreview({...bannerPreview, place_type, place_index})
    }
    setIsTargetingFieldsDisabled(true)
  }

  return (
    <div className={classes.formBlock}>
      <div className={classes.label} style={{marginBottom: '10px'}}>
        {isEditing ? 'This is selected banner placement' : 'Select a place in the application to display ads'}
      </div>
      {!!placementError.length &&
        <div style={{color: 'red'}}>
          {placementError}
        </div>
      }
      {!isEditing && 
        <>
          <div>
            {searchType !== 'event' &&
              <Checkbox onChange={(e) => setIsVIPSelected(e.target.checked)} value={isVIPSelected}>
                Show only VIP slots
              </Checkbox>
            }
          </div>
          <Button
            type='default'
            className={localClasses.selectBtn}
            onClick={openSelectPlacementModal}
            loading={isLoading}
          >
            Select
          </Button>
        </>
      }
      {bannerPreview?.place_type &&
        <Screen
          screenImage={getScreensCollection(form.getFieldValue('search_type')).find(s => s.slots.some(slot => slot.place_type === bannerPreview.place_type && ((slot?.place_index) === bannerPreview?.place_index || !bannerPreview?.place_index)))?.screenImage!}
          slots={getScreensCollection(form.getFieldValue('search_type')).find(s => s.slots.some(slot => slot.place_type === bannerPreview.place_type && (slot?.place_index === bannerPreview?.place_index || !bannerPreview?.place_index)))?.slots!}
          selectSlot={() => {}}
          bannerPreview={{...bannerPreview, selectedBannerType: selectedBannerOption, isPagePreview: true}}
        />
      }
      <PlacementModal
        isOpen={isPlacementModalOpen}
        closeModal={() => setIsPlacementModalOpen(false)}
        targetingType={selectedConnectionOption}
        form={form}
        bannerPreview={bannerPreview}
        isVIPSelected={isVIPSelected}
        selectSlot={selectSlot}
        searchType={searchType}
      />
    </div>
  )
}

const PlacementModal: React.FC<PlacementModalPropTypes> = ({
  isOpen,
  closeModal,
  targetingType,
  form,
  bannerPreview,
  isVIPSelected,
  selectSlot,
  searchType,
}) => {
  const [currentScreenIndex, setCurrentScreenIndex] = useState(0)
  const [screensForSelectedConnection, setScreensForSelectedConnection] = useState<typeof screens>([])

  useEffect(() => {
    setScreensForSelectedConnection(getScreensBySettings(targetingType, searchType, isVIPSelected))
  // eslint-disable-next-line
  }, [targetingType, isVIPSelected, searchType])

  const saveChanges = () => {
    form.setFieldValue('place_type', bannerPreview.place_type)
    form.setFieldValue('place_index', bannerPreview.place_index)
    closeModal()
  }

  return (
    <Modal
      visible={isOpen}
      className={localClasses.placementModal}
      footer={false}
      closable={false}
      destroyOnClose
      width={'70%'}
      style={{zIndex: 100}}
    >
      <Screen 
        screenImage={screensForSelectedConnection[currentScreenIndex]?.screenImage}
        slots={screensForSelectedConnection[currentScreenIndex]?.slots}
        selectSlot={(place_type, place_index) => selectSlot(place_type, place_index)}
        bannerPreview={bannerPreview}
      />
      <div className={localClasses.screenNavigation}>
        <svg
          className={localClasses.navigateToPrevScreen}
          onClick={() => setCurrentScreenIndex(currentScreenIndex - 1)}
          style={{visibility: currentScreenIndex !== 0 ? 'visible' : 'hidden'}}
          width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="44" height="44" rx="22" fill="#3A36DB"/>
          <path d="M25 28L19 22L25 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        <div className={localClasses.currentScreenCount}>
          <div>
            { screensForSelectedConnection[currentScreenIndex]?.title }
          </div>
          <div>
            { currentScreenIndex + 1 } / { screensForSelectedConnection.length }
          </div>
        </div>

        <svg
          className={localClasses.navigateToNextScreen}
          onClick={() => setCurrentScreenIndex(currentScreenIndex + 1)}
          style={{visibility: currentScreenIndex < screensForSelectedConnection.length - 1 ? 'visible' : 'hidden'}}
          width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="44" height="44" rx="22" fill="#3A36DB"/>
          <path d="M19 28L25 22L19 16" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      </div>

      <div className={localClasses.modalBtnsArea}>
        <Button onClick={closeModal}>
          Cancel
        </Button>
        <Button type='primary' style={{marginLeft: '15px'}} onClick={saveChanges}>
          Save
        </Button>
      </div>
    </Modal>
  )
}

interface AdvertisingPlacementPropTypes {
  form: FormInstance
  selectedConnectionOption: number
  isEditing: boolean
  isCopy: boolean
  selectedBannerOption: 'generated' | 'uploaded' | 'html'
  uploadedBannerPreview: ImagePreviewType | null
  setIsTargetingFieldsDisabled: Dispatch<SetStateAction<boolean>>
}

interface PlacementModalPropTypes {
  isOpen: boolean
  closeModal: () => void
  targetingType: number
  form: FormInstance
  bannerPreview: PlacementPreviewType
  selectSlot: (place_type: string, place_index?: number) => void
  isVIPSelected: boolean
  searchType: 'search' | 'cruise'
}

export default AdvertisingPlacement
