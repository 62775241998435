import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { trackingAPI } from '../app/api'
import { TrackingFetchParamsType, TrackingListType } from '../types/trackingTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

interface InitialStateType {
  trackings: TrackingListType
  trackings_total_count: number
}

const initialState: InitialStateType = {
  trackings: [],
  trackings_total_count: 0,
}

export const trackingsSlice = createSlice({
  name: 'trackings',
  initialState,
  reducers: {
    setTrackings: (state, action: PayloadAction<TrackingListType>) => {state.trackings = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllTrackingsThunk.fulfilled, (state, action) => {
        state.trackings = action.payload.trackings
        state.trackings_total_count = action.payload.totalSize
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.trackings = []
      })
  }
})

export const { setTrackings } = trackingsSlice.actions

export const selectTrackings = (state: RootState): TrackingListType => state.trackings.trackings
export const selectTrackingsTotalCount = (state: RootState): number => state.trackings.trackings_total_count

export const GetAllTrackingsThunk = createAsyncThunk<{trackings: TrackingListType, totalSize: number}, {fetchParams: TrackingFetchParamsType, source: any}, AsyncThunkConfig>(
  'trackings/getAllTrackings',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const { status, data } = await trackingAPI.getAllTrackings(fetchParams, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default trackingsSlice.reducer
 