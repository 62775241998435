import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Checkbox, Input, Select } from 'antd'
import { debounce } from 'lodash'
import axios from './../../helpers/axiosHelper'
import classes from './AirportList.module.css'
import ItemList from '../common/ItemList/ItemList'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GetAllAirportsThunk, selectAirportList, selectAirportTotalCount } from '../../store/airportsReducer'
import { AirportType } from '../../types/airportTypes'
import { selectAirportFetchParams, setAirportFetchParams } from '../../store/appStatusReducer'
import { TransportCategoryFetchParams } from '../../types/appTypes'

const AirportList = () => {
  const dispatch = useAppDispatch()
  const airports = useAppSelector(selectAirportList)
  const airports_total_count = useAppSelector(selectAirportTotalCount)
  const fetchParams = useAppSelector(selectAirportFetchParams)
  const [tableData, setTableData] = useState<AirportType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllAirportsThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(airports)
  }, [airports])

  // const deleteItem = (airport: AirportType): void => {
  //   dispatch(DeleteAirportThunk(airport.id))
  // }

  const setFetchParams = (params: TransportCategoryFetchParams) => {
    dispatch(setAirportFetchParams(params))
  }
  
  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, name: encodeURI(searchRequest)})
    }, 350),
    [fetchParams]
  )

  const editItemLink = '/airports/edit/'

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Airport List
        </h1>
        <Link to='/airports/create'>
        <Button type='primary'>+ Add New</Button>
      </Link>
      </div>
      <div className={classes.tools}>
        <div style={{display: 'flex', alignItems: 'center', marginRight: '10px'}}>
          <Checkbox
            checked={fetchParams.is_triple_digit}
            onChange={(e) => setFetchParams({...fetchParams, is_triple_digit: e.target.checked})}
          />
          <div style={{width: '100px', marginLeft: '5px'}}>
            Triple digit
          </div>
        </div>
        <div>
          <Select
            value={fetchParams.search_filter}
            onChange={(val) => setFetchParams({...fetchParams, search_filter: val})}
            style={{width: '200px'}}
            className={classes.resultsFilter}
          >
            <Select.Option value={'ALL'}>Show all</Select.Option>
            <Select.Option value={'NOT_APPROVED'}>Not approved only</Select.Option>
            <Select.Option value={'DONT_DISPLAY'}>Don't display only</Select.Option>
          </Select>
        </div>
        <div>
          <Input.Group compact style={{display:'flex'}}>
            <Select
              style={{ width: '187px' }}
              value={fetchParams.search_param}
              onChange={(val) => setFetchParams({...fetchParams, search_param: val as 'NAME' | 'CODE'})}
            >
              <Select.Option value={'NAME'}>By name</Select.Option>
              <Select.Option value={'CODE'}>By code</Select.Option>
              <Select.Option value={null}>By both</Select.Option>
            </Select>
            <Input
              placeholder='Search'
              suffix={<SearchIcon />}
              defaultValue={decodeURIComponent(fetchParams.name as string)}
              onChange={(e) => handleSearchDebounce(e.target.value.trim())}
              style={{ width: '240px' }}
            />
          </Input.Group>
        </div>
      </div>
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        // deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={airports_total_count}
        columnList={['name', 'code', 'icao', 'country.name', 'location', 'actions', 'category']}
        rowIdKey='id'
        transportType='airport'
      />
    </div>
  )
}

export default AirportList
