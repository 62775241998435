import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import classes from './CruiseLineList.module.css'
import axios from '../../helpers/axiosHelper'
import { GetAllCruiseLinesThunk, selectCruiseLines, selectCruiseLineTotalCount } from '../../store/cruiseLinesReducer'
import { CruiseLineFetchParamsType, CruiseLineType } from '../../types/cruiseType'
import { Button, Input } from 'antd'
import { Link } from 'react-router-dom'
import ItemList from '../common/ItemList/ItemList'
import { selectCruiseLineFetchParams, setCruiseLineFetchParams } from '../../store/appStatusReducer'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'

const CruiseLineList = () => {
  const dispatch = useAppDispatch()
  const railway_stations = useAppSelector(selectCruiseLines)
  const cruise_lines_total_count = useAppSelector(selectCruiseLineTotalCount)
  const fetchParams = useAppSelector(selectCruiseLineFetchParams)

  const [tableData, setTableData] = useState<CruiseLineType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllCruiseLinesThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(railway_stations)
  }, [railway_stations])

  // const deleteItem = (cruise_line: CruiseLineType): void => {
  //   dispatch(DeleteCruiseLineThunk(cruise_line.id))
  // }

  const setFetchParams = (params: CruiseLineFetchParamsType) => {
    dispatch(setCruiseLineFetchParams(params))
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, name: encodeURI(searchRequest)})
    }, 350),
    [fetchParams]
  )

  const editItemLink = '/cruise-lines/edit/'
  
  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Cruise Lines
        </h1>
        <div className={classes.tools}>
          <div>
          </div>
            <Input
              placeholder='Search'
              suffix={<SearchIcon />}
              defaultValue={decodeURIComponent(fetchParams.name as string)}
              onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            />
          <Link to='/cruise-lines/create'>
            <Button type='primary'>+ Add New</Button>
          </Link>
        </div>
      </div>
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        // deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={cruise_lines_total_count}
        columnList={['name', 'type_name', 'headquarters', 'code', 'website', 'is_operated', 'actions']}
        rowIdKey='id'
      />
    </div>
  )
}

export default CruiseLineList
