import { Button, Form, FormInstance } from 'antd'
import InputField from '../../../../common/formFields/InputField'
import localClasses from './../MainData.module.css'
import { useState } from 'react'
import { isValidHTML } from '../../../../../helpers/files_helper'

const AddBannerHTMLCodeBlock: React.FC<{form: FormInstance, isEditing: boolean}> = ({form}) => {
  const htmlCode = Form.useWatch('banner_html', form)

  const [bannerCodeForGeneration, setBannerCodeForGeneration] = useState('')
  const [bannerCodeValidationError, setBannerCodeValidationError] = useState(false)

  const generateBannerFromHTML = () => {
    const isValid = isValidHTML(htmlCode)

    if (!isValid) {
      setBannerCodeValidationError(true)
      setBannerCodeForGeneration('')
    } else {
      setBannerCodeValidationError(false)
      setBannerCodeForGeneration(htmlCode)
    }
  }

  return (
    <div>
      <InputField
        name='banner_html'
        placeholder='HTML code'
        label='Enter HTML code'
        rows={3}
        requiredMessage='Please enter HTML code'
      />
      <Button
        type='default'
        disabled={!htmlCode?.length}
        style={{marginBottom: '10px'}}
        onClick={() => generateBannerFromHTML()}
      >
        Generate
      </Button>
      {bannerCodeForGeneration?.length ? (
        <div className={localClasses.bannerFromCode} dangerouslySetInnerHTML={{__html: htmlCode}}></div>
      ) : (
        <div className={localClasses.bannerFromCodeEmpty}>
          {bannerCodeValidationError
            ? 'HTML code is invalid! Check your code and press "Generate" again'
            : 'Here will be displayed banner from HTML code'
          }
        </div>
      )}
    </div>
  )
}

export default AddBannerHTMLCodeBlock
