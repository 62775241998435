import { Link } from 'react-router-dom'
import { Button, Input } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import classes from './AdvertisingList.module.css'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import axios from './../../helpers/axiosHelper'
import { selectAdvertisingFetchParams, setAdvertisingFetchParams } from '../../store/appStatusReducer'
import { AdvertisementType, AdvertisingFetchParamsType } from '../../types/advertisingTypes'
import { debounce } from 'lodash'
import { DeleteAdvertisementThunk, GetAllAdvertisementsThunk, selectAdvertisementList, selectAdvertisementsTotalCount } from '../../store/advertisingReducer'
import moment from 'moment'
import { ReactComponent as SkipForward } from './../../img/icons/skipForward.svg'

const AdvertisingList = () => {
  const dispatch = useAppDispatch()
  const advertisements = useAppSelector(selectAdvertisementList)
  const advertisements_total_count = useAppSelector(selectAdvertisementsTotalCount)
  const fetchParams = useAppSelector(selectAdvertisingFetchParams)

  const [tableData, setTableData] = useState<AdvertisementType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllAdvertisementsThunk({fetchParams, source}))
      .then((resp) => {
        setIsDataLoading(false)
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(advertisements)
  }, [advertisements])

  const deleteItem = (advertisement: AdvertisementType): void => {
    dispatch(DeleteAdvertisementThunk(advertisement.advertising_id))
  }

  const editItemLink = `/advertising/edit/`
  // const viewItemLink = '/advertising/view/'
  const copyItemLink = `/advertising/create/copy/`

  const setFetchParams = (params: AdvertisingFetchParamsType) => {
    dispatch(setAdvertisingFetchParams(params))
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, search_value: encodeURI(searchRequest), pagination: {...fetchParams.pagination, page: 1}})
    }, 350),
    [fetchParams]
  )
  
  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Advertising
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.search_value as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
          />
          <Link to='/advertising/showAdvertising'>
            <Button className={classes.viewUserAppToolBtn}>
              <SkipForward />
              Show ads
            </Button>
          </Link>
          <Link to='/advertising/create'>
            <Button type='primary'>+ Add New</Button>
          </Link>
        </div>
      </div>
      <ItemList
        items={tableData}
        deleteItem={deleteItem}
        copyItemLink={copyItemLink}
        editItemLink={editItemLink}
        loading={isDataLoading}
        pagination={fetchParams.pagination}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={advertisements_total_count}
        columnList={['actions']}
        rowIdKey='advertising_id'
        getAdditionalColumns={(getColumnSortOrder, setColumnSortOrder) => getAdditionalTableColumns(getColumnSortOrder, setColumnSortOrder)}
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Company Name',
      dataIndex: 'company_name',
      key: 'company_name',
      sorter: true,
      sortOrder: getColumnSortOrder('company_name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('company_name', column.sortOrder)
      }),
      ellipsis: true,
    },
    {
      title: 'Website',
      dataIndex: 'link',
      key: 'link',
      sorter: true,
      sortOrder: getColumnSortOrder('link'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('link', column.sortOrder)
      }),
      ellipsis: true,
    },
    {
      title: 'Dates to show',
      dataIndex: 'start_date',
      key: 'start_date',
      sorter: true,
      sortOrder: getColumnSortOrder('start_date'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('start_date', column.sortOrder)
      }),
      ellipsis: true,
      render: (_:any, item: any) => (
        <div>{moment(item.start_date).format('DD.MM.YYYY')} - {moment(item.end_date).format('DD.MM.YYYY')}</div>
      ),
    },
  ])
}
export default AdvertisingList
