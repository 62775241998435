import { configureStore } from '@reduxjs/toolkit'
import appStatus, { AppStatusType } from './appStatusReducer'
import user from './userReducer'
import airports from './airportsReducer'
import ports from './portsReducer'
import locations from './locationsReducer'
import services from './servicesReducer'
import busStations from './busStationsReducer'
import railwayStations from './railwayStationsReducer'
import terminals from './terminalsReducer'
import cruiseLines from './cruiseLinesReducer'
import trackings from './trackingsReducer'
import advertising from './advertisingReducer'
import cruises from './cruisesReducer'
import blog from './blogReducer'
import gtfs from './gtfsReducer'
import event from './eventReducer'
import airlines from './airlinesReducer'
import widgets from './widgetsReducer'
import faq from './faqReducer'

export const store = configureStore({
  reducer: {
    appStatus,
    user,
    airports,
    ports,
    locations,
    services,
    busStations,
    railwayStations,
    terminals,
    cruiseLines,
    trackings,
    advertising,
    cruises,
    blog,
    gtfs,
    event,
    airlines,
    widgets,
    faq
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
  devTools: true,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export type AsyncThunkConfig = {
  fulfilledMeta: {
    appStatus: AppStatusType.succeeded | AppStatusType.idle
    appMessage?:  string
  },
  state: RootState
}
export type fulfilledMetaType = {
  arg: unknown;
  requestId: string;
  requestStatus: 'fulfilled',
  appStatus: AppStatusType.succeeded | AppStatusType.idle
  appMessage?:  string
}
