import { Button, Checkbox, Form, FormInstance, Spin, Tabs, Tooltip } from 'antd'
import { pickBy } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './PortDetailsForm.module.css'
import { GetAllCountriesThunk, selectCurrentCity, setCities, setCurrentCity } from '../../../store/locationsReducer'
import redDot from './../../../img/icons/redDot.png'
import {ReactComponent as InfoIcon} from './../../../img/icons/info.svg'
import { CreatePortThunk, EditPortThunk, GetPortByIdThunk, selectCurrentPort, setCurrentPort, setPorts } from '../../../store/portsReducer'
import { PortType } from '../../../types/portTypes'
import MainInformationTab from '../../common/transportItemForm/MainInformationTab/MainInformationTab'
import PhotoTab from '../../common/transportItemForm/PhotoTab/PhotoTab'
import { CityType } from '../../../types/locationTypes'
import moment from 'moment'
import CruiseRoutesTab from './CruiseRoutesTab/CruiseRoutesTab'
import { setCruiseRoutesByLocode } from '../../../store/cruisesReducer'

const PortDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentPort = useAppSelector(selectCurrentPort)
  const currentCity = useAppSelector(selectCurrentCity)
  const [form] = Form.useForm()
  const [editingPortId, setEditingPortId] = useState(0)
  const [activeTabKey, setActiveTabKey] = useState('1')
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [isApproved, setIsApproved] = useState(false)

  const portType = Form.useWatch('port_type', form)

  const tabs: TabType[] = [
    {
      title: 'Main information',
      key: '1',
      content: <MainInformationTab
        form={form}
        transportName='port'
        isEditing={isEditing}
        itemId={currentPort.id}
        isCodeOptional={
          currentPort?.port_type?.toUpperCase() === 'WAYPOINT'
          || currentPort?.port_type?.toUpperCase() === 'EXCURSION'
          || portType?.toUpperCase() === 'WAYPOINT'
          || portType?.toUpperCase() === 'EXCURSION'
        }
      />
    },
    {
      title: 'Photo',
      key: '2', 
      content: <PhotoTab form={form} isEditing={isEditing} />
    },
    ...(isEditing && !!currentPort?.is_cruise ? [{
      title: 'Cruise Routes',
      key: '3', 
      content: <CruiseRoutesTab />
    }] : [])
  ]
  useEffect(() => {
    dispatch(GetAllCountriesThunk())
    return () => {
      dispatch(setCurrentCity({} as CityType))
    }
  }, [dispatch])
  
  const handleChangeTab = (activeKey: string) => {
    setActiveTabKey(activeKey)
    setErrors([])
  }

  const onFinishFailed = (errors: any) => {
    const errorsData = errors?.errorFields?.map((error:any) => error.errors).flat(1) || []
    const formValues = form.getFieldsValue(true)
    if (!formValues?.place_id && (!formValues?.country?.code || !formValues?.city_name)) {
      errorsData.push('The address is empty!')
    }
    setErrors(errorsData)
  }

  const sendData = async(formData: any) => {
    if (isEditing) {
      return dispatch(EditPortThunk({portData: formData, portId: editingPortId}))
    } else {
      return dispatch(CreatePortThunk(formData))
    }
  }

  const handleSave = async() => {

    let formValues = form.getFieldsValue(true)
    const noAddressData = !formValues?.place_id && (!formValues?.country?.code || !formValues?.city_name)
    if (noAddressData) {
      return onFinishFailed({})
    }
    const portData = {
      ...pickBy(formValues, (_, key) => key !== 'photos'
        && key !== 'photo_urls'
        && key !== 'country'
        && key !== 'city_name'
        && key !== 'country_id'
        && key !== 'country_code'
        && key !== 'placeId'
        && key !== 'parent_port'
      ),
      phones: formValues?.phones?.filter((p: string) => p.length > 0) || [],
      city_id: !!formValues?.place_id ? undefined : (currentCity.id ? currentCity.id : currentPort?.country.cities[0].id),
      place_id: formValues?.place_id || currentPort?.place_id,
      code: formValues?.code ? (formValues?.country_code + formValues?.code).toUpperCase() : '',
      parent_port_id: formValues?.parent_port_id && Object.keys(formValues?.parent_port_id)?.length ? formValues?.parent_port_id?.value : formValues?.parent_port_id
    }
    const formData = new FormData()
    formData.append('port', new Blob([JSON.stringify(portData, null, 2)], {type: 'application/json'}))
    !!formValues?.photos?.length && formValues.photos.forEach((p:any) => formData.append(isEditing ? 'add_photos' : 'photos', p.originFileObj))
    !!isEditing && !!formValues?.delete_photos?.length && formData.append('delete_photos', new Blob([JSON.stringify({urls: formValues?.delete_photos}, null, 2)], {type: 'application/json'}))

    setIsSaving(true)
    sendData(formData)
      .then((resp) => {
        setIsSaving(false)
        !resp?.type.includes('rejected') && navigate('/ports')
      })
  }

  useEffect(() => {
    if (isEditing) {
      // pathname example: /ports/edit/47895
      const id = location.pathname.split('edit/')[1]
      setEditingPortId(+id)
      dispatch(GetPortByIdThunk(+id))
    }
    return () => {
      dispatch(setCurrentPort({} as PortType))
      dispatch(setCities([]))
      dispatch(setCruiseRoutesByLocode(null))
    }
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && !!Object.keys(currentPort)?.length) {
      const portFieldsData = {
        ...currentPort,
        placeId: currentPort?.place_id,
        country_code: currentPort?.country?.code,
        parent_port_id: currentPort?.parent_port?.id,
        port_type: currentPort?.port_type === null ? 'PORT' : currentPort?.port_type,
        code: currentPort?.code?.substr(currentPort?.code.length - 3),
        city_name: !!currentPort?.country?.cities[0]?.name
          ? currentPort?.country?.cities[0]?.name + `${currentPort?.country?.cities[0]?.state_name?.length ? ' ('+currentPort?.country?.cities[0]?.state_name+')' : ''}`
          : '',
      }
      form.setFieldsValue(portFieldsData)
      dispatch(setPorts(!!currentPort?.parent_port?.id ? [currentPort.parent_port] : []))
      setIsApproved(form.getFieldValue('is_approved') === null ? true : form.getFieldValue('is_approved'))
    }
  }, [currentPort, form, isEditing, dispatch])

  const handleApprovalChange = (isApproved:boolean) => {
    setIsApproved(isApproved)
    form.setFieldsValue({'is_approved': isApproved})
  }

  if (isEditing && !Object.keys(currentPort).length) {
    return <Spin className={classes.spinner} />
  }

  return (
    <Form
      name='port'
      onFinish={handleSave}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentPort?.name : 'Adding a new port'}
          {currentPort?.is_approved === false &&
            <Tooltip title='Data needs to be approved'>
              <img src={redDot} alt='needs attention' className={classes.redDot}/>
            </Tooltip>
          }
        </h1>
        <Tabs activeKey={activeTabKey} onChange={(activeKey) => handleChangeTab(activeKey)}>
          {tabs.map((tab) => (
            <Tabs.TabPane tab={tab.title} key={tab.key}>
              {tab.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
      <div className={classes.navigationBtnWrapper}>
        {!!errors.length && activeTabKey === '2' &&
          <div className={classes.errors}>
            {errors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
        }
        {activeTabKey !== '3' &&
          <div className={classes.btnsArea}>
            {!!isEditing ? ( 
              <>
                {activeTabKey === '1' ? (
                  <div>
                    Created: {moment(currentPort?.created).format('YYYY-MM-DD')}
                  </div>
                ) : (
                  <div className={classes.approveData}>
                    <div>
                      <InfoIcon />
                      Port data is approved
                    </div>
                    <Checkbox checked={isApproved} onChange={e => handleApprovalChange(e.target.checked)}/>
                  </div>
                )}
              </>
            ) : (
              <div></div>
            )}
            <div className={classes.buttons}>
              {activeTabKey !==  '1' &&
                <div
                  className={classes.backBtn}
                  onClick={(e) => setActiveTabKey((+activeTabKey - 1).toString())}
                >
                  Back
                </div>
              }
              <Link to='/ports'>
                <Button className={classes.navigationBtn}>
                  Cancel
                </Button>
              </Link>
              {activeTabKey !== '2' ? (
                <Button
                  type='primary'
                  className={classes.navigationBtn}
                  onClick={(e) => {
                    e.preventDefault();
                    setActiveTabKey((+activeTabKey + 1).toString())
                  }}
                >
                  Next
                </Button>
              ) : (
                <Button
                  type='primary'
                  htmlType='submit'
                  className={classes.navigationBtn}
                  loading={isSaving}
                >
                  Save
                </Button>
              )}
            </div>
          </div>
        }
      </div>
    </Form>
  )
}

interface TabType {
  title: string
  key: string
  content: ReactElement<any, any>
}

export interface FormTabProps {
  form: FormInstance
  isEditing: boolean
}

export default PortDetailsForm
