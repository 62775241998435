import { useEffect, useState } from 'react'
import { Button, Input, Select } from 'antd'
import { Link } from 'react-router-dom'
import { sortBy } from 'lodash'
import axios from './../../helpers/axiosHelper'
import classes from './CityCountryList.module.css'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import ItemList from '../common/ItemList/ItemList'
import { GetAllCountriesThunk, GetCityCountryListThunk, selectAllCountries, selectCityList, selectCountryList, selectListItemsTotalCount, selectSelectedListType, setSelectedListType } from '../../store/locationsReducer'
import { CityFetchParamsType } from '../../types/locationTypes'
import { selectCityFetchParams, setCityFetchParams } from '../../store/appStatusReducer'

const CityCountryList = () => {
  const dispatch = useAppDispatch()
  const cities = useAppSelector(selectCityList)
  const countries = useAppSelector(selectCountryList)
  const allCountries = useAppSelector(selectAllCountries)
  const listItemsTotalCount = useAppSelector(selectListItemsTotalCount)
  const fetchParams = useAppSelector(selectCityFetchParams)
  const selectedListType = useAppSelector(selectSelectedListType)

  const [tableData, setTableData] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    dispatch(GetAllCountriesThunk())
  }, [dispatch])

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    setIsDataLoading(true)
    dispatch(GetCityCountryListThunk({fetchParams, source, searchType: selectedListType}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, selectedListType, fetchParams])

  useEffect(() => {
    setTableData(selectedListType === 'city' ? cities : countries)
  }, [cities, countries, selectedListType])

  const setFetchParams = (params: CityFetchParamsType) => {
    dispatch(setCityFetchParams(params))
  }

  const selectListType = (listType: 'city' | 'country') => {
    dispatch(setSelectedListType(listType))
  }

  const handleSelectListType = (selectedType: 'city' | 'country') => {
    setFetchParams({
      ...fetchParams,
      pagination: {...fetchParams.pagination!, page: 1},
      name: '',
      country_id: undefined,
      state_name: ''
    })
    selectListType(selectedType)
  }
  
  const handleNameSearchDebounce = (searchParam: string, searchRequest: string) => {
    setFetchParams({...fetchParams, pagination: {...fetchParams.pagination!, page: 1}, [searchParam]: encodeURI(searchRequest.replace(/^\s+/g, ''))})
  }

  const editItemLink = selectedListType === 'city' ? '/city-country/city/edit/' : '/city-country/country/edit/'
  
  return (
    <>
      <h1>
        City / Country
      </h1>
      <div className={classes.pageHeader}>
        <div>
          {selectedListType === 'city' &&
            <Input.Group compact>
              <Select
                placeholder='Country'
                style={{ width: '200px' }}
                allowClear
                onClear={() => setFetchParams({...fetchParams, country_id: undefined})}
                suffixIcon={<SearchIcon style={{marginRight: '10px', marginBottom: '3px'}}/>}
                value={fetchParams?.country_id}
                showSearch
                filterOption={(input, option) => {
                  const countryName = option!.children as unknown as string
                  return countryName?.toLowerCase().includes(input.toLowerCase())
                }}
                onChange={(val) => setFetchParams({...fetchParams, country_id: val})}
              >
                {sortBy(allCountries, country => country.name).map(country => (
                  <Select.Option value={country.id} key={country.code}>{country.name}</Select.Option>
                ))}
              </Select>
              <Input
                style={{ width: '200px' }}
                placeholder='State name'
                suffix={<SearchIcon style={{marginBottom: '3px'}}/>}
                onChange={(e) => handleNameSearchDebounce('state_name', e.target.value)}
                value={decodeURIComponent(fetchParams?.state_name as string)}
              />
            </Input.Group>
          }
        </div>
        <div className={classes.tools}>
          <div>
            <Select
              value={selectedListType}
              onChange={(val) => handleSelectListType(val)}
              className={classes.resultsFilter}
            >
              <Select.Option value={'city'}>Show cities</Select.Option>
              <Select.Option value={'country'}>Show countries</Select.Option>
            </Select>
          </div>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            onChange={(e) => handleNameSearchDebounce('name', e.target.value)}
            value={decodeURI(fetchParams?.name || '')}
          />
          <Link to={selectedListType === 'city' ? '/city-country/city/create' : '/city-country/country/create'}>
            <Button type='primary'>
              + Add {selectedListType === 'city' ? 'City' : 'Country'}
            </Button>
          </Link>
        </div>
      </div>
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        // deleteItem={() => {}}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={listItemsTotalCount}
        columnList={selectedListType === 'city' ? (
          ['name', 'country_name', 'code', 'latitude', 'longitude', 'state_code', 'state_name', 'alias', 'actions']
        ) : (
          ['name', 'code', 'actions']
        )}
        rowIdKey='id'
      />
    </>
  )
}

export default CityCountryList
