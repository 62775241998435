import { useEffect, useState } from 'react'
import { Button, Checkbox, Form, InputNumber, Popover, Select, Spin, Tooltip } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { sortBy } from 'lodash'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './AirlineDetailsForm.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CreateAirlineThunk, EditAirlineThunk, GetAirlineByIdThunk, GetAllAlliancesThunk, selectAllianceList, selectCurrentAirline, setCurrentAirline } from '../../../store/airlinesReducer'
import redDot from './../../../img/icons/redDot.png'
import { ReactComponent as InfoIcon } from './../../../img/icons/info.svg'
import {ReactComponent as QuestionMarkIcon} from './../../../img/icons/questionMark.svg'
import { AirlineType } from '../../../types/airlineTypes'
import InputField from '../../common/formFields/InputField'

const AirlineDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentAirline = useAppSelector(selectCurrentAirline)
  const allianceList = useAppSelector(selectAllianceList)

  const [form] = Form.useForm()
  const [validationError, setValidationError] = useState('')
  const [isSaving, setIsSaving] = useState(false)
  const [isApproved, setIsApproved] = useState(false)

  useEffect(() => {
    dispatch(GetAllAlliancesThunk())
    if (isEditing) {
      // pathname example: /airlines/edit/11111
      const id = location.pathname.split('edit/')[1]
      dispatch(GetAirlineByIdThunk(+id))
    }
    return () => {
      dispatch(setCurrentAirline({} as AirlineType))
    }
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && !!Object.keys(currentAirline || {})?.length) {
      form.setFieldsValue(currentAirline)
      setIsApproved(currentAirline?.is_approved)
    }
  }, [isEditing, currentAirline, form])

  const sendData = async(formData: any) => {
    if (isEditing) {
      return dispatch(EditAirlineThunk({airlineData: formData, airlineId: currentAirline?.id}))
    } else {
      return dispatch(CreateAirlineThunk(formData))
    }
  }

  const handleSave = async() => {
    let formValues = form.getFieldsValue(true)
    const airlineData = {
      code: formValues?.code,
      icao_code: formValues?.icao_code,
      name: formValues?.name,
      short_name: formValues?.short_name,
      carr_type: formValues?.carr_type,
      phone: formValues?.phone,
      url: formValues?.url,
      alliance_id: formValues?.alliance_id,
      is_approved: isApproved,
    }
    if (!airlineData?.code && !airlineData?.icao_code) {
      setValidationError('Enter at least one code: IATA or ICAO!')
      return
    }
    setIsSaving(true)
    sendData(airlineData)
      .then((resp) => {
        setIsSaving(false)
        !resp?.type?.includes('rejected') && navigate('/airlines')
      })
  }

  if (isEditing && !Object.keys(currentAirline || {})?.length) {
    return <Spin className={classes.spinner} />
  }

  return (
    <>
      <Form
        name='airline'
        onFinish={handleSave}
        autoComplete='off'
        validateTrigger='onBlur'
        className={classes.wrapper}
        form={form}
      >
        <div>
          <Breadcrumbs />
          <h1>
            {isEditing ? currentAirline?.name : 'Adding a new airline'}
            {currentAirline?.is_approved === false &&
              <Tooltip title='Data needs to be approved'>
                <img src={redDot} alt='needs attention' className={classes.redDot}/>
              </Tooltip>
            }
          </h1>
        </div>

        <div className={classes.formBlock}>
          <div className={classes.doubleInputArea}>
            <InputField
              name='name'
              requiredMessage={`Please enter airline name!`}
              placeholder='Enter Name'
              label={`Airline Name`}
            />
            <InputField
              name='short_name'
              requiredMessage={`Please enter airline short name!`}
              placeholder='Enter Short Name'
              label={`Airline Short Name`}
            />
          </div>

          <div className={classes.doubleInputArea}>
            <InputField
              name='code'
              // requiredMessage={`Please enter IATA!`}
              placeholder='Enter IATA'
              label={`IATA`}
              rules={[() => ({min: 2, message: `IATA must include 2 letters`})]}
              maxLength={2}
            />
            <InputField
              name='icao_code'
              placeholder='Enter ICAO'
              label={`ICAO`}
              rules={[() => ({min: 3, message: `IATA must include 3 letters`})]}
              maxLength={3}
            />
          </div>

          <div className={classes.doubleInputArea}>
            <InputField
              name='url'
              placeholder='Enter Website'
              label='Website'
            />

            <div>
              <div className={classes.label}>
                Phone
                <Popover content={'Only numbers are allowed'}>
                  <QuestionMarkIcon style={{marginLeft: '5px', cursor: 'pointer', width: '10px', position: 'absolute'}}/>
                </Popover>
              </div>
              <Form.Item name='phone'>
                <InputNumber
                  placeholder='Enter Phone'
                  style={{width: '100%'}}
                  controls={false}
                />
              </Form.Item>
            </div>
          </div>

          <div>
            <div className={classes.label}>
              Alliance
            </div>
            <Form.Item
              name='alliance_id'
              // rules={[{ required: true, message: 'Please select alliance!' }]}
            >
              <Select placeholder='Select Alliance' allowClear onClear={() => form.setFieldValue('alliance_id', null)}>
                {sortBy(allianceList, alliance => alliance.name).map(alliance => (
                  <Select.Option value={alliance.id} key={alliance.id}>
                    {alliance.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          
        </div>

        {!!validationError?.length &&
          <div className={classes.error}>
            {validationError}
          </div>
        }
        <div className={classes.btnsArea}>
          <div className={classes.approveData}>
            <div>
              <InfoIcon />
              Airline data is approved
            </div>
            <Checkbox
              checked={isApproved}
              onChange={e => setIsApproved( e.target.checked)}
            />
          </div>
          
          <div className={classes.buttons}>
            <Link to='/airlines'>
              <Button>
                Cancel
              </Button>
            </Link>
            <Button
              type='primary'
              htmlType='submit'
              loading={isSaving}
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  )
}

export default AirlineDetailsForm
