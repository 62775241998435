import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Button, Checkbox, Input, Select } from 'antd'
import { Link } from 'react-router-dom'
import classes from './PortList.module.css'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import axios from './../../helpers/axiosHelper'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectPortFetchParams, setPortFetchParams } from '../../store/appStatusReducer'
import { PortType } from '../../types/portTypes'
import { TransportCategoryFetchParams } from '../../types/appTypes'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import { GetAllPortsThunk, selectPortList, selectPortTotalCount } from '../../store/portsReducer'
import moment from 'moment'

const PortList = () => {
  const dispatch = useAppDispatch()
  const ports = useAppSelector(selectPortList)
  const ports_total_count = useAppSelector(selectPortTotalCount)
  const fetchParams = useAppSelector(selectPortFetchParams)

  const [tableData, setTableData] = useState<PortType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllPortsThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(ports)
  }, [ports])

  // const deleteItem = (port: PortType): void => {
  //   dispatch(DeletePortThunk(port.id))
  // }

  const setFetchParams = (params: TransportCategoryFetchParams) => {
    dispatch(setPortFetchParams(params))
  }
  
  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, name: encodeURI(searchRequest), pagination: {...fetchParams.pagination!, page: 1}})
    }, 350),
    [fetchParams]
  )

  const editItemLink = '/ports/edit/'

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Port List
        </h1>
        <div className={classes.tools} style={{marginBottom: '0px'}}>
          
          <Link to='/ports/create'>
            <Button type='primary'>+ Add New</Button>
          </Link>
        </div>
      </div>
      <div className={classes.tools}>
        <div className={classes.checkboxForUnknown}>
          <Checkbox
            style={{margin: '0px 6px 3px 0px'}}
            checked={fetchParams.is_unk}
            onChange={(e) => setFetchParams({...fetchParams, is_unk: e.target.checked, pagination: {...fetchParams.pagination!, page: 1}})}
          /> Show only unknown
        </div>
        <div>
          <Select
            value={fetchParams.is_cruise}
            onChange={(val) => setFetchParams({...fetchParams, is_cruise: val, pagination: {...fetchParams.pagination!, page: 1}})}
            style={{width: '200px'}}
            className={classes.resultsFilter}
          >
            <Select.Option value={null}>All ports</Select.Option>
            <Select.Option value={true}>Cruise ports</Select.Option>
            <Select.Option value={false}>Not cruise ports</Select.Option>
          </Select>
        </div>
        <div>
          <Select
            value={fetchParams.port_type}
            onChange={(val) => setFetchParams({...fetchParams, port_type: val, pagination: {...fetchParams.pagination!, page: 1}})}
            style={{width: '200px'}}
            className={classes.resultsFilter}
          >
            <Select.Option value={null}>All types</Select.Option>
            <Select.Option value={'PORT'}>Port</Select.Option>
            <Select.Option value={'WAYPOINT'}>Waypoint</Select.Option>
            <Select.Option value={'EXCURSION'}>Excursion</Select.Option>
            <Select.Option value={'GENERIC'}>Generic</Select.Option>
          </Select>
        </div>
        <div>
          <Select
            value={fetchParams.search_filter}
            onChange={(val) => setFetchParams({...fetchParams, search_filter: val, pagination: {...fetchParams.pagination!, page: 1}})}
            style={{width: '200px'}}
            className={classes.resultsFilter}
          >
            <Select.Option value={'ALL'}>Show all</Select.Option>
            <Select.Option value={'NOT_APPROVED'}>Not approved only</Select.Option>
            <Select.Option value={'DONT_DISPLAY'}>Don't display only</Select.Option>
          </Select>
        </div>
        <div>
          <Input.Group compact style={{display:'flex'}}>
            <Select
              style={{ width: '137px' }}
              value={fetchParams.search_param}
              onChange={(val) => setFetchParams({...fetchParams, search_param: val as 'NAME' | 'CODE'})}
            >
              <Select.Option value={'NAME'}>By name</Select.Option>
              <Select.Option value={'CODE'}>By code</Select.Option>
              <Select.Option value={null}>By both</Select.Option>
            </Select>
            <Input
              placeholder='Search'
              suffix={<SearchIcon />}
              defaultValue={decodeURIComponent(fetchParams.name as string)}
              onChange={(e) => handleSearchDebounce(e.target.value.trim())}
              style={{ width: '160px' }}
            />
          </Input.Group>
        </div>
      </div>
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        // deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={ports_total_count}
        columnList={['name', 'code', 'country.name', 'location', 'actions', 'category']}
        rowIdKey='id'
        transportType='port'
        getAdditionalColumns={getAdditionalTableColumns}
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Type',
      dataIndex: 'port_type',
      key: 'port_type',
      ellipsis: true,
      width: "120px",
      render: (_:any, item: any) => {
        const typeLowerCase = item?.port_type?.toLowerCase()
        return item.port_type === null
          ? 'Port'
          : typeLowerCase.charAt(0).toUpperCase() + typeLowerCase.slice(1)
      }
    },
    {
      title: 'Unknown',
      dataIndex: 'is_unk',
      key: 'is_unk',
      ellipsis: true,
      width: '90px',
      render: (_:any, item: any) => {
        return !!item.is_unk
          ? 'Yes'
          : 'No'
      }
    },
    {
      title: 'Created date',
      dataIndex: 'created',
      key: 'created',
      ellipsis: true,
      width: "120px",
      render: (_:any, item: any) => (<>{moment(item.created).format('YYYY-MM-DD')}</>)
    },
    {
      title: 'Cruise Port',
      dataIndex: 'is_cruise',
      key: 'is_cruise',
      // sorter: true,
      // sortOrder: getColumnSortOrder('is_cruise'),
      // onHeaderCell: (column: any) => ({
      //   onClick: () => setColumnSortOrder('is_cruise', column.sortOrder)
      // }),
      ellipsis: true,
      render: (_:any, item: any) => (
        <div
          style={{
            borderRadius: '12px',
            background: item.is_cruise ? '#E6FBD9' : '#FFE8D7',
            color: item.is_cruise ? '#0F5B1D' : '#931222',
            padding: '2px 10px',
            width: '40px',
            textAlign: 'center',
          }}
        >
          {item.is_cruise ? 'Yes' : 'No'}
        </div>
      ),
    },
  ])
}

export default PortList
