import AirportList from '../components/Airports/AirportList'
import AirportDetailsForm from '../components/Airports/AirportDetailsForm/AirportDetailsForm'
import PageNotFound from '../components/PageNotFound/PageNotFound'
import Settings from '../components/Settings/Settings'
import SignIn, { AuthPageOptions } from '../components/SignIn/SignIn'
import ServiceList from '../components/Services/ServiceList'
import ServiceDetailsForm from '../components/Services/ServiceDetailsForm/ServiceDetailsForm'
import CityCountryList from '../components/CityCountry/CityCountryList'
import CityDetailsForm from '../components/CityCountry/CityDetailsForm/CityDetailsForm'
import CountryDetailsForm from '../components/CityCountry/CountryDetailsForm/CountryDetailsForm'
import PortList from '../components/Ports/PortList'
import PortDetailsForm from '../components/Ports/PortDetailsForm/PortDetailsForm'
import BusStationList from '../components/BusStations/BusStationList'
import BusStationDetailsForm from '../components/BusStations/BusStationDetailsForm/BusStationDetailsForm'
import RailwayStationList from '../components/RailwayStations/RailwayStationList'
import RailwayStationDetailsForm from '../components/RailwayStations/RailwayStationDetailsForm/RailwayStationDetailsForm'
import TerminalList from '../components/Terminals/TerminalList'
import TerminalDetailsForm from '../components/Terminals/TerminalDetailsForm/TerminalDetailsForm'
import CruiseLineList from '../components/CruiseLines/CruiseLineList'
import CruiseLineDetailsForm from '../components/CruiseLines/CruiseLineDetailsForm/CruiseLineDetailsForm'
import TrackingList from '../components/Tracking/TrackingList'
import AdvertisingList from '../components/Advertising/AdvertisingList'
import AdvertisingDetailsForm from '../components/Advertising/AdvertisingDetailsForm/AdvertisingDetailsForm'
import CruiseList from '../components/Cruises/CruiseList'
import CruiseDetailsForm from '../components/Cruises/CruiseDetailsForm/CruiseDetailsForm'
import ViewUserAppTool from '../components/Advertising/ViewUserAppTool/ViewUserAppTool'
import BlogList from '../components/Blog/BlogList'
import BlogEntryForm from '../components/Blog/BlogEntryForm/BlogEntryForm'
import GTFSList from '../components/GTFS/GTFSList'
import EventList from '../components/Events/EventList'
import EventDetailsPage from '../components/Events/EventDetailsPage/EventDetailsPage'
import GTFSLogList from '../components/GTFS/GTFSLogList/GTFSLogList'
import AirlineList from '../components/Airlines/AirlineList'
import AirlineDetailsForm from '../components/Airlines/AirlineDetailsForm/AirlineDetailsForm'
import AboutPage from '../components/AboutPage/AboutPage'
import AboutPageDetailsForm from '../components/AboutPage/AboutPageDetailsForm/AboutPageDetailsForm'
import Widgets from '../components/Widgets/Widgets'
import WidgetDetailsPage from '../components/Widgets/WidgetDetailsPage/WidgetDetailsPage'
import FAQ from '../components/FAQ/FAQ'
import FAQEntryForm from '../components/FAQ/FAQEntryForm/FAQEntryForm'

const routes: RouteType[] = [
  {path: '/sign-in', component: SignIn, props: {page: AuthPageOptions.SignIn}, auth: false},
  {path: '/forgot-password', component: SignIn, props: {page: AuthPageOptions.ForgotPassword}, auth: false},
  {path: '/login/create-password', component: SignIn, props: {page: AuthPageOptions.ResetPassword}, auth: false},
  {path: '/sign-in', component: SignIn, props: {page: AuthPageOptions.SignIn}, auth: false},
  {path: '/', component: GTFSList, auth: true},
  {path: '/gtfs', component: GTFSList, auth: true, breadcrumb: 'GTFS'},
  {path: '/gtfs/logs/:id', component: GTFSLogList, auth: true, breadcrumb: 'View logs'},
  {path: '/blog', component: BlogList, auth: true},
  {path: '/blog/create', component: BlogEntryForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add post'},
  {path: '/blog/edit/:id', component: BlogEntryForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit post'},
  {path: '/faq', component: FAQ, auth: true, breadcrumb: 'FAQ'},
  {path: '/faq/create', component: FAQEntryForm, auth: true, breadcrumb: 'Add new question'},
  {path: '/faq/edit/:id', component: FAQEntryForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit question'},
  {path: '/about-page', component: AboutPage, auth: true},
  {path: '/about-page/edit', component: AboutPageDetailsForm, auth: true},
  {path: '/events', component: EventList, auth: true},
  {path: '/events/view/:code', component: EventDetailsPage, auth: true, breadcrumb: 'View event'},
  {path: '/events/edit/:code', component: EventDetailsPage, auth: true, breadcrumb: 'Edit event'},
  {path: '/widgets', component: Widgets, auth: true},
  {path: '/widgets/view/:code', component: WidgetDetailsPage, auth: true, breadcrumb: 'View widget'},
  {path: '/airports', component: AirportList, auth: true},
  {path: '/airports/create', component: AirportDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/airports/edit/:id', component: AirportDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit airport'},
  {path: '/airlines', component: AirlineList, auth: true},
  {path: '/airlines/create', component: AirlineDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/airlines/edit/:id', component: AirlineDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit airline'},
  {path: '/ports', component: PortList, auth: true},
  {path: '/ports/create', component: PortDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/ports/edit/:id', component: PortDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit port'},
  {path: '/services', component: ServiceList, auth: true},
  {path: '/services/create', component: ServiceDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/services/edit/:id', component: ServiceDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit service'},
  {path: '/city-country', component: CityCountryList, auth: true},
  {path: '/city-country/city/create', component: CityDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new city'},
  {path: '/city-country/city/edit/:id', component: CityDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit city'},
  {path: '/city-country/country/create', component: CountryDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new country'},
  {path: '/city-country/country/edit/:id', component: CountryDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit country'},
  {path: '/bus-stations', component: BusStationList, auth: true},
  {path: '/bus-stations/create', component: BusStationDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/bus-stations/edit/:id', component: BusStationDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit bus station'},
  {path: '/railway-stations', component: RailwayStationList, auth: true},
  {path: '/railway-stations/create', component: RailwayStationDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/railway-stations/edit/:id', component: RailwayStationDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit railway station'},
  {path: '/terminals', component: TerminalList, auth: true},
  {path: '/terminals/create', component: TerminalDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/terminals/edit/:id', component: TerminalDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit terminal'},
  {path: '/cruises', component: CruiseList, auth: true},
  {path: '/cruises/create', component: CruiseDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/cruises/edit/:id', component: CruiseDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit cruise'},
  {path: '/cruise-lines', component: CruiseLineList, auth: true},
  {path: '/cruise-lines/create', component: CruiseLineDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/cruise-lines/edit/:id', component: CruiseLineDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit cruise line'},
  {path: '/tracking', component: TrackingList, auth: true},
  {path: '/advertising', component: AdvertisingList, auth: true},
  {path: '/advertising/create', component: AdvertisingDetailsForm, props: {isEditing: false}, auth: true, breadcrumb: 'Add new'},
  {path: '/advertising/create/copy/:id', component: AdvertisingDetailsForm, props: {isEditing: false, isCopy: true}, auth: true, breadcrumb: 'Add new'},
  {path: '/advertising/edit/:id', component: AdvertisingDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'Edit advertisement'},
  {path: '/advertising/view/:id', component: AdvertisingDetailsForm, props: {isEditing: true}, auth: true, breadcrumb: 'View advertisement'},
  {path: '/advertising/showAdvertising', component: ViewUserAppTool, auth: true, breadcrumb: 'Show ads'},
  {path: '/settings', component: Settings, auth: true, breadcrumb: null},
  {path: '*', component: PageNotFound, auth: false, breadcrumb: null},
]

export interface RouteType {
  path: string
  component: React.FC<any>
  auth: boolean
  props?: object
  breadcrumb?: string | null
}

export default routes
