import { useState } from 'react'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import { InputGoogleAPI } from '../../common/InputGoogleAPI/InputGoogleAPI'
import classes from './ViewUserAppTool.module.css'
import { geocodeByAddress } from 'react-places-autocomplete'

const ViewUserAppTool = () => {
  const [locationPlaceId, setLocationPlaceId] = useState('')

  const userAppUrl = window.location.href.includes('admin.relavanti.com/')
    ? `https://www.relavanti.com?place_id=${locationPlaceId}`
    : `https://scopia-user-dev.esverito.com?place_id=${locationPlaceId}`

  const handleLocationSelect = async(value: string) => {
    if (value.length) {
      const addressData = await geocodeByAddress(value)
      setLocationPlaceId(addressData?.[0]?.place_id)
    }
  }
  return (
    <div className={classes.wrapper}>     
      <div>
        <Breadcrumbs />
        <h1>
          Show Advertising
        </h1>
        <div>
          <div className={classes.label}>
            Enter Location
            {!locationPlaceId?.length &&
              <span className={classes.defaultLocationWarning}>
                (For now you are seeing the App from your current location)
              </span>
            }
          </div>
          <InputGoogleAPI
            placeholder='Location from which to view the App'
            onChange={(value: string) => handleLocationSelect(value)}
            style={{width: '100%'}}
            suggestionsStyle={{maxHeight: '150px', overflowY: 'auto'}}
          />
          {!!locationPlaceId?.length &&
            <div className={classes.defaultLocationWarning}>
              After changing location, the app will start from the main page
            </div>
          }
        </div>
      </div>
      <div className={classes.contentWrapper}>

        
        <div className={classes.userAppWrapper}>
          <iframe
            title='userApp'
            src={userAppUrl}
          />
        </div>
      </div>
    </div>
  )
}

export default ViewUserAppTool
