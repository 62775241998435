import { message } from 'antd'
import { useEffect } from 'react'
import Geocode from 'react-geocode'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'
import NavigationPanel from '../components/NavigationPanel/NavigationPanel'
import { AppStatusType, selectAppStatus, selectErrorMessage, selectSuccessMessage } from '../store/appStatusReducer'
import { selectIsLoggedIn } from '../store/userReducer'
import './App.less'
import { useAppSelector } from './hooks'
import routes from './routes'

function App() {
  Geocode.setApiKey("AIzaSyBMiInUWpheUl8SjTyNX3wtZEoUgCVlviw")
  const appStatus = useAppSelector(selectAppStatus)
  const error = useAppSelector(selectErrorMessage)
  const successMessage = useAppSelector(selectSuccessMessage)

  useEffect(() => {
    appStatus === AppStatusType.failed && message.error(error, 2)
    appStatus === AppStatusType.succeeded && message.success(successMessage, 2)
  }, [appStatus, error, successMessage])

  return (
    <div className='app'>
      <Routes>
        {routes.map((route, index) => {
          return route.auth ? (
            <Route key={index} path={route.path} element={<PrivateRoute/>}>
              <Route path={route.path} element={<route.component {...route?.props} />}/>
            </Route>
          ) : (
            <Route key={index} element={<route.component {...route?.props} />} path={route.path}/>
          )
        })}
      </Routes>
    </div>
  )
}

const PrivateRoute = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  return isLoggedIn ? (
    <div className='privateRoute' style={{position: 'relative'}}>
      <NavigationPanel />
      <div>
      </div>
      <div className='appContent'>
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to='/sign-in' />
  )
}

export default App
