import { Button, Checkbox, Form, FormInstance, Spin, Tabs, Tooltip } from 'antd'
import { pickBy } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CreateAirportThunk, EditAirportThunk, GetAirportByIdThunk, selectCurrentAirport, setCurrentAirport } from '../../../store/airportsReducer'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './AirportDetailsForm.module.css'
import { GetAllCountriesThunk, selectCityList, selectCurrentCity, setCities, setCurrentCity } from '../../../store/locationsReducer'
import { AirportType } from '../../../types/airportTypes'
import redDot from './../../../img/icons/redDot.png'
import {ReactComponent as InfoIcon} from './../../../img/icons/info.svg'
import FlightsConnected from './FlightsConnected/FlightsConnected'
import PhotoTab from '../../common/transportItemForm/PhotoTab/PhotoTab'
import MainInformationTab from '../../common/transportItemForm/MainInformationTab/MainInformationTab'
import { CityType } from '../../../types/locationTypes'

const AirportDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentAirport = useAppSelector(selectCurrentAirport)
  const currentCity = useAppSelector(selectCurrentCity)
  const cities = useAppSelector(selectCityList)
  const [form] = Form.useForm()
  const [editingAirportId, setEditingAirportId] = useState(0)
  const [activeTabKey, setActiveTabKey] = useState('1')
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [isApproved, setIsApproved] = useState(false)
  const tabs: TabType[] = [
    {title: 'Main information', key: '1', content: <MainInformationTab form={form} transportName='airport' isEditing={isEditing}/> },
    ...(isEditing ? [{title: 'Flights connected', key: '2', content: <FlightsConnected form={form} />}] : []),
    {title: 'Photo', key: isEditing ? '3' : '2', content: <PhotoTab form={form} isEditing={isEditing} />}
    // {title: 'Additional ', key: '3', content: <Additional />},
  ]
  useEffect(() => {
    dispatch(GetAllCountriesThunk())
    return () => {
      dispatch(setCurrentCity({} as CityType))
    }
  }, [dispatch])

  const handleChangeTab = (activeKey: string) => {
    setActiveTabKey(activeKey)
    setErrors([])
  }

  const onFinishFailed = (errors: any) => {
    const errorsData = errors?.errorFields?.map((error:any) => error.errors).flat(1) || []
    const formValues = form.getFieldsValue(true)
    !formValues?.country_code && errorsData.push('The address is invalid!')
    if (!formValues?.city_name || (!!cities.length && !cities.some(c => c.name === formValues?.city_name?.split(' (')[0]))) {
      errorsData.push('Wrong city name!')
    }
    setErrors(errorsData)
  }

  const sendData = async(formData: any) => {
    if (isEditing) {
      return dispatch(EditAirportThunk({airportData: formData, airportId: editingAirportId}))
    } else {
      return dispatch(CreateAirportThunk(formData))
    }
  }

  const handleSave = async() => {
    let formValues = form.getFieldsValue(true)
    const hasAddressData = (!!formValues?.country_code && !!formValues?.city_name) || !!formValues?.place_id

    if (!hasAddressData) {
      return onFinishFailed({})
    }

    const airportData = {
      ...pickBy(formValues, (_, key) => key !== 'photos' 
        && key !== 'photo_urls'
        && key !== 'country'
        && key !== 'city_name'
        && key !== 'country_id'
        && key !== 'country_code'
      ),
      phones: formValues?.phones?.filter((p: string) => p.length > 0) || [],
      city_id: !!formValues?.place_id ? undefined : (currentCity.id ? currentCity.id : currentAirport?.country.cities[0].id),
    }
    const formData = new FormData()
    formData.append('airport', new Blob([JSON.stringify(airportData, null, 2)], {type: 'application/json'}))
    !!formValues?.photos?.length && formValues.photos.forEach((p:any) => formData.append(isEditing ? 'add_photos' : 'photos', p.originFileObj))
    !!isEditing && !!formValues?.delete_photos?.length && formData.append('delete_photos', new Blob([JSON.stringify({urls: formValues?.delete_photos}, null, 2)], {type: 'application/json'}))

    setIsSaving(true)
    sendData(formData)
      .then((resp) => {
        setIsSaving(false)
        !resp?.type.includes('rejected') && navigate('/airports')
      })
  }

  useEffect(() => {
    if (isEditing) {
      // pathname example: /airports/edit/47895
      const id = location.pathname.split('edit/')[1]
      setEditingAirportId(+id)
      dispatch(GetAirportByIdThunk(+id))
    }
    return () => {
      dispatch(setCurrentAirport({} as AirportType))
      dispatch(setCities([]))
    }
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && !!Object.keys(currentAirport)?.length) {
      const airportFieldsData = {
        ...currentAirport,
        placeId: currentAirport?.place_id,
        country_code: currentAirport?.country.code,
        city_name: currentAirport?.country.cities[0]?.name !== null
          ? currentAirport?.country.cities[0]?.name + `${currentAirport?.country.cities[0]?.state_name?.length ? ' ('+currentAirport?.country.cities[0]?.state_name+')' : ''}`
          : '',
      }
      form.setFieldsValue(airportFieldsData)
      setIsApproved(form.getFieldValue('is_approved') === null ? true : form.getFieldValue('is_approved'))
    }
  }, [currentAirport, form, isEditing, dispatch])

  const handleApprovalChange = (isApproved:boolean) => {
    setIsApproved(isApproved)
    form.setFieldsValue({'is_approved': isApproved})
  }

  if (isEditing && !Object.keys(currentAirport).length) {
    return <Spin className={classes.spinner} />
  }

  return (
    <Form
      name='mainInformation'
      onFinish={handleSave}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentAirport?.name : 'Adding a new airport'}
          {currentAirport?.is_approved === false &&
            <Tooltip title='Data needs to be approved'>
              <img src={redDot} alt='needs attention' className={classes.redDot}/>
            </Tooltip>
          }
        </h1>
        <Tabs activeKey={activeTabKey} onChange={(activeKey) => handleChangeTab(activeKey)}>
          {tabs.map((tab) => (
            <Tabs.TabPane tab={tab.title} key={tab.key}>
              {tab.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
      <div className={classes.navigationBtnWrapper}>
        {!!errors.length && activeTabKey === String(tabs.length) &&
          <div className={classes.errors}>
            {errors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
        }
        <div className={classes.btnsArea}>
          {!!isEditing && activeTabKey === String(tabs.length) ? (
            <div className={classes.approveData}>
              <div>
                <InfoIcon />
                Airport data is approved
              </div>
              <Checkbox checked={isApproved} onChange={e => handleApprovalChange(e.target.checked)}/>
            </div>
          ) : (
              <div></div>
          )}
          <div className={classes.buttons}>
            {activeTabKey !==  '1' &&
              <div
                className={classes.backBtn}
                onClick={(e) => setActiveTabKey((+activeTabKey - 1).toString())}
              >
                Back
              </div>
            }
            <Link to='/airports'>
              <Button className={classes.navigationBtn}>
                Cancel
              </Button>
            </Link>
            {activeTabKey !== String(tabs.length) ? (
              <Button
                type='primary'
                className={classes.navigationBtn}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTabKey((+activeTabKey + 1).toString())
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                type='primary'
                htmlType='submit'
                className={classes.navigationBtn}
                loading={isSaving}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </Form>
  )
}

interface TabType {
  title: string
  key: string
  content: ReactElement<any, any>
}

export interface FormTabProps {
  form: FormInstance
  isEditing: boolean
}

export default AirportDetailsForm
