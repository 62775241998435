import { Checkbox, Input, Popover, Upload, message } from 'antd';
import { dummyRequest, normFile } from '../../../../helpers/files_helper';
import classes from './UploadBlogImages.module.css'
import {ReactComponent as UploadIcon} from './../../../../img/airportDetailsForm/uploadIcon.svg'
import {ReactComponent as CloseIcon} from './../../../../img/icons/close.svg'
import {ReactComponent as PictureIcon} from './../../../../img/icons/picture.svg'
import {ReactComponent as QuestionMarkIcon} from './../../../../img/icons/questionMark.svg'
import { BlogFormImageType } from '../../../../types/blogTypes';

const UploadBlogImages: React.FC<UploadBlogImagesPropTypes> = ({
  removePhoto,
  addPhoto,
  images,
  updateImagesData,
  noCover,
  maxCount
}) => {
  const defaultMaxCount = 10

  const onCoverChange = (uid: string, index: number, isCover: boolean) => {
    if (!!isCover) {
      updateImagesData(images.map(i => i.preview.uid === uid ? {...i, is_cover: isCover} : {...i, is_cover: false}))
    } else {
      updateImagesData(images.map((img, i) => {
        if (img.preview.uid === uid) {
          return {...img, is_cover: false}
        } else {
          return {...img, is_cover: (index === 0 && i === 1) || i === 0 ? true : false}
        }    
      }))
    }
  }

  const beforeUpload = (file: any) => {
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) message.error('File must be less than 1 mb')
    return isLt1M ? true : Upload.LIST_IGNORE
  }

  return (
    <div className={classes.imagesAreaWrapper}>
      {images?.length > 0 && images.map((image, index) => (
        <div className={classes.imagePreviewWrapper} key={image.preview.url}>
          <div style={{position: 'relative'}}>
            <img src={image.preview.url} alt='preview' />
            <div className={classes.removePhotoBtn}>
              <CloseIcon onClick={() => removePhoto(image.preview.uid, !!image?.isNew)}/>
            </div>
          </div>
          
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 20px', alignItems: 'center', columnGap: '9px'}}>
              <Input
                placeholder='Alternative text'
                onChange={(e) => updateImagesData(images.map(i => i.preview.uid === image.preview.uid ? {...i, alt: e.target.value} : i))}
                value={images[index].alt}
                style={{height: 'max-content'}}
              />
              <Popover
                content={'Short image description, that can be used by screen readers and Google crawlers'}
                placement='topRight'
              >
                <QuestionMarkIcon />
              </Popover>
            </div>
            {!noCover &&
              <Checkbox
                checked={images[index].is_cover}
                onChange={(e) => onCoverChange(images[index].preview.uid, index, e.target.checked)}
                disabled={images?.length === 1}
              >
                Cover image
              </Checkbox>
            }
          </div>
        </div>
      ))}
      {((!!maxCount && images?.length < maxCount) || (!maxCount && images?.length < defaultMaxCount) || !images?.length) &&
        <Upload.Dragger
          name='files'
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          className={`${classes.uploadDragger}`}
          accept={'image/png, image/jpeg'}
          maxCount={maxCount || defaultMaxCount}
          onChange={(e) => normFile(e, addPhoto)}
          showUploadList={false}
        >
          {!!images?.length && ((!!maxCount && images.length < maxCount) || (!maxCount && images.length < defaultMaxCount)) && 
            <>
              <PictureIcon />
              <div>
                + Add more
              </div>
            </>
          }

          {!images?.length &&
            <>
              <UploadIcon />
              <div className={classes.uploadTitle}>
                Click or drag photo to this area to upload
              </div>
            </>
          }
        </Upload.Dragger>
      }
    </div>
  )
}

interface UploadBlogImagesPropTypes {
  addPhoto: (file: any) => Promise<void>
  removePhoto: (uid: string, isNew: boolean) => void
  images: BlogFormImageType[]
  disabled?: boolean
  updateImagesData: (images: BlogFormImageType[]) => void
  noCover?: boolean
  maxCount?: number
}

export default UploadBlogImages
