import { useState } from 'react'
import { Button, Form } from 'antd'
import { Lottie } from '@crello/react-lottie'
import { Link, Navigate, useLocation } from 'react-router-dom'
import classes from './SignIn.module.css'
import plane from './../../img/signIn/plane.png'
import logo from './../../img/logo.png'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { SignInDataType } from '../../types/userTypes'
import { CreateNewPasswordThunk, LoginThunk, selectIsLoggedIn, SendPasswordEmailThunk } from '../../store/userReducer'
import InputField from '../common/formFields/InputField'
import successAnimation from './../../img/signIn/success.json'

export enum AuthPageOptions {
  SignIn = 'signIn',
  ForgotPassword = 'forgotPassword',
  ResetPassword = 'resetPassword',
}

const SignIn: React.FC<AuthPropTypes> = ({page}) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)

  if (isLoggedIn) {
    return <Navigate to='/' />
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.contentWrapper}>
        <img src={plane} alt='' className={classes.plane}/>
        {page === AuthPageOptions.SignIn && <SignInForm />}
        {page === AuthPageOptions.ForgotPassword && <ForgotPassword />}
        {page === AuthPageOptions.ResetPassword && <ResetPassword />}
      </div>
    </div>
  )
}

const SignInForm = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = (formData: SignInDataType) => {
    setIsLoading(true)
    dispatch(LoginThunk(formData))
      .then(() => setIsLoading(false))
  }

  return (
    <Form
      name='signIn'
      onFinish={onFinish}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
    >
      <img src={logo} alt='logo' className={classes.logo}/>
      <h2 className={classes.formTitle}>
        Sign In
      </h2>
      <div className={classes.description}>
        Enter your email and password to Sign In
      </div>

      <InputField
        name='email'
        placeholder='Email'
        rules={[{ type: 'email', message: 'Invalid e-mail!' }]}
        requiredMessage='Please enter your email!'
      />

      <InputField
        name='password'
        placeholder='Enter password'
        type='password'
        requiredMessage='Please enter your password!'
      />

      <Link to='/forgot-password' className={classes.forgotPassword}>
        Forgot your password?
      </Link>

      <Form.Item>
        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
          SIGN IN
        </Button>
      </Form.Item>

      {/* <div className={classes.additionalLink}>
        Don’t have an account? <Link to='/s'>Sign Up</Link>
      </div> */}
    </Form>
  )
}

const ForgotPassword = () => {
  const dispatch = useAppDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)
  const [email, setEmail] = useState('')

  const sendMessage = async(email: string, showSuccessMessage: boolean) => {
    return dispatch(SendPasswordEmailThunk({email, showSuccessMessage}))
  }

  const onSubmit = (formData: {email: string}) => {
    setIsLoading(true)
    setEmail(formData.email)
    sendMessage(formData.email, false)
      .then(r => {
        setIsLoading(false)
        !r.type.includes('rejected') && setIsSent(true)
      })
  }

  if (isSent) {
    return (
      <div className={classes.form}>
        <h2 className={classes.formTitle}>
          {`We sent a password reset link to \n ${email}`}
        </h2>
        <div className={classes.additionalLink}>
          Didn’t receive the email?
          <span
            style={{color: 'var(--app-primary-color)', cursor: 'pointer', marginLeft: '5px'}}
            onClick={() => sendMessage(email, true)}
          >
            Click to resend
          </span>
        </div>
      </div>
    )
  }

  return (
    <Form
      name='forgotPassword'
      onFinish={onSubmit}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
    >
      <h2 className={classes.formTitle}>
        Forgot your password?
      </h2>
      <div className={classes.description}>
        {'Please enter your email address and we’ll send you \n a link to reset your password'}
      </div>

      <InputField
        name='email'
        placeholder='Email'
        rules={[{ type: 'email', message: 'Invalid e-mail!' }]}
        requiredMessage='Please enter your email!'
      />
      
      <Form.Item>
        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
          Reset password
        </Button>
      </Form.Item>

      <div className={classes.additionalLink}>
        Back to  <Link to='/sign-in'>Sign in</Link>
      </div>
    </Form>
  )
}

const ResetPassword = () => {
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(false)
  const [isCreated, setIsCreated] = useState(false)

  const onSubmit = (formData: {password: string, repeatPassword: string}) => {
    setIsLoading(true)
    // location.search example: ?token=be82790e-06c3-4f06-a016-dc9f12451d0e
    const token = location.search.substr(location.search.indexOf('=') + 1)
    dispatch(CreateNewPasswordThunk({password: formData.password, token}))
      .then((resp) => {
        setIsLoading(false)
        !resp.type.includes('rejected') && setIsCreated(true)
      })
  }

  if (isCreated) {
    return (
      <div className={classes.form}>
        <Lottie
          config={{animationData: successAnimation}}
          height='53px'
          width='53px'
          style={{margin: '0 auto'}}
        />
        <h1 className={classes.formTitle}>
          Password reset
        </h1>
        <div className={classes.description}>
          Your password has been successfully reset
        </div>
        <Link to='/sign-in'>
          <Button type='primary'>
            Continue
          </Button>
        </Link>
      </div>
    )
  }

  return (
    <Form
      name='resetPassword'
      onFinish={onSubmit}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.form}
    >
      <h2 className={classes.formTitle}>
        Set new password
      </h2>

      <InputField
        name='password'
        placeholder='Enter new password'
        type='password'
        requiredMessage='Please enter your password!'
        rules={[{ min: 6, message: 'Password must be at least 6 characters '}]}
      />

      <InputField
        name='repeatPassword'
        dependencies={['password']}
        placeholder='Confirm password'
        type='password'
        requiredMessage='Please repeat your password!'
        matchWith={{field: 'password', message: 'The two passwords that you entered do not match!'}}
      />

      <Form.Item>
        <Button type='primary' htmlType='submit' className={classes.submitBtn} loading={isLoading}>
          Reset password
        </Button>
      </Form.Item>

      <div className={classes.additionalLink}>
        Back to  <Link to='/sign-in'>Sign in</Link>
      </div>
    </Form>
  )
}

interface AuthPropTypes {
  page: AuthPageOptions
}

export default SignIn
