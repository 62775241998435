import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GtfsAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { GTFSListFetchParamsType, GTFSLogType, GTFSType } from '../types/gtfsTypes'
import { PaginationRequestTypes } from '../types/appTypes'

const gtfsListFetchParamsDefaultValue = {
  pagination: {page: 1, size: 10 as 10},
  filter: {
    file_name: '',
    country_name: '',
    city_name: '',
    search_value: ''
  }
}

interface InitialStateType {
  gtfsList: null | GTFSType[]
  gtfsListTotalCount: number
  gtfsListFetchParams: GTFSListFetchParamsType
  gtfsLogList: null | GTFSLogType[]
  gtfsLogListTotalCount: number
  gtfsLogListFetchParams: PaginationRequestTypes
}

const initialState: InitialStateType = {
  gtfsList: null,
  gtfsListTotalCount: 0,
  gtfsListFetchParams: gtfsListFetchParamsDefaultValue,
  gtfsLogList: null,
  gtfsLogListTotalCount: 0,
  gtfsLogListFetchParams: {page: 1, size: 10},
}

export const gtfsSlice = createSlice({
  name: 'gtfs',
  initialState,
  reducers: {
    setGtfsList: (state, action: PayloadAction<null | GTFSType[]>) => {state.gtfsList = action.payload},
    setGtfsListTotalCount: (state, action: PayloadAction<number>) => {state.gtfsListTotalCount = action.payload},
    setGtfsListFetchParams: (state, action: PayloadAction<GTFSListFetchParamsType>) => {state.gtfsListFetchParams = action.payload},
    setGtfsLogList: (state, action: PayloadAction<null | GTFSLogType[]>) => {state.gtfsLogList = action.payload},
    setGtfsLogListTotalCount: (state, action: PayloadAction<number>) => {state.gtfsLogListTotalCount = action.payload},
    setGtfsLogListFetchParams: (state, action: PayloadAction<PaginationRequestTypes>) => {state.gtfsLogListFetchParams = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetGTFSListThunk.fulfilled, (state, action) => {
        state.gtfsList = action.payload.file_info_list
        state.gtfsListTotalCount = action.payload.total_count
      })
      .addCase(DeleteGTFSFileThunk.fulfilled, (state, action) => {
        // state.gtfsList = state.gtfsList?.filter(gtfs => gtfs.file_id !== action.payload) || []
        // state.gtfsListTotalCount -= 1
      })
      .addCase(UploadGTFSThunk.fulfilled, (state, action) => {
        if (action?.payload !== null) {
          state.gtfsList = [...state?.gtfsList || [], action.payload]
          state.gtfsListTotalCount += 1
        }
      })
      .addCase(GetGTFSLogListThunk.fulfilled, (state, action) => {
        state.gtfsLogList = action.payload.logs
        state.gtfsLogListTotalCount = action.payload.total_count
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.gtfsList = null
        state.gtfsListTotalCount = 0
        state.gtfsListFetchParams = gtfsListFetchParamsDefaultValue
        state.gtfsLogList = null
        state.gtfsLogListTotalCount = 0
        state.gtfsLogListFetchParams = {page: 1, size: 10}
      })
  }
})

export const {
  setGtfsList,
  setGtfsListTotalCount,
  setGtfsListFetchParams,
  setGtfsLogList,
  setGtfsLogListTotalCount,
  setGtfsLogListFetchParams,
} = gtfsSlice.actions

export const selectGtfsList = (state: RootState): null | GTFSType[] => state.gtfs.gtfsList
export const selectGtfsListTotalCount = (state: RootState): number => state.gtfs.gtfsListTotalCount
export const selectGtfsListFetchParams = (state: RootState): GTFSListFetchParamsType => state.gtfs.gtfsListFetchParams
export const selectGtfsLogList = (state: RootState): null | GTFSLogType[] => state.gtfs.gtfsLogList
export const selectGtfsLogListTotalCount = (state: RootState): number => state.gtfs.gtfsLogListTotalCount
export const selectGtfsLogListFetchParams = (state: RootState): PaginationRequestTypes => state.gtfs.gtfsLogListFetchParams

export const GetGTFSListThunk = createAsyncThunk<{file_info_list: GTFSType[], total_count: number}, {fetchParams: GTFSListFetchParamsType, source?: any}, AsyncThunkConfig>(
  'gtfs/getGTFSList',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('pagination', new Blob([JSON.stringify(fetchParams.pagination, null, 2)], {type: 'application/json'}))
      formData.append('filter', new Blob([JSON.stringify(fetchParams.filter, null, 2)], {type: 'application/json'}))
      const { status, data } = await GtfsAPI.getGTFSList(formData, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const DeleteGTFSFileThunk = createAsyncThunk<string, string, AsyncThunkConfig>(
  'gtfs/deleteGTFSFile',
  async (feedInfoId, thunkAPI) => {
    try {
      const { status, data } = await GtfsAPI.deleteGTFSFile(feedInfoId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(feedInfoId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const UploadGTFSThunk = createAsyncThunk<GTFSType | null, {countryId: number, cityId: number, file: any, source: any}, AsyncThunkConfig>(
  'gtfs/uploadGTFS',
  async ({cityId, file, countryId, source}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('archive', file.originFileObj)
      const { status, data } = await GtfsAPI.uploadGTFSFile(countryId, cityId, formData, source)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'File has been successfully uploaded'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      if (error?.response?.status === 504) {
        const requestData = thunkAPI.getState()?.gtfs?.gtfsListFetchParams!
        const listTotalCount = thunkAPI?.getState()?.gtfs?.gtfsListTotalCount
        thunkAPI.dispatch(GetGTFSListThunk({
          fetchParams: {
            ...requestData,
            pagination: {
              ...requestData.pagination,
              page: (listTotalCount / requestData.pagination.size) % 1 === 0
                ? requestData.pagination.page + 1
                : requestData.pagination.page
            }
          }
        }))
        return thunkAPI.fulfillWithValue(null, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
      }
    }
  }
)

export const GetGTFSLogListThunk = createAsyncThunk<{logs: GTFSLogType[], total_count: number}, {fileId: number, fetchParams: PaginationRequestTypes, source?: any}, AsyncThunkConfig>(
  'gtfs/getGTFSLogList',
  async ({fileId, fetchParams, source}, thunkAPI) => {
    try {
      const { status, data } = await GtfsAPI.getGTFSLogList(fileId, fetchParams, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default gtfsSlice.reducer
 