import { Form, FormInstance, Input } from 'antd'
import { useEffect } from 'react'
import classes from './CoordinatesField.module.css'
import InputField from '../InputField'

const CoordinatesField: React.FC<{form: FormInstance, setMapCenter: (data: [number, number]) => void, coordinates: string, setCoordinates: (c: string) => void}> = ({form, setMapCenter, coordinates, setCoordinates}) => {
  const latitudeValue = Form.useWatch('latitude', form)
  const longitudeValue = Form.useWatch('longitude', form)

  useEffect(() => {
    if (!!latitudeValue && !!longitudeValue && !coordinates.length) {
      setCoordinates(`${latitudeValue}, ${longitudeValue}`)
      setMapCenter([+latitudeValue, +longitudeValue])
    }
    // eslint-disable-next-line
  }, [longitudeValue, latitudeValue, coordinates])

  const onCoordinatesChange = (coordinates: string) => {
    const containsOnlyAllowedSymbols = /[^a-zA-z]+$|^$/g.test(coordinates)
    if (!!containsOnlyAllowedSymbols) {
      setCoordinates(coordinates[coordinates.length - 1] === ',' ? coordinates + ' ': coordinates)
    }

    const isFullCoordinatesString = /-?(\d*\.)?\d+,\s*-?(\d*\.)\d+/.test(coordinates) && !!containsOnlyAllowedSymbols
    if (isFullCoordinatesString) {
      const [latitude, longitude] = coordinates.split(', ')
      form.setFieldsValue({latitude: +latitude, longitude: +longitude})
      setMapCenter([+latitude, +longitude])
    } else if (!coordinates.length) {
      form.setFieldsValue({latitude: null, longitude: null})
      setMapCenter([0, 0])
    }
  }

  return (
    <>
      <div style={{marginBottom: '20px'}}>
        <div className={classes.label}>
          Coordinates
        </div>
        <Input
          placeholder='Enter coordinates (example: 50.49085592975587, 30.486457024483787)'
          onChange={(e) => onCoordinatesChange(e.target.value)}
          value={coordinates}
        />
      </div>
      <div className={classes.doubleInputArea}>
        <InputField
          name='latitude'
          label='Latitude'
          disabled
          requiredMessage='Latitude is empty!'
        />
        <InputField
          name='longitude'
          label='Longitude'
          disabled
          requiredMessage='Longitude is empty!'
        />
      </div>
    </>
  )
}

export default CoordinatesField
