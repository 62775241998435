import { debounce } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { Select, Input, Button } from 'antd'
import { Link } from 'react-router-dom'
import axios from './../../helpers/axiosHelper'
import classes from './RailwayStationList.module.css'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectRailwayStationFetchParams, setRailwayStationFetchParams } from '../../store/appStatusReducer'
import { GetAllRailwayStationsThunk, selectRailwayStationList, selectRailwayStationTotalCount } from '../../store/railwayStationsReducer'
import { TransportCategoryFetchParams } from '../../types/appTypes'
import { RailwayStationType } from '../../types/railwayStationTypes'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'
import ItemList from '../common/ItemList/ItemList'

const RailwayStationList = () => {
  const dispatch = useAppDispatch()
  const railway_stations = useAppSelector(selectRailwayStationList)
  const railway_stations_total_count = useAppSelector(selectRailwayStationTotalCount)
  const fetchParams = useAppSelector(selectRailwayStationFetchParams)

  const [tableData, setTableData] = useState<RailwayStationType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllRailwayStationsThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(railway_stations)
  }, [railway_stations])

  // const deleteItem = (railwayStation: RailwayStationType): void => {
  //   dispatch(DeleteRailwayStationThunk(railwayStation.id))
  // }

  const setFetchParams = (params: TransportCategoryFetchParams) => {
    dispatch(setRailwayStationFetchParams(params))
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, name: encodeURI(searchRequest)})
    }, 350),
    [fetchParams]
  )

  const editItemLink = '/railway-stations/edit/'

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Railway Station List
        </h1>
        <Link to='/railway-stations/create'>
          <Button type='primary'>+ Add New</Button>
        </Link>
      </div>
      <div className={classes.tools}>
        <div>
          <Select
            value={fetchParams.search_filter}
            onChange={(val) => setFetchParams({...fetchParams, search_filter: val})}
            style={{width: '200px'}}
            className={classes.resultsFilter}
          >
            <Select.Option value={'ALL'}>Show all</Select.Option>
            <Select.Option value={'NOT_APPROVED'}>Not approved only</Select.Option>
            {/* <Select.Option value={'DONT_DISPLAY'}>Don't display only</Select.Option> */}
          </Select>
        </div>
        <div>
          <Input.Group compact style={{display:'flex'}}>
            <Select
              style={{ width: '187px' }}
              value={fetchParams.search_param}
              onChange={(val) => setFetchParams({...fetchParams, search_param: val as 'NAME' | 'CODE'})}
            >
              <Select.Option value={'NAME'}>By name</Select.Option>
              <Select.Option value={'CODE'}>By code</Select.Option>
              <Select.Option value={null}>By both</Select.Option>
            </Select>
            <Input
              placeholder='Search'
              suffix={<SearchIcon />}
              defaultValue={decodeURIComponent(fetchParams.name as string)}
              onChange={(e) => handleSearchDebounce(e.target.value.trim())}
              style={{ width: '240px' }}
            />
          </Input.Group>
        </div>
      </div>
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        // deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={railway_stations_total_count}
        columnList={['name', 'code', 'country.name', 'location', 'actions']}
        rowIdKey='id'
        transportType='railway'
      />
    </div>
  )
}

export default RailwayStationList
