import { FormInstance } from 'antd'
import { Dispatch, SetStateAction } from 'react'
import UploadFileField from '../../../../common/UploadFileField/UploadFileField'
import { getImageUrl } from '../../../../../helpers/files_helper'
import { ImagePreviewType } from '../../../../../types/appTypes'

const UploadBannerBlock: React.FC<UploadBannerBlockPropTypes> = ({form, preview, setPreview, isEditing}) => {
  const addBannerImage = async(file:any) => {
    const imageUrl = await getImageUrl(file)
    setPreview({uid: file.uid, url: imageUrl})
    form.setFieldsValue({banner: file})
  }

  return (
    <UploadFileField
      addBannerImage={addBannerImage}
      preview={preview}
    />
  )
}

interface UploadBannerBlockPropTypes {
  form: FormInstance
  preview: ImagePreviewType | null
  setPreview: Dispatch<SetStateAction<ImagePreviewType | null>>
  isEditing: boolean
}

export default UploadBannerBlock
