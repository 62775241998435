import { Link } from 'react-router-dom'
import classes from './AboutPage.module.css'
import { Button } from 'antd'
import { ReactComponent as EditIcon } from './../../img/icons/edit.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GetAboutPageThunk, selectAboutPage } from '../../store/blogReducer'
import React, { useEffect } from 'react'

const AboutPage = () => {
  const dispatch = useAppDispatch()
  const pageData = useAppSelector(selectAboutPage)

  useEffect(() => {
   if (pageData === null) {
    dispatch(GetAboutPageThunk())
   }
  }, [dispatch, pageData])

  return (
    <div className={classes.wrapper}>
      <div className={classes.pageHeader}>
        <h1>
          About page
        </h1>
        <div className={classes.tools} style={{marginBottom: '0px'}}>
          <Link to='/about-page/edit'>
            <Button type='primary'>
              <EditIcon style={{marginRight: '7px'}}/>
              Edit
            </Button>
          </Link>
        </div>
      </div>
      <div className={classes.content}>
        {pageData?.id ? (
          <>
            <div
              className={classes.title}
              dangerouslySetInnerHTML={{__html: pageData?.content}}
            >
            </div>
            {!!pageData?.image_list?.[0]?.image_url && 
              <img
                src={pageData?.image_list?.[0]?.image_url}
                alt={pageData?.image_list?.[0]?.comment}
                className={classes.cover}
              />
            }
            {pageData?.image_list?.[0]?.image_title &&
              <div className={classes.imageDetails}>
                {pageData?.image_list?.[0]?.image_title}
              </div>
            }
            {pageData?.child_sections?.map(section => (
              <React.Fragment key={section.id}>
                <div dangerouslySetInnerHTML={{__html: section?.content}} className={classes.sectionContent}>
                </div>
                {!!section?.image_list?.[0]?.image_url && 
                  <img
                    src={section?.image_list?.[0]?.image_url}
                    alt={section?.image_list?.[0]?.comment}
                    className={classes.cover}
                  />
                }
                {section?.image_list?.[0]?.image_title &&
                  <div className={classes.imageDetails}>
                    {section?.image_list?.[0]?.image_title}
                  </div>
                }
              </React.Fragment>
            ))}
          </>
        ) : (
          <div className={classes.emptyPage}>
            You have not created this page yet
          </div>
        )}
      </div>
    </div>
  )
}

export default AboutPage
