import { useEffect, useState } from 'react'
import { Form, Radio, Select } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetAllCruisesThunk, selectCruiseList, setCruises } from '../../../../store/cruisesReducer'
import commonClasses from './../ConnectionField.module.css'
import {ReactComponent as SearchIcon} from './../../../../img/icons/search.svg'
import { AdvertisementEditingType } from '../../../../types/advertisingTypes'
import { sortBy, uniqWith } from 'lodash'
import { CruiseType } from '../../../../types/cruiseType'
import { FormInstance } from 'antd/es/form/Form'
import axios from 'axios'

export const ConnectionToCruise: React.FC<ConnectionToCruisePropTypes> = ({
  itemForConnection,
  disabled,
  currentEditingData,
  form,
  isEditing,
  handleNodeSelect,
}) => {
  const dispatch = useAppDispatch()
  const cruises = useAppSelector(selectCruiseList)

  const [isLoading, setIsLoading] = useState(false)
  const [cruiseName, setCruiseName] = useState('')
  const [cruiseOptions, setCruiseOptions] = useState<CruiseType[] | null>([])
  const [isOptionsOpen, setIsOptionsOpen] = useState(false)
  const [connectionSelectedType, setConnectionSelectedType] = useState<'global' | 'cruise'>(currentEditingData?.advertising_locations?.[0]?.location_type === 'global' ? 'global' : 'cruise')

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    if(!!cruiseName?.length) {
      setIsLoading(true)
      dispatch(GetAllCruisesThunk({name: cruiseName, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsLoading(false)
        }
      })
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, cruiseName])

  useEffect(() => {
    setCruiseOptions(getOnlyUniqueCruiseName(cruises))
  }, [cruises])

  useEffect(() => {
    form.setFieldsValue({node_id_list: []})
    return () => {
      form.setFieldsValue({node_id_list: []})
      setCruiseOptions([])
      setCruiseName('')
      dispatch(setCruises([]))
    }
    // eslint-disable-next-line
  }, [form])

  const getOnlyUniqueCruiseName:(opt: CruiseType[]) => CruiseType[] = (cruiseNameOptions: CruiseType[]) => {
    const a = uniqWith(
      cruiseNameOptions,
      (optA, optB) => optA.name.trim() === optB.name.trim()
        && optA?.start_date_locations?.[0].name.trim() === optB?.start_date_locations?.[0].name.trim()
        && optA?.ship?.name.trim() === optB?.ship?.name.trim()
        && optA?.cruise_route_count === optB?.cruise_route_count
    )
    return a
  }

  const onCruiseSelect = (cruiseId: number) => {
    handleNodeSelect && handleNodeSelect(cruiseId, () => true)
    form.setFieldsValue({node_id_list: [cruiseId]})
    setCruiseName('')
    setCruiseOptions(null)
  }

  const onGlobalSelect = () => {
    form.setFieldsValue({node_id_list: null, is_global: true, node_type: 'Cruise'})
    setCruiseName('')
    setCruiseOptions(null)
  }

  const onCruiseConnectionTypeChange = (val: 'global' | 'cruise') => {
    setConnectionSelectedType(val)
    if (val === 'global') {
      onGlobalSelect()
    } else {
      form.setFieldsValue({is_global: false})
    }
  }

  return (
    <div>
      <div className={`${commonClasses.blockTitle} ${itemForConnection === 'advertisement' ? commonClasses.advertisement : ''}`}>
        Select the cruise of according to the cruise searched by the user:
      </div>
      <Radio.Group
        value={connectionSelectedType}
        onChange={(e) => onCruiseConnectionTypeChange(e.target.value as 'global' | 'cruise')}
        style={{marginBottom: '15px'}}
        disabled={disabled}
      >
        <Radio value='cruise'>Cruise</Radio>
        <Radio value='global'>Global</Radio>
      </Radio.Group>
      {connectionSelectedType === 'cruise' &&
        <Form.Item>
          <Select
            placeholder='Enter the cruise name'
            style={{ width: '65%' }}
            suffixIcon={<SearchIcon style={{marginRight: '10px', marginBottom: '3px'}}/>}
            value={
              isEditing
                ? {value: currentEditingData?.nodes?.[0]?.id!, label: currentEditingData?.nodes?.[0]?.name}
                : undefined
            }
            showSearch
            onSearch={(val) => {
              setCruiseName(val)
              if (!val.length) {
                setCruiseOptions(null)
              }
            }}
            onSelect={(cruise:any) => onCruiseSelect(cruise?.value)}
            filterOption={false}
            labelInValue
            open={isOptionsOpen}
            onDropdownVisibleChange={(isOpen => setIsOptionsOpen(isLoading || isOpen))}
            notFoundContent={isLoading
              ? 'Loading...'
              : cruiseOptions === null ? 'Start typing cruise name' : 'No cruise options found'}
            disabled={disabled}
            searchValue={cruiseName}
          >
            {sortBy(cruiseOptions, cruise => cruise.name).map(cruise => (
              <Select.Option value={cruise.id} key={cruise.id}>
                {cruise.name.trim()} 
                <span style={{color: 'gray', fontSize: '14px', marginLeft: '7px'}}>
                  {!!cruise?.display_info && cruise?.display_info}
                </span>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      }
    </div>
  )
}

interface ConnectionToCruisePropTypes {
  form: FormInstance
  isEditing?: boolean
  currentEditingData?: AdvertisementEditingType | null
  itemForConnection: 'service' | 'advertisement'
  disabled?: boolean
  handleNodeSelect?: (id: number, removeCopiedNodes?: (nodeTypes?: string[]) => boolean) => void
}

export default ConnectionToCruise
