import { FormInstance, Table } from 'antd'
import { useEffect, useState } from 'react'

const FlightsConnected: React.FC<{form: FormInstance}> = ({form}) => {
  const [data, setData] = useState<object[]>([])

  useEffect(() => {
    const formValues = form.getFieldsValue(true)
    Object.keys(formValues)?.length && setData([formValues])
  }, [form])

  const columns = [
    {
      title: 'Arrivals',
      dataIndex: 'arrival_flight_count',
      key: 'arrival_flight_count',
      align: 'center',
    },
    {
      title: 'Departures',
      dataIndex: 'departure_flight_count',
      key: 'departure_flight_count',
      align: 'center',
    },
  ]
  return (
    // @ts-ignore
    <Table dataSource={data} columns={columns} rowKey={'id'} pagination={false} style={{width: '400px'}}/>
  )
}

export default FlightsConnected
