
import { Button, Radio } from 'antd'
import hexRgb from 'hex-rgb'
import { ColorExtractor as ExtractorComponent } from 'react-color-extractor'
import classes from './ColorExtractor.module.css'
import { useState } from 'react'
import { uniq } from 'lodash'

const ColorExtractor: React.FC<{imageUrl: string, onColorSelection: (color: string) => void}> = ({imageUrl, onColorSelection})  => {
  const defaultColor = 'rgba(87,190,192,0.1)'
  const defaultOpacity = '0.1'

  const [colorOptions, setColorOptions] = useState<string[]>([])
  const [selectedColor, setSelectedColor] = useState<string>(defaultColor)

  const getRGBAColorValue = (color: string) => {
    if (color.includes('rgba')) {
      return color
    } else {
      const rgbaData = hexRgb(color)
      return `rgba(${rgbaData.red}, ${rgbaData.green}, ${rgbaData.blue}, ${defaultOpacity})`
    }
  }

  const getColorOptions = (colors: string[]) => {
    return colors.map(color => getRGBAColorValue(color))
  }

  const saveChanges = () => {
    onColorSelection(selectedColor)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.blockTitle}>
        Logo of service
      </div>
      <ExtractorComponent getColors={(options: string[]) => setColorOptions(uniq(getColorOptions(options)))}>
          <img
            src={imageUrl}
            style={{ width: 200, height: 200, objectFit: 'contain', margin: '0 auto', marginBottom: '15px' }}
            alt='for color selection'
          />
        </ExtractorComponent>
        <div className={classes.blockTitle}>
          Select the background color for the banner from the available list
        </div>
        <Radio.Group
          value={selectedColor}
          onChange={(e) => setSelectedColor(getRGBAColorValue(e.target.value))}
          style={{display: 'flex', flexDirection: 'column', marginBottom: '15px'}}
        >
          {colorOptions.map(option => (
            <Radio value={option} key={option} className={classes.colorOption}>
              - {option} <div className={classes.colorSample} style={{backgroundColor: option}}></div>
            </Radio>
          ))}
          <Radio value={defaultColor} className={classes.colorOption}>
            - Use default color <div className={classes.colorSample} style={{backgroundColor: defaultColor}}></div>
          </Radio>
        </Radio.Group>
        <Button type='primary' onClick={saveChanges}>
          Save
        </Button>
    </div>
  )
}
export default ColorExtractor
