import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { pickBy } from 'lodash'
import { userAPI } from '../app/api'
import { tokenDataHelper, userDataHelper } from '../helpers/localStorageHelper'
import { SignInDataType, UserDataType, UserSettingsType } from './../types/userTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { encryptPassword } from '../helpers/funcHelper'

interface InitialStateType {
  isLoggedIn: boolean
  userData: UserDataType
}

const initialState: InitialStateType = {
  isLoggedIn: !!userDataHelper.getUserData(),
  userData: userDataHelper.getUserData(),
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {state.isLoggedIn = action.payload},
    setUserData: (state, action: PayloadAction<UserDataType>) => {state.userData = action.payload},
    setUserAvatar: (state, action: PayloadAction<string>) => {state.userData = {...state.userData, photo_url: action.payload}},
  },
  extraReducers: (builder) => {
    builder
      .addCase(LoginThunk.fulfilled, (state, action) => {
        state.isLoggedIn = true
        state.userData = pickBy(action.payload, (_, key) => key !== 'token') as UserDataType
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.isLoggedIn = false
        state.userData = {} as UserDataType
      })
      .addCase(EditAdminDataThunk.fulfilled, (state, action) => {
        state.userData = pickBy(action.payload, (_, key) => key !== 'token') as UserDataType
      })
  }
})

export const { setIsLoggedIn, setUserData, setUserAvatar } = userSlice.actions

export const selectIsLoggedIn = (state: RootState): boolean => state.user.isLoggedIn
export const selectUserData = (state: RootState): UserDataType => state.user.userData
export const selectUserId = (state: RootState): number => state.user.userData.user_id

export const LoginThunk = createAsyncThunk<UserDataType, SignInDataType, AsyncThunkConfig>(
  'user/login',
  async (loginData, thunkAPI) => {
    try {
      const { status, data } = await userAPI.signIn({
        ...loginData,
        password: encryptPassword(loginData.password)
      })
      if (status === 200 && data) {
        userDataHelper.setUserData(pickBy(data, (_, key) => key !== 'token') as UserDataType)
        tokenDataHelper.setTokenData(data.token!)
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SendPasswordEmailThunk = createAsyncThunk<string, {email: string, showSuccessMessage: boolean}, AsyncThunkConfig>(
  'user/sendPasswordEmail',
  async (emailData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.sendEmailForNewPassword(emailData.email)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: emailData.showSuccessMessage ? AppStatusType.succeeded : AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateNewPasswordThunk = createAsyncThunk<null, {token: string, password: string}, AsyncThunkConfig>(
  'user/createNewPassword',
  async (newPasswordData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.setPassword({
        ...newPasswordData,
        password: encryptPassword(newPasswordData.password)
      })
      if (status === 200) {
        return thunkAPI.fulfillWithValue(null, {appStatus: AppStatusType.succeeded, appMessage: 'Password has been created'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditAdminDataThunk = createAsyncThunk<UserDataType, UserSettingsType, AsyncThunkConfig>(
  'user/editAdminData',
  async (adminData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.editAdminData(adminData)
      if (status === 200 && data) {
        userDataHelper.setUserData(pickBy(data, (_, key) => key !== 'token') as UserDataType)
        tokenDataHelper.setTokenData(data.token!)
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Data has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditPasswordThunk = createAsyncThunk<string, {new_password: string, current_password: string}, AsyncThunkConfig>(
  'user/editPassword',
  async (newPasswordData, thunkAPI) => {
    try {
      const {data, status} = await userAPI.editAdminPassword({
        ...newPasswordData,
        new_password: encryptPassword(newPasswordData.new_password),
        current_password: encryptPassword(newPasswordData.current_password)
      })
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const SignOutThunk = createAsyncThunk<void, void, AsyncThunkConfig>(
  'user/signOut', (_, thunkAPI) => {
    userDataHelper.removeUserData()
    tokenDataHelper.removeTokenData()
    return thunkAPI.fulfillWithValue(_, {appStatus: AppStatusType.idle})
  }
)

export default userSlice.reducer
 