import { useAppSelector } from '../../../../app/hooks'
import { selectOccupiedSlots } from '../../../../store/advertisingReducer'
import { BigBanner } from '../MainData/GenerateBannerBlock/GenerateBannerBlock'
import classes from './AdvertisingPlacement.module.css'

const AddAdvertisementBtn:React.FC<AddAdvertisementBtnPropTypes> = ({top, left, width, height, selectSlot, disabled, isPagePreview}) => {
  return (
    <div
      className={`${classes.bannerSlot} ${disabled || isPagePreview ? classes.disabled : ''}`}
      onClick={() => disabled ? {} : selectSlot()}
      style={{top, left, width, height, cursor: isPagePreview ? 'default' : 'pointer'}}
    >
      {
        isPagePreview ? (
          <div className={classes.emptySlotForPagePreview}>
            <div>A PLACE TO ADVERTISE</div>
            <div>It can be an advertisement for another company</div>
          </div>
        ) : (
          disabled ? height === '5%' ? 'Taken' : 'This slot is taken' : height === '5%' ? '+' : '+ Add Advertisement'
        )
      }
      
    </div>
  )
}

const AdvertisementPreview:React.FC<AdvertisementPreviewPropTypes> = ({top, left, width, height, bannerPreview}) => {
  return (
    <div style={{position: 'absolute', height: height || '5.6%', top, left, width}}>
      {bannerPreview?.selectedBannerType === 'uploaded' &&
        <img
          src={bannerPreview.url}
          alt='banner'
          style={{width: '100%', height: '100%', objectFit: 'scale-down', borderRadius: '9px'}}
        />
      }
      {bannerPreview?.selectedBannerType === 'generated' &&
        <BigBanner 
          bannerData={{bannerText: bannerPreview.banner_comment || '', imagePreview: bannerPreview.url!}}
          bannerSettings={{banner_bg_color: bannerPreview?.banner_bg_color || '', banner_color: bannerPreview?.banner_color || ''}}
        />
      }
      {bannerPreview?.selectedBannerType === 'html' && (
        <div
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
            borderRadius: '9px',
          }}
          dangerouslySetInnerHTML={{__html: bannerPreview?.html!}}
        >
        </div>
      )}
    </div>
  )
}

export const Screen:React.FC<ScreePropTypes> = ({screenImage, slots, selectSlot, bannerPreview}) => {
  const occupiedSlots = useAppSelector(selectOccupiedSlots)
  const htmlEmpty = bannerPreview.selectedBannerType === 'html' && !bannerPreview.html?.length
  const uploadedEmpty = bannerPreview.selectedBannerType === 'uploaded' && !bannerPreview.url?.length
  const generatedEmpty = bannerPreview.selectedBannerType === 'generated' && !bannerPreview.url?.length

  if (bannerPreview.isPagePreview && (htmlEmpty || uploadedEmpty || generatedEmpty)) {
    return (
      <div style={{marginTop: '15px', textAlign: 'center'}}>
        You need to provide banner data to see placement preview!
      </div>
    )
  }
  return (
    <div className={classes.screenWrapper}>
      <img className={classes.screenImg} src={screenImage} alt='page'  style={{width:'100%', height: '100%'}}/> 
      {slots?.map((slot, i) => {
        return slot.place_type === bannerPreview?.place_type && (slot?.place_index === bannerPreview?.place_index || !bannerPreview?.place_index) ? (
          <AdvertisementPreview
            top={slot.top}
            left={slot.left}
            width={slot.width}
            height={slot.height}
            bannerPreview={bannerPreview}
            key={slot.place_type + (slot?.place_index ? slot?.place_index : '') + i}
          />
        ) : (
          <AddAdvertisementBtn
            top={slot.top}
            left={slot.left}
            width={slot.width}
            height={slot.height}
            key={slot.place_type + (slot?.place_index ? slot?.place_index : '') + i}
            selectSlot={() => selectSlot(slot.place_type, slot?.place_index)}
            disabled={occupiedSlots.some(s => (s.place_index === slot.place_index || !slot.place_index) && s.place_type === slot.place_type)}
            isPagePreview={!!bannerPreview?.isPagePreview}
          />
        )
      })}
    </div>
  )
}

interface AddAdvertisementBtnPropTypes {
  top: string
  left: string
  width: string
  height?: string
  selectSlot: () => void
  disabled: boolean
  isPagePreview: boolean
}

interface AdvertisementPreviewPropTypes{
  top: string
  left: string
  width: string
  height?: string
  bannerPreview: PlacementPreviewType
}

interface ScreePropTypes {
  screenImage: string
  slots: ScreenSlotType[]
  selectSlot: (place_type: string, place_index?: number) => void
  bannerPreview: PlacementPreviewType
}

export interface ScreenSlotType {
  top: string
  left: string
  width: string
  height?: string
  place_type: string
  place_index?: number
}

export interface PlacementPreviewType {
  html?: string
  url?: string
  banner_comment?: string
  banner_color?: string
  banner_bg_color?: string
  place_type: string
  place_index?: number
  isPagePreview?: boolean
  selectedBannerType: 'uploaded' | 'generated' | 'html'
}
