import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {CancelTokenSource} from 'axios'
import {locationAPI} from '../app/api'
import {
    CityOptionsType,
    CityType,
    CountryFetchParamsType,
    NewCityDataTypes,
    CountryType,
    CityFetchParamsType,
    NewCountryType
} from '../types/locationTypes'
import {AppStatusType} from './appStatusReducer'
import {AsyncThunkConfig, RootState} from './store'

interface InitialStateType {
    allCountries: CountryType[]

    countries: CountryType[]
    cities: CityType[]
    listItemsTotalCount: number,
    selectedListType: 'city' | 'country',
    country: CountryType
    cityOptions: CityOptionsType[]
    countryOptions: CountryType[]
    distanceUnits: string[],

    currentCity: CityType
    currentCountry: CountryType
}

const initialState: InitialStateType = {
    allCountries: [],
    country: {} as CountryType,
    countries: [],
    cities: [],
    listItemsTotalCount: 0,
    selectedListType: 'city',

    cityOptions: [],
    countryOptions: [],
    distanceUnits: [],

    currentCity: {} as CityType,
    currentCountry: {} as CountryType
}

export const locationsSlice = createSlice({
    name: 'locations',
    initialState,
    reducers: {
        setCountries: (state, action: PayloadAction<CountryType[]>) => {
            state.countries = action.payload
        },
        setCities: (state, action: PayloadAction<CityType[]>) => {
            state.cities = action.payload
        },
        setCityOptions: (state, action: PayloadAction<CityOptionsType[]>) => {
            state.cityOptions = action.payload
        },
        setCountryOptions: (state, action: PayloadAction<CountryType[]>) => {
            state.countryOptions = action.payload
        },
        setCurrentCity: (state, action: PayloadAction<CityType>) => {
            state.currentCity = action.payload
        },
        setCurrentCountry: (state, action: PayloadAction<CountryType>) => {
            state.currentCountry = action.payload
        },
        setCountry: (state, action: PayloadAction<CountryType>) => {
            state.currentCountry = action.payload
        },
        setSelectedListType: (state, action: PayloadAction<'city' | 'country'>) => {
            state.selectedListType = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetAllCountriesThunk.fulfilled, (state, action) => {
                state.allCountries = action.payload
            })
            .addCase(GetCitiesByCountryThunk.fulfilled, (state, action) => {
                state.cities = action.payload
            })
            .addCase(GetCountryThunk.fulfilled, (state, action) => {
                state.country = action.payload
            })
            .addCase(GetCitiesBySearchQueryThunk.fulfilled, (state, action) => {
                state.cityOptions = action.payload
            })
            .addCase(GetCountriesBySearchQueryThunk.fulfilled, (state, action) => {
                state.countryOptions = action.payload
            })
            .addCase(GetDistanceUnitsThunk.fulfilled, (state, action) => {
                state.distanceUnits = action.payload
            })
            .addCase(GetCityCountryListThunk.fulfilled, (state, action) => {
                action.payload.dataType === 'city' ? state.cities = action.payload.data.data as CityType[] : state.countries = action.payload.data.data as CountryType[]
                state.listItemsTotalCount = action.payload.data.total_count
            })
            .addCase(GetCityByIdThunk.fulfilled, (state, action) => {
                state.currentCity = action.payload
            })
            .addCase(GetCountryByIdThunk.fulfilled, (state, action) => {
                state.currentCountry = action.payload
            })
            .addCase(CreateCityThunk.fulfilled, (state, action) => {
                state.cities = [...state.cities, action.payload]
            })
    }
})

export const {
    setCountries,
    setCities,
    setCityOptions,
    setCountryOptions,
    setCurrentCity,
    setCurrentCountry,
    setSelectedListType,
    setCountry
} = locationsSlice.actions

export const selectAllCountries = (state: RootState): CountryType[] => state.locations.allCountries
export const selectCountryList = (state: RootState): CountryType[] => state.locations.countries
export const selectCityList = (state: RootState): CityType[] => state.locations.cities
export const selectCityOptions = (state: RootState): CityOptionsType[] => state.locations.cityOptions
export const selectCountryOptions = (state: RootState): CountryType[] => state.locations.countryOptions
export const selectDistanceUnits = (state: RootState): string[] => state.locations.distanceUnits
export const selectListItemsTotalCount = (state: RootState): number => state.locations.listItemsTotalCount
export const selectSelectedListType = (state: RootState): 'city' | 'country' => state.locations.selectedListType
export const selectCurrentCity = (state: RootState): CityType => state.locations.currentCity
export const selectCurrentCountry = (state: RootState): CountryType => state.locations.currentCountry
export const selectCountry = (state: RootState): CountryType => state.locations.country

export const GetAllCountriesThunk = createAsyncThunk<CountryType[], void, AsyncThunkConfig>(
    'locations/getAllCountries',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.getAllCountries()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.countries, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export const GetCitiesByCountryThunk = createAsyncThunk<CityType[], { countryCode: string, name?: string, source: CancelTokenSource }, AsyncThunkConfig>(
    'locations/getCitiesByCountry',
    async (countryData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await locationAPI.getCitiesByCountry(countryData.source, countryData.countryCode, countryData?.name)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.cities, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)


export const GetCountryThunk = createAsyncThunk<any, { countryCode: string, name?: string, source: CancelTokenSource }, AsyncThunkConfig>(
    'locations/getCountry',
    async (countryData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await locationAPI.getCitiesByCountry(countryData.source, countryData.countryCode, countryData?.name)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const GetCitiesBySearchQueryThunk = createAsyncThunk<CityOptionsType[], { searchQuery: string, source: CancelTokenSource }, AsyncThunkConfig>(
    'locations/getCitiesBySearchQuery',
    async (search, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.getCitiesBySearchQuery(search.searchQuery, search.source)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.countries, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const GetCountriesBySearchQueryThunk = createAsyncThunk<CountryType[], { searchQuery: string, source: CancelTokenSource }, AsyncThunkConfig>(
    'locations/getCountriesBySearchQuery',
    async (search, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.getCountriesWithPagination({name: search.searchQuery}, search.source)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.countries, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const GetDistanceUnitsThunk = createAsyncThunk<string[], void, AsyncThunkConfig>(
    'locations/getDistanceUnits',
    async (_, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.getDistanceUnits()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data.units, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const CreateCityThunk = createAsyncThunk<CityType, { cityData: NewCityDataTypes, countryId?: number }, AsyncThunkConfig>(
    'locations/createCity',
    async (newCityData, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.createCity(newCityData.cityData, newCityData?.countryId)
            if (status === 201 && data) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'City has been added'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const EditCityThunk = createAsyncThunk<CityType, { cityData: NewCityDataTypes, cityId: number, countryId?: number }, AsyncThunkConfig>(
    'locations/editCity',
    async (newCityData, thunkAPI) => {
        try {
            const {
                status,
                data
            } = await locationAPI.editCity(newCityData.cityData, newCityData.cityId, newCityData?.countryId)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'City has been edited'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const CreateCountryThunk = createAsyncThunk<CountryType, NewCountryType, AsyncThunkConfig>(
    'locations/createCountry',
    async (newCountryData, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.createCountry(newCountryData)
            if (status === 201 && data) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Country has been added'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const EditCountryThunk = createAsyncThunk<CountryType, { countryData: NewCountryType, countryId: number }, AsyncThunkConfig>(
    'locations/editCountry',
    async (newCountryData, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.editCountry(newCountryData.countryData, newCountryData.countryId)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {
                    appStatus: AppStatusType.succeeded,
                    appMessage: 'Country has been edited'
                })
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const GetCityCountryListThunk = createAsyncThunk<{ data: { total_count: number, data: CityType[] | CountryType[] }, dataType: 'city' | 'country' }, { fetchParams: CountryFetchParamsType | CityFetchParamsType, source: CancelTokenSource, searchType: 'city' | 'country' }, AsyncThunkConfig>(
    'locations/getCityCountryList',
    async (searchData, thunkAPI) => {
        const getRequest = async () => {
            return searchData.searchType === 'city'
                ? locationAPI.getCitiesWithPagination(searchData.fetchParams, searchData.source).then((resp) => ({
                    data: {
                        data: resp.data.cities,
                        total_count: resp.data.total_count
                    }, status: resp.status
                }))
                : locationAPI.getCountriesWithPagination(searchData.fetchParams, searchData.source).then((resp) => ({
                    data: {
                        data: resp.data.countries,
                        total_count: resp.data.total_count
                    }, status: resp.status
                }))
        }
        try {
            const {status, data} = await getRequest()
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue({
                    data,
                    dataType: searchData.searchType
                }, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const GetCityByIdThunk = createAsyncThunk<CityType, number, AsyncThunkConfig>(
    'locations/getCityById',
    async (cityId, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.getCityById(cityId)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const GetCountryByIdThunk = createAsyncThunk<CountryType, number, AsyncThunkConfig>(
    'locations/getCountryById',
    async (countryId, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.getCountryById(countryId)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
        }
    }
)

export const DisableCountriesForCountryThunk = createAsyncThunk<null, {countryId: number, countryIds: number[]}, AsyncThunkConfig>(
    'locations/disableCountriesForCountry',
    async ({countryId, countryIds}, thunkAPI) => {
        try {
            const {status, data} = await locationAPI.disableCountriesForCountry(countryId, countryIds)
            if (status === 200 && data) {
                return thunkAPI.fulfillWithValue(null, {appStatus: AppStatusType.idle})
            } else {
                return thunkAPI.rejectWithValue(data)
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error?.response?.data?.message)
        }
    }
)

export default locationsSlice.reducer
 