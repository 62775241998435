import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Input } from 'antd'
import { debounce } from 'lodash'
import axios from '../../helpers/axiosHelper'
import classes from './ServiceList.module.css'
import ItemList from '../common/ItemList/ItemList'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DeleteServiceThunk, GetAllServicesThunk, selectServiceList, selectServicesTotalCount } from '../../store/servicesReducer'
import { ServiceFetchParamsType, ServiceType } from '../../types/serviceTypes'
import { selectServiceFetchParams, setServiceFetchParams } from '../../store/appStatusReducer'

const ServiceList = () => {
  const dispatch = useAppDispatch()
  const services = useAppSelector(selectServiceList)
  const services_total_count = useAppSelector(selectServicesTotalCount)
  const fetchParams = useAppSelector(selectServiceFetchParams)

  const [tableData, setTableData] = useState<ServiceType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllServicesThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(services)
  }, [services])

  const setFetchParams = (params: ServiceFetchParamsType) => {
    dispatch(setServiceFetchParams(params))
  }

  const deleteItem = (service: ServiceType): void => {
    dispatch(DeleteServiceThunk(service.service_id))
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, name: encodeURI(searchRequest)})
    }, 350),
    [fetchParams]
  )

  const editItemLink = '/services/edit/'

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Service List
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            defaultValue={decodeURIComponent(fetchParams.name as string)}
          />
          <Link to='/services/create'>
            <Button type='primary'>+ Add New</Button>
          </Link>
        </div>
      </div>
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={services_total_count}
        columnList={['name', 'display_name', 'ranking', 'phones', 'website', 'comment', 'actions']}
        rowIdKey='service_id'
      />
    </div>
  )
}

export default ServiceList
