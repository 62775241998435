import { Button, Checkbox, Form, Select, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classes from './CruiseDetailsForm.module.css'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CruiseRouteUpdatePortType, CruiseType } from '../../../types/cruiseType'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import { AddPortToCruiseRouteThunk, GetCruiseByIdThunk, UpdateCruiseRoutePortsThunk, selectCurrentCruise, setCurrentCruise } from '../../../store/cruisesReducer'
import RoutesBlock from './RoutesBlock/RoutesBlock'
import { pickBy, uniq } from 'lodash'
import { selectCruiseFetchParams, setCruiseFetchParams } from '../../../store/appStatusReducer'

const CruiseDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentCruise = useAppSelector(selectCurrentCruise)
  const fetchParams = useAppSelector(selectCruiseFetchParams)

  const [form] = Form.useForm()

  const [isSaving, setIsSaving] = useState(false)
  const [dateOptions, setDateOptions] = useState<null | string[]>(null)
  const [startDate, setStartDate] = useState('')
  const [changeForAllDatedSelected, setChangeForAllDatedSelected] = useState(false)

  const sendData = async(formData: {change_ports: CruiseRouteUpdatePortType[], added_ports: CruiseRouteUpdatePortType[], is_all_itineraries: boolean}) => {
    return Promise.all([
      ...(formData?.change_ports?.length ? [dispatch(UpdateCruiseRoutePortsThunk({change_ports: formData.change_ports, is_all_itineraries: formData.is_all_itineraries}))] : []),
      ...(formData?.added_ports?.length ? [dispatch(AddPortToCruiseRouteThunk({change_ports: formData.added_ports, is_all_itineraries: formData.is_all_itineraries}))] : [])
    ])
  }

  const handleSave = async() => {
    let formValues = form.getFieldsValue(true)
    setIsSaving(true)
    const changedPorts = (formValues.changePorts || []).map((port:any) => ({...pickBy(port, (_, key) => key !== 'name' && key !== 'code' && key !== 'port_type')}))
    const addedPorts = (formValues.addPorts || []).map((port:any) => ({...pickBy(port, (_, key) => key !== 'name' && key !== 'code' && key !== 'port_type')}))
    sendData({
      change_ports: changedPorts,
      added_ports: addedPorts,
      is_all_itineraries: changeForAllDatedSelected
    })
      .then((resp) => {
        setIsSaving(false)
        !resp?.some(r => r.type.includes('rejected')) && navigate('/cruises')
      })
  }

  useEffect(() => {
    if (isEditing) {
      // pathname example: /cruises/edit/47895&min_port_distance=1000
      const id = location.pathname?.includes('&min_port_distance')
        ? location.pathname.split('edit/')[1]?.split('&min_port_distance')[0]
        : location.pathname.split('edit/')[1]
      dispatch(GetCruiseByIdThunk({id: +id, startDate}))
      if (location.pathname?.includes('&min_port_distance')) {
        dispatch(setCruiseFetchParams({...fetchParams, min_port_distance: +location.pathname.split('&min_port_distance=')[1]}))
      }
    }
    return () => {
      dispatch(setCurrentCruise({} as CruiseType))
    }
    // eslint-disable-next-line
  }, [isEditing, startDate, dispatch, location])

  useEffect(() => {
    if (isEditing && !!Object.keys(currentCruise || {})?.length) {
      if (!startDate?.length) {
        setStartDate(currentCruise?.start_date_locations?.[0]?.departure_date || '')
      }
      if (!dateOptions?.length) {
        setDateOptions(currentCruise?.start_date_locations?.map(l => l.departure_date) || [])
      }
      // const cruiseFieldsData = {...currentCruise}
      // form.setFieldsValue(cruiseFieldsData)
    }
    // eslint-disable-next-line
  }, [currentCruise, form, isEditing, dispatch])

  if ((isEditing && !Object.keys(currentCruise || {})?.length) || (!startDate?.length && !!currentCruise?.cruise_routes?.length)) {
    return <Spin className={classes.spinner} />
  } else if (isEditing && currentCruise?.id && currentCruise?.cruise_routes?.length === 0) {
    return (
      <div className={classes.formBlock} style={{textAlign: 'center'}}>
        <div style={{marginBottom: '10px'}}>
          No routes found
        </div>
        <Link to='/cruises'>Back to cruise list</Link>
      </div>
    )
  }
  return (
    <Form
      name='cruise'
      onFinish={handleSave}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
      initialValues={{}}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentCruise?.name : 'Adding a new cruise'}
        </h1>
        {isEditing &&
          <Select
            style={{width: '230px', marginTop: '10px'}}
            value={startDate}
            onChange={(val) => setStartDate(val)}
          >
            {uniq(dateOptions)?.map(opt => (
              <Select.Option value={opt} key={opt}>
                {opt}
              </Select.Option>
            ))}
          </Select>
        }
        <RoutesBlock form={form}/>
      </div>
      {!!dateOptions?.length && dateOptions?.length > 1 &&
        <Checkbox
          style={{marginTop: '10px'}}
          checked={changeForAllDatedSelected}
          onChange={e => setChangeForAllDatedSelected(e.target.checked)}
        >
          Change port details for all dates on this cruise
        </Checkbox>
      }
      <div className={classes.buttons}>
        <Link to='/cruises'>
          <Button>
            Cancel
          </Button>
        </Link>
          <Button
            type='primary'
            htmlType='submit'
            loading={isSaving}
          >
            Save
          </Button>
      </div>
    </Form>
  )
}

export default CruiseDetailsForm
