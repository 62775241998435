import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { portAPI } from '../app/api'
import { TransportCategoryFetchParams } from '../types/appTypes'
import { PortType } from '../types/portTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

interface InitialStateType {
  ports: PortType[]
  ports_total_count: number
  currentPort: PortType

  portOptions: null | PortType[]
}

const initialState: InitialStateType = {
  ports: [],
  ports_total_count: 0,
  currentPort: {} as PortType,

  portOptions: null
}

export const portsSlice = createSlice({
  name: 'ports',
  initialState,
  reducers: {
    setPorts: (state, action: PayloadAction<PortType[]>) => {state.ports = action.payload},
    setCurrentPort: (state, action: PayloadAction<PortType>) => {state.currentPort = action.payload},
    setPortOptions: (state, action: PayloadAction<PortType[] | null>) => {state.portOptions = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllPortsThunk.fulfilled, (state, action) => {
        if (action.payload.isOptionList) {
          state.portOptions = action.payload.ports
        } else {
          state.ports = action.payload.ports
          state.ports_total_count = action.payload.total_count
        }
      })
      .addCase(GetPortByIdThunk.fulfilled, (state, action) => {
        state.currentPort = action.payload
      })
      .addCase(CreatePortThunk.fulfilled, (state, action) => {
        state.ports = [...state.ports, action.payload]
      })
      .addCase(EditPortThunk.fulfilled, (state, action) => {
        state.ports = state.ports.map(port => {
          return port.id === action.payload.id ? action.payload : port
        })
      })
      .addCase(DeletePortThunk.fulfilled, (state, action) => {
        state.ports = state.ports.filter(port => port.id !== action.payload)
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.ports = []
        state.currentPort = {} as PortType
        state.portOptions = null
      })
  }
})

export const {
  setPorts,
  setCurrentPort,
  setPortOptions
 } = portsSlice.actions

export const selectPortList = (state: RootState): PortType[] => state.ports.ports
export const selectPortTotalCount = (state: RootState): number => state.ports.ports_total_count
export const selectCurrentPort = (state: RootState): PortType => state.ports.currentPort
export const selectPortOptions = (state: RootState): null | PortType[] => state.ports.portOptions

export const GetAllPortsThunk = createAsyncThunk<{ports: PortType[], total_count: number, isOptionList: boolean}, {fetchParams: TransportCategoryFetchParams, source: any, isOptionList?: boolean}, AsyncThunkConfig>(
  'ports/getAllPortList',
  async (fetchParamsData, thunkAPI) => {
    try {
      const { status, data } = await portAPI.getAllPorts(fetchParamsData.fetchParams, fetchParamsData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue({...data, isOptionList: !!fetchParamsData?.isOptionList}, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetPortByIdThunk = createAsyncThunk<PortType, number, AsyncThunkConfig>(
  'ports/getPortById',
  async (portId, thunkAPI) => {
    try {
      const { status, data } = await portAPI.getPortById(portId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreatePortThunk = createAsyncThunk<PortType, any, AsyncThunkConfig> (
  'ports/createPort',
  async (portData, thunkAPI) => {
    try {
      const { status, data } = await portAPI.createPort(portData)
      if (status === 201 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Port has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditPortThunk = createAsyncThunk<PortType, {portData: any, portId: number}, AsyncThunkConfig> (
  'ports/editPort',
  async (portData, thunkAPI) => {
    try {
      const { status, data } = await portAPI.editPort(portData.portData, portData.portId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Port has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeletePortThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'ports/deletePort',
  async (portId, thunkAPI) => {
    try {
      const { status, data } = await portAPI.deletePort(portId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(portId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default portsSlice.reducer
 