import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { railwayStationAPI } from '../app/api'
import { TransportCategoryFetchParams } from '../types/appTypes'
import { RailwayStationType } from '../types/railwayStationTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

interface InitialStateType {
  railway_stations: RailwayStationType[]
  railway_stations_total_count: number
  current_railway_station: RailwayStationType
}

const initialState: InitialStateType = {
  railway_stations: [],
  railway_stations_total_count: 0,
  current_railway_station: {} as RailwayStationType
}

export const railwayStationsSlice = createSlice({
  name: 'railwayStations',
  initialState,
  reducers: {
    setRailwayStations: (state, action: PayloadAction<RailwayStationType[]>) => {state.railway_stations = action.payload},
    setCurrentRailwayStations: (state, action: PayloadAction<RailwayStationType>) => {state.current_railway_station = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllRailwayStationsThunk.fulfilled, (state, action) => {
        state.railway_stations = action.payload.railway_stations
        state.railway_stations_total_count = action.payload.total_count
      })
      .addCase(GetRailwayStationByIdThunk.fulfilled, (state, action) => {
        state.current_railway_station = action.payload
      })
      .addCase(CreateRailwayStationThunk.fulfilled, (state, action) => {
        state.railway_stations = [...state.railway_stations, action.payload]
      })
      .addCase(EditRailwayStationThunk.fulfilled, (state, action) => {
        state.railway_stations = state.railway_stations.map(railwayStation => {
          return railwayStation.id === action.payload.id ? action.payload : railwayStation
        })
      })
      .addCase(DeleteRailwayStationThunk.fulfilled, (state, action) => {
        state.railway_stations = state.railway_stations.filter(railwayStation => railwayStation.id !== action.payload)
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.railway_stations = []
        state.current_railway_station = {} as RailwayStationType
      })
  }
})

export const { setRailwayStations, setCurrentRailwayStations } = railwayStationsSlice.actions

export const selectRailwayStationList = (state: RootState): RailwayStationType[] => state.railwayStations.railway_stations
export const selectRailwayStationTotalCount = (state: RootState): number => state.railwayStations.railway_stations_total_count
export const selectCurrentRailwayStation = (state: RootState): RailwayStationType => state.railwayStations.current_railway_station

export const GetAllRailwayStationsThunk = createAsyncThunk<{railway_stations: RailwayStationType[], total_count: number}, {fetchParams: TransportCategoryFetchParams, source: any}, AsyncThunkConfig>(
  'railwayStations/getAllRailwayStations',
  async (fetchParamsData, thunkAPI) => {
    try {
      const { status, data } = await railwayStationAPI.getAllRailwayStations(fetchParamsData.fetchParams, fetchParamsData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetRailwayStationByIdThunk = createAsyncThunk<RailwayStationType, number, AsyncThunkConfig>(
  'railwayStations/getRailwayStationById',
  async (railwayStationId, thunkAPI) => {
    try {
      const { status, data } = await railwayStationAPI.getRailwayStationById(railwayStationId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateRailwayStationThunk = createAsyncThunk<RailwayStationType, any, AsyncThunkConfig> (
  'railwayStations/createRailwayStation',
  async (railwayStationData, thunkAPI) => {
    try {
      const { status, data } = await railwayStationAPI.createRailwayStation(railwayStationData)
      if (status === 201 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Railway Station has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditRailwayStationThunk = createAsyncThunk<RailwayStationType, {railwayStationData: any, railwayStationId: number}, AsyncThunkConfig> (
  'railwayStations/editRailwayStation',
  async (railwayStationData, thunkAPI) => {
    try {
      const { status, data } = await railwayStationAPI.editRailwayStation(railwayStationData.railwayStationData, railwayStationData.railwayStationId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Railway Station has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteRailwayStationThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'railwayStations/deleteRailwayStation',
  async (railwayStationId, thunkAPI) => {
    try {
      const { status, data } = await railwayStationAPI.deleteRailwayStation(railwayStationId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(railwayStationId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default railwayStationsSlice.reducer
 