import { Button, Input } from 'antd'
import { useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { debounce } from 'lodash'
import classes from './FAQ.module.css'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import axios from './../../helpers/axiosHelper'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DeleteFaqEntryThunk, GetAllFaqEntriesThunk, selectFaqEntries, selectFaqEntriesFetchParams, setFaqEntriesFetchParams } from '../../store/faqReducer'
import { FaqEntriesFetchParams, FaqEntryType } from '../../types/faqTypes'

const FAQ = () => {
  const dispatch = useAppDispatch()
  const faqEntries = useAppSelector(selectFaqEntries)
  const fetchParams = useAppSelector(selectFaqEntriesFetchParams)

  const [tableData, setTableData] = useState<FaqEntryType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()

    setIsDataLoading(true)
    dispatch(GetAllFaqEntriesThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
  //   // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(faqEntries || [])
  }, [faqEntries])

  const deleteItem = (entry: FaqEntryType): void => {
    dispatch(DeleteFaqEntryThunk(entry.id as number))
  }

  const setFetchParams = (params: FaqEntriesFetchParams) => {
    dispatch(setFaqEntriesFetchParams(params))
  }
  
  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, search_value: encodeURI(searchRequest)})
    }, 350),
    [fetchParams]
  )

  const getEditItemLink = (id: number) => `/faq/edit/${id}`

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          FAQ
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.search_value as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
          <Link to='/faq/create'>
            <Button type='primary'>+ Add New</Button>
          </Link>
        </div>
      </div>

      <ItemList
        items={tableData}
        getEditItemLink={getEditItemLink}
        deleteItem={deleteItem}
        loading={isDataLoading}
        total={tableData?.length}
        columnList={['actions']}
        rowIdKey='id'
        getAdditionalColumns={getAdditionalTableColumns}
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      sorter: false,
      sortOrder: getColumnSortOrder('title'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('title', column.sortOrder)
      }),
      ellipsis: true,
    },
    {
      title: 'Date',
      dataIndex: 'created_date',
      key: 'created_date',
      sorter: false,
      sortOrder: getColumnSortOrder('created_date'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('created_date', column.sortOrder)
      }),
      ellipsis: true,
      render: (created_date: string) => <span>{moment(created_date).format('DD MMM YYYY')}</span>,
    },
  ])
}

export default FAQ
