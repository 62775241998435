import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CancelTokenSource } from 'axios'
import { serviceAPI } from '../app/api'
import { NodeType, ServiceCategorySearchTypeType, ServiceCategoryType, ServiceFetchParamsType, ServiceType } from '../types/serviceTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

interface InitialStateType {
  services: ServiceType[]
  services_total_count: number
  currentService: ServiceType

  serviceCategories: ServiceCategoryType[]
  serviceCategorySearchTypes: ServiceCategorySearchTypeType[]
  nodeList: NodeType[]
}

const initialState: InitialStateType = {
  services: [],
  services_total_count: 0,
  currentService: {} as ServiceType,

  serviceCategories: [],
  serviceCategorySearchTypes: [],
  nodeList: [],
}

export const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
    setServices: (state, action: PayloadAction<ServiceType[]>) => {state.services = action.payload},
    setCurrentService: (state, action: PayloadAction<ServiceType>) => {state.currentService = action.payload},
    setNodeList: (state, action: PayloadAction<NodeType[]>) => {state.nodeList = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllServicesThunk.fulfilled, (state, action) => {
        state.services = action.payload.services
        state.services_total_count = action.payload.total_count
      })
      .addCase(GetServiceByIdThunk.fulfilled, (state, action) => {
        state.currentService = action.payload
      })
      .addCase(CreateServiceThunk.fulfilled, (state, action) => {
        state.services = [...state.services, action.payload]
      })
      .addCase(EditServiceThunk.fulfilled, (state, action) => {
        state.services = state.services.map(service => {
          return service.service_id === action.payload.service_id ? action.payload : service
        })
      })
      .addCase(DeleteServiceThunk.fulfilled, (state, action) => {
        state.services = state.services.filter(service => service.service_id !== action.payload)
      })
      .addCase(GetAllServiceCategoriesThunk.fulfilled, (state, action) => {
        state.serviceCategories = action.payload
      })
      .addCase(CreateServiceCategoryThunk.fulfilled, (state, action) => {
        state.serviceCategories = [...state.serviceCategories, action.payload]
      })
      .addCase(EditServiceCategoryThunk.fulfilled, (state, action) => {
        state.serviceCategories = state.serviceCategories.map(s => s.service_category_id === action.payload.service_category_id ? action.payload : s)
      })
      .addCase(GetNodeListThunk.fulfilled, (state, action) => {
        state.nodeList = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.services = []
        state.currentService = {} as ServiceType
      })
      .addCase(GetServiceCategorySearchTypesThunk.fulfilled, (state, action) => {
        state.serviceCategorySearchTypes = action.payload
      })
      .addCase(GetNodesByCodeThunk.fulfilled, (state, action) => {
        state.nodeList = action.payload
      }) 
  }
})

export const { setServices, setCurrentService, setNodeList } = servicesSlice.actions

export const selectServiceList = (state: RootState): ServiceType[] => state.services.services
export const selectServicesTotalCount = (state: RootState): number => state.services.services_total_count
export const selectCurrentService = (state: RootState): ServiceType => state.services.currentService
export const selectServiceCategories = (state: RootState): ServiceCategoryType[] => state.services.serviceCategories
export const selectServiceCategorySearchTypes = (state: RootState): ServiceCategorySearchTypeType[] => state.services.serviceCategorySearchTypes
export const selectNodeList = (state: RootState): NodeType[] => state.services.nodeList

export const GetAllServicesThunk = createAsyncThunk<{services: ServiceType[], total_count: number}, {fetchParams: ServiceFetchParamsType, source: CancelTokenSource}, AsyncThunkConfig>(
  'services/getAllServicesList',
  async (fetchParamsData, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.getAllServices(fetchParamsData.fetchParams, fetchParamsData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetServiceByIdThunk = createAsyncThunk<ServiceType, number, AsyncThunkConfig>(
  'services/getServiceById',
  async (serviceId, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.getServiceById(serviceId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateServiceThunk = createAsyncThunk<ServiceType, any, AsyncThunkConfig> (
  'services/createService',
  async (service, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.createService(service.serviceData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Service has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditServiceThunk = createAsyncThunk<ServiceType, {serviceData: any, serviceId: number}, AsyncThunkConfig> (
  'services/editService',
  async (service, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.editService(service.serviceData, service.serviceId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Service has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteServiceThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'services/deleteService',
  async (serviceId, thunkAPI) => {
    try {
      const { status } = await serviceAPI.deleteService(serviceId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(serviceId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue('')
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetAllServiceCategoriesThunk = createAsyncThunk<ServiceCategoryType[], void, AsyncThunkConfig>(
  'services/getAllServiceTypes',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.getAllServiceCategories()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.service_categories, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const CreateServiceCategoryThunk = createAsyncThunk<ServiceCategoryType, any, AsyncThunkConfig>(
  'services/createServiceCategory',
  async (category, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.createServiceCategory(category)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Category has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const EditServiceCategoryThunk = createAsyncThunk<ServiceCategoryType, {formData: any, service_category_id: number}, AsyncThunkConfig>(
  'services/editServiceCategory',
  async (category, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.editServiceCategory(category.formData, category.service_category_id)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Category has been changed'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetNodeListThunk = createAsyncThunk<NodeType[], {searchQuery: string, source: CancelTokenSource}, AsyncThunkConfig>(
  'services/getNodeList',
  async (search, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.getNodesList(search.searchQuery, search.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.nodes, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetServiceCategorySearchTypesThunk = createAsyncThunk<ServiceCategorySearchTypeType[], void, AsyncThunkConfig>(
  'services/getServiceCategorySearchTypes',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.getServiceCategorySearchTypes()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.category_search_types, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetNodesByCodeThunk = createAsyncThunk<NodeType[], {code: string, source: CancelTokenSource}, AsyncThunkConfig>(
  'services/getNodesByCode',
  async ({code, source}, thunkAPI) => {
    try {
      const { status, data } = await serviceAPI.getNodesByCode(code, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.nodes, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default servicesSlice.reducer
 