import { Button, Input, Popconfirm, Spin, Tooltip } from 'antd'
import { debounce } from 'lodash'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import classes from './GTFSList.module.css'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import {ReactComponent as UploadIcon} from './../../img/icons/upload.svg'
import {ReactComponent as DeleteIcon} from './../../img/icons/delete.svg'
import {ReactComponent as ErrorIcon} from './../../img/icons/exclamation-circle.svg'
import { GTFSType } from '../../types/gtfsTypes'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { DeleteGTFSFileThunk, GetGTFSListThunk, selectGtfsList, selectGtfsListFetchParams, selectGtfsListTotalCount, setGtfsListFetchParams } from '../../store/gtfsReducer'
import axios from './../../helpers/axiosHelper'
import UploadModal from './UploadModal/UploadModal'
import { Link } from 'react-router-dom'

const GTFSList = () => {
  const dispatch = useAppDispatch()
  const gtfsList = useAppSelector(selectGtfsList)
  const gtfsListTotalCount = useAppSelector(selectGtfsListTotalCount)
  const fetchParams = useAppSelector(selectGtfsListFetchParams)

  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetGTFSListThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      dispatch(setGtfsListFetchParams({
        ...fetchParams,
        filter: {...fetchParams.filter, search_value: encodeURI(searchRequest)},
        pagination: {...fetchParams.pagination, page: 1}
      }))
    }, 350),
    [fetchParams]
  )

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          GTFS
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.filter.search_value as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
          <Button
            type='primary'
            onClick={() => setIsUploadModalOpen(true)}
          >
            <UploadIcon /> Upload file
          </Button>
        </div>
      </div>

      <ItemList
        items={gtfsList || []}
        loading={isDataLoading}
        pagination={fetchParams.pagination}
        setPagination={(pagination: any) => dispatch(setGtfsListFetchParams({...fetchParams, pagination}))}
        total={gtfsListTotalCount}
        columnList={[]}
        rowIdKey='file_id'
        getAdditionalColumns={getAdditionalTableColumns}
      />
      <UploadModal
        isModalOpen={isUploadModalOpen}
        onClose={() => setIsUploadModalOpen(false)}
      />
    </div>
  )
}

const DeleteWithLoading: React.FC<{item: GTFSType}> = ({item}) => {
  const dispatch = useAppDispatch()
  const gtfsList = useAppSelector(selectGtfsList)
  const fetchParams = useAppSelector(selectGtfsListFetchParams)

  const [isDeleting, setIsDeleting] = useState(false)

  const deleteItem = (GTFSItem: GTFSType): void => {
    setIsDeleting(true)
    dispatch(DeleteGTFSFileThunk(GTFSItem.file_id))
      .then(() => {
        setIsDeleting(false)
        dispatch(GetGTFSListThunk({
          fetchParams: {
            ...fetchParams,
            pagination: {
              ...fetchParams.pagination,
              page: gtfsList?.length === 1 ? fetchParams.pagination.page - 1 : fetchParams.pagination.page
            }
          }
        }))
      })
  }
  if (isDeleting) {
    return (
      <Tooltip title='It may take a few minutes'>
        <Spin />
      </Tooltip>
    )
  } else {
    return (
      <Popconfirm
        title='Are you sure to delete this item?'
        onConfirm={() => deleteItem(item)}
        okText='Yes'
        cancelText='No'
        placement='left'
        >
        <DeleteIcon style={{cursor: 'pointer'}}/>
      </Popconfirm>
    )
  }
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'File name',
      dataIndex: 'archive_name',
      key: 'archive_name',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('archive_name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('archive_name', column.sortOrder)
      }),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('country_name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('country_name', column.sortOrder)
      }),
      render: (_:any, item: GTFSType) => (<>{item?.country?.name}</>)
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('city_name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('city_name', column.sortOrder)
      }),
      render: (_:any, item: GTFSType) => (<>{item?.city?.name}</>)
    },
    {
      title: 'Date of upload',
      dataIndex: 'upload_end_date',
      key: 'upload_end_date',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('upload_end_date'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('upload_end_date', column.sortOrder)
      }),
      render: (_:any, item: GTFSType) => (<>{!!item?.upload_end_date && moment(item.upload_end_date).format('DD MMM YYYY')}</>)
    },
    {
      title: 'Log',
      dataIndex: 'logs',
      key: 'logs',
      ellipsis: true,
      render: (_:any, item: GTFSType) => (
        <div>
          {!!item.has_logs && 
            <Link to={`/gtfs/logs/${item.file_id}`} style={{display: 'inline-flex'}}>
              <ErrorIcon />
            </Link>
          }
        </div>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: true,
      render: (_:any, item: GTFSType) => (
        <div
          className={`
            ${classes.status}
            ${item.status.toLowerCase() ==='uploaded' ? classes.completed : ''}
            ${item.status.toLowerCase() === 'error loading' ? classes.error : ''}
            ${item.status.toLowerCase() === 'loading' ? classes.warn : ''}
          `}
        >
          {item.status}
        </div>
      )
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      ellipsis: true,
      render: (_:any, item: GTFSType) => (
        <div style={{display: 'flex', alignItems: 'center'}}>
          <DeleteWithLoading item={item} />
        </div>
      )
    },   
  ])
}

export default GTFSList
