import { Upload } from 'antd';
import { beforeUpload, dummyRequest, normFile } from '../../../helpers/files_helper';
import classes from './UploadFileField.module.css'
import {ReactComponent as UploadIcon} from './../../../img/airportDetailsForm/uploadIcon.svg'
import {ReactComponent as CloseIcon} from './../../../img/icons/close.svg'

const UploadFileField:React.FC<UploadFileFieldPropTypes> = ({addBannerImage, removeBannerImage, preview, disabled}) => {
  return (
    <div>
      <Upload.Dragger
        name='bannerUpload'
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        className={classes.uploadDragger}
        accept={'image/png, image/jpeg'}
        maxCount={1}
        onChange={e => normFile(e, addBannerImage)}
        showUploadList={false}
        disabled={disabled}
      >
        {preview?.url?.length ? (
          <div className={classes.imagePreviewWrapper}>
            <img src={preview.url} alt='preview' />
            {removeBannerImage &&
              <div className={classes.removePhotoBtn}>
                <CloseIcon onClick={(e) => {e.stopPropagation(); removeBannerImage()}}/>
              </div>
            }
          </div>
        ) : (
          <>
            <UploadIcon />
            <div>
              Click or drag banner to this area to upload
            </div>
          </>
        )}
      </Upload.Dragger>
    </div>
  )
}

interface ImagePreviewType {
  uid: string
  url: string
}

interface UploadFileFieldPropTypes {
  addBannerImage: (file: any) => Promise<void>
  removeBannerImage?: () => void
  preview: ImagePreviewType | null
  disabled?: boolean
}

export default UploadFileField
