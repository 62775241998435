import { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import classes from './NavigationPanel.module.css'
import {ReactComponent as BlogIcon} from './../../img/navigationPanel/blogIcon.svg'
import {ReactComponent as GTFCIcon} from './../../img/navigationPanel/gtfsIcon.svg'
import {ReactComponent as FaqIcon} from './../../img/navigationPanel/faqIcon.svg'
import {ReactComponent as AboutPageIcon} from './../../img/navigationPanel/aboutPageIcon.svg'
import {ReactComponent as EventsIcon} from './../../img/navigationPanel/eventsIcon.svg'
import {ReactComponent as WidgetsIcon} from './../../img/navigationPanel/widgetsIcon.svg'
import {ReactComponent as ServicesIcon} from './../../img/navigationPanel/servicesIcon.svg'
import {ReactComponent as AdvertisingIcon} from './../../img/navigationPanel/advertisingIcon.svg'
import {ReactComponent as TerminalIcon} from './../../img/navigationPanel/terminalIcon.svg'
import {ReactComponent as CityCountryIcon} from './../../img/navigationPanel/cityCountryIcon.svg'
import {ReactComponent as AirportsIcon} from './../../img/navigationPanel/airportsIcon.svg'
import {ReactComponent as AirlinesIcon} from './../../img/navigationPanel/airlinesIcon.svg'
import {ReactComponent as PortsIcon} from './../../img/navigationPanel/portsIcon.svg'
import {ReactComponent as TaxiIcon} from './../../img/navigationPanel/taxiIcon.svg'
import {ReactComponent as RentalsIcon} from './../../img/navigationPanel/rentalsIcon.svg'
import {ReactComponent as BusIcon} from './../../img/navigationPanel/busIcon.svg'
import {ReactComponent as TrainIcon} from './../../img/navigationPanel/trainIcon.svg'
import {ReactComponent as CruisesIcon} from './../../img/navigationPanel/cruisesIcon.svg'
import {ReactComponent as CruiseLinesIcon} from './../../img/navigationPanel/cruiseLinesIcon.svg'
import {ReactComponent as TrackingIcon} from './../../img/navigationPanel/trackingIcon.svg'
import {ReactComponent as SettingsIcon} from './../../img/navigationPanel/settingsIcon.svg'
import {ReactComponent as LogoutIcon} from './../../img/navigationPanel/logoutIcon.svg'
import avatarDefault from './../../img/avatarDefault.svg'
import logo from './../../img/logo.png'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { selectUserData, setUserAvatar, SignOutThunk } from '../../store/userReducer'

const NavigationPanel = () => {
  const location = useLocation()
  const dispatch = useAppDispatch()
  const adminData = useAppSelector(selectUserData)

  const menuItems = [
    {name: 'GTFS', link: '/gtfs', icon: GTFCIcon},
    {name: 'Blog', link: '/blog', icon: BlogIcon},
    {name: 'FAQ', link: '/faq', icon: FaqIcon},
    {name: 'About page', link: '/about-page', icon: AboutPageIcon},
    {name: 'Events', link: '/events', icon: EventsIcon},
    {name: 'Widgets', link: '/widgets', icon: WidgetsIcon},
    {name: 'Services', link: '/services', icon: ServicesIcon},
    {name: 'Advertising', link: '/advertising', icon: AdvertisingIcon},
    {name: 'Terminals', link: '/terminals', icon: TerminalIcon},
    {name: 'City/Country', link: '/city-country', icon: CityCountryIcon},
    {name: 'Airports', link: '/airports', icon: AirportsIcon},
    {name: 'Airlines', link: '/airlines', icon: AirlinesIcon},
    {name: 'Ports', link: '/ports', icon: PortsIcon},
    {name: 'Taxi, shuttles and transport', link: '/taxi', icon: TaxiIcon},
    {name: 'Rentals', link: '/rentals', icon: RentalsIcon},
    {name: 'Bus stations', link: '/bus-stations', icon: BusIcon},
    {name: 'Railway stations', link: '/railway-stations', icon: TrainIcon},
    {name: 'Cruise lines', link: '/cruise-lines', icon: CruiseLinesIcon},
    {name: 'Cruises', link: '/cruises', icon: CruisesIcon},
    {name: 'Tracking', link: '/tracking', icon: TrackingIcon},
  ]

  const pagesWithColorfulStroke = [
    'Cruise lines' ,
    'GTFS',
    'Events',
    'Airlines',
  ]

  const handleLogout = () => {
    dispatch(SignOutThunk())
  }

  useEffect(() => {
    location.pathname === '/' && setSelectedItemLink('/gtfs')
  }, [location])

  useEffect(() => {
    const refreshAvatarUrlInState = (e: Event) => {
      // @ts-ignore
      const newAvatarUrl = e.detail
      if(adminData.photo_url !== newAvatarUrl) {
        dispatch(setUserAvatar(newAvatarUrl))
      }
    }
    window.addEventListener('storageAvatarRefresh', refreshAvatarUrlInState);
    return () => {
      window.removeEventListener('storageAvatarRefresh', refreshAvatarUrlInState);
    };
  }, [dispatch, adminData]);

  const [selectedItemLink, setSelectedItemLink] = useState(location.pathname)

  return (
    <div className={classes.wrapper}>
      <div className={classes.logo}>
        <img src={logo} alt='logo'/>
      </div>
      <div className={classes.menu}>
        <div>
          {menuItems.map(item => (
            <Link
              to={item.link}
              className={`
                ${classes.menuItem}
                ${selectedItemLink.includes(item.link) && classes.active}
                ${pagesWithColorfulStroke.includes(item.name) && classes.withStroke}
              `}
              key={item.name}
              onClick={() => setSelectedItemLink(item.link)}
            >
              <item.icon />
              <span className={classes.itemTitle}>
                {item.name}
              </span>
            </Link>
          ))}
        </div>
        <div>
          <Link
            to='/settings'
            className={`${classes.menuItem} ${selectedItemLink === '/settings' && classes.active}`}
            onClick={() => setSelectedItemLink('/settings')}
          >
            <SettingsIcon />
            <span className={classes.itemTitle}>
              Settings
            </span>
          </Link>
          <div className={classes.menuItem} onClick={handleLogout}>
            <LogoutIcon/>
            <span className={classes.itemTitle}>
              Logout
            </span>
          </div>
        </div>
      </div>
      <div className={classes.user}>
        <img src={adminData?.photo_url || avatarDefault} alt='avatar' className={classes.avatar}/>
        <div className={classes.userName}>
          {adminData.first_name} {adminData.last_name}
        </div>
      </div>
    </div>
  )
}

export default NavigationPanel
