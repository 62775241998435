import React, { useEffect } from 'react'
import { Input } from 'antd'
import PlacesAutocomplete from 'react-places-autocomplete'
import classes from './InputGoogleAPI.module.css'

interface GoogleDataInputPropsType {
  placeholder: string
  onChange?: (value: string) => void
  value?: string
  suggestionsPosition?: 'vertical' | 'horizontal'
  searchOptions?: any
  style?: object
  suggestionsStyle?: object
  disabled?: boolean
  clearAfterSelect?: boolean
}

export const InputGoogleAPI = (props: GoogleDataInputPropsType) => {
  const [address, setAddress] = React.useState('')

  const handleSelect = async (value: string) => {
    props.onChange && props.onChange(value)
    setAddress(props.clearAfterSelect ? '' : value)
  }

  const handleChange = (value: string) => {
    if (!value.length) {
      props.onChange && props.onChange(value)
    }
    setAddress(value)
  }

  useEffect(() => {
    setAddress(props.value || '')
  }, [props.value])

  return (
    <>
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        searchOptions={props?.searchOptions}
      >
        {({getInputProps, suggestions, getSuggestionItemProps, loading}) => (
          <div className={props.suggestionsPosition === 'vertical' ? classes.verticalSuggestions : ''} style={{marginBottom: '10px', ...props.style}}>
            <Input
              {...getInputProps({placeholder: props.placeholder})}
              disabled={props.disabled}
              style={props.style}
            />
            <div className={suggestions.length ? classes.suggestionsWrapper : ''} style={props.suggestionsStyle}>
              {suggestions.map(suggestion => {
                return (
                  <div {...getSuggestionItemProps(suggestion)} className={suggestion.active ? classes.active : ''}>
                    {suggestion.description}
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  )
}
