import { debounce, minBy, orderBy } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import classes from './CruiseList.module.css'
import axios from '../../helpers/axiosHelper'
import { GetAllCruiseLinesThunk, selectCruiseLines, setCruiseLines } from '../../store/cruiseLinesReducer'
import { CruiseFetchParamsType, CruiseRouteType, CruiseType } from '../../types/cruiseType'
import { Button, Input, InputNumber, Rate, Select } from 'antd'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import { selectCruiseFetchParams, setCruiseFetchParams } from '../../store/appStatusReducer'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'
import { GetCruiseListThunk, selectCruiseList, selectCruiseTotalCount, setCruises } from '../../store/cruisesReducer'
import { ReactComponent as ArrowDown } from '../../img/icons/arrow-down.svg'

const CruiseList = () => {
  const dispatch = useAppDispatch()
  const fetchParams = useAppSelector(selectCruiseFetchParams)
  const cruises = useAppSelector(selectCruiseList)
  const cruiseLines = useAppSelector(selectCruiseLines)
  const cruisesTotalCount = useAppSelector(selectCruiseTotalCount)

  const [tableData, setTableData] = useState<CruiseType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [cruiseLineName, setCruiseLineName] = useState('')
  const [isDistanceFieldOpen, setIsDistanceFieldOpen] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetCruiseListThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(cruises)
  }, [cruises])

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    dispatch(GetAllCruiseLinesThunk({fetchParams: {}, source}))
  }, [dispatch])

  useEffect(() => {
    return () => {
      dispatch(setCruises([]))
      dispatch(setCruiseLines([]))
    }
    // eslint-disable-next-line
  }, [])

  // const deleteItem = (cruise: CruiseType): void => {
  //   dispatch(DeleteCruiseThunk(cruise.id))
  // }

  const setFetchParams = (params: CruiseFetchParamsType) => {
    dispatch(setCruiseFetchParams(params))
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({
        ...fetchParams,
        pagination: {...fetchParams.pagination, page: 1},
        name: encodeURI(searchRequest)
      })
    }, 350),
    [fetchParams]
  )

  const editItemLink = '/cruises/edit/'
  
  return (
    <div onClick={() => setIsDistanceFieldOpen(false)}>
      <div className={classes.pageHeader}>
        <h1>
          Cruises
        </h1>
      </div>
      <div className={classes.tools}>
        <DistanceField
          setIsDistanceFieldOpen={setIsDistanceFieldOpen}
          isDistanceFieldOpen={isDistanceFieldOpen}
        />
        <div>
          <Select
            placeholder='Cruise line'
            showSearch
            onSelect={(_: any, option: {value: number, children: string}) => setFetchParams({...fetchParams, cruise_line: option.value, pagination: {...fetchParams.pagination, page: 1}})}
            onSearch={(val) => setCruiseLineName(val)}
            searchValue={cruiseLineName}
            style={{width: '250px', marginRight: '15px'}}
            filterOption={false}
            value={fetchParams.cruise_line}
            allowClear
            onClear={() => setFetchParams({...fetchParams, cruise_line: undefined, pagination: {...fetchParams.pagination, page: 1}})}
          >
            {orderBy(cruiseLines.filter(l => l.name.toLowerCase().includes(cruiseLineName.toLowerCase())), [line => line.name?.toLowerCase()])
              .map(line => (
                <Select.Option value={line.id} key={line.id}>
                  {line.name}
                </Select.Option>
              ))
            }
          </Select>
        </div>
        <Input.Group compact style={{display:'flex', width: '480px'}}>
          <Select
            style={{ width: '240px' }}
            value={fetchParams.search_param}
            onChange={(val) => setFetchParams({...fetchParams, search_param: val as 'NAME' | 'CODE'})}
          >
            <Select.Option value={'NAME'}>By name</Select.Option>
            <Select.Option value={'CODE'}>By code</Select.Option>
            <Select.Option value={null}>By name & code</Select.Option>
            <Select.Option value={'LOCODE'}>By port code</Select.Option>
          </Select>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.name as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
        </Input.Group>
      </div>
      <ItemList
        items={tableData}
        getEditItemLink={(id: number) => editItemLink + id + (fetchParams?.min_port_distance ? '&min_port_distance=' + fetchParams.min_port_distance : '')}
        // editItemLink={editItemLink}
        // deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={cruisesTotalCount}
        columnList={['name', 'actions']}
        rowIdKey='id'
        getAdditionalColumns={getAdditionalTableColumns}
      />
    </div>
  )
}

const DistanceField = ({setIsDistanceFieldOpen, isDistanceFieldOpen}: any) => {
  const dispatch = useAppDispatch()
  const fetchParams = useAppSelector(selectCruiseFetchParams)

  const [distanceFieldValue, setDistanceFieldValue] = useState<number | null>(null)

  useEffect(() => {
    if (fetchParams.min_port_distance && !distanceFieldValue) {
      setDistanceFieldValue(fetchParams.min_port_distance)
    }
    // eslint-disable-next-line
  }, [fetchParams])

  return (
    <div
      className={`${classes.distanceFieldWrapper} ${isDistanceFieldOpen ? classes.active : ''}`}
      onClick={(e) => {
        e.stopPropagation()
        setIsDistanceFieldOpen(!isDistanceFieldOpen)
      }}
    >
      {fetchParams?.min_port_distance ?
        <span style={{color: 'black'}}>
          {fetchParams?.min_port_distance} km
        </span>
        : 'Set the distance'
      }
      <ArrowDown/>
      {isDistanceFieldOpen &&
        <div className={classes.distanceField} onClick={(e) => e.stopPropagation()}>
          <div className={classes.distanceFieldTitle}>
            Set the distance on an itinerary 
          </div>
          <InputNumber
            placeholder='Enter distance'
            style={{width: '100%'}}
            min={0}
            controls={false}
            onChange={value => setDistanceFieldValue(value ? +value : null)}
            value={distanceFieldValue || undefined}
          />
          <div className={classes.distanceFieldButtons}>
            <Button onClick={() => setIsDistanceFieldOpen(false)}>
              Cancel
            </Button>
            <Button
              type='primary'
              onClick={() => {
                dispatch(setCruiseFetchParams({
                  ...fetchParams,
                  pagination: {...fetchParams.pagination, page: 1},
                  min_port_distance: distanceFieldValue
                }))
                setIsDistanceFieldOpen(false)
              }}
            >
              Search
            </Button>
          </div>
        </div>
      }
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = () => {
  return ([
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      ellipsis: true,
      width: "100px"
    },
    {
      title: 'Start location',
      dataIndex: 'start',
      key: 'start',
      ellipsis: true,
      render: (_:any, item: any) => (
        <div>{minBy((item?.cruise_routes as CruiseRouteType[]), (r => r.sequence))?.name}</div>
      ),
    },
    {
      title: 'Days',
      dataIndex: 'days',
      key: 'days',
      ellipsis: true,
      width: "100px"
    },
    {
      title: 'Ship',
      dataIndex: 'ship',
      key: 'ship',
      ellipsis: true,
      render: (_:any, item: any) => (
        <div>{item?.ship?.name}</div>
      ),
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating',
      ellipsis: true,
      render: (_:any, item: any) => (
        <Rate value={Math.round(item.rating*2)/2} allowHalf disabled/>
      ),
    },
  ])
}

export default CruiseList
