import { Input, message, Popover, Upload } from 'antd'
import classes from './UploadImage.module.css'
import { FaqImageType } from '../../../../types/faqTypes'
import { ReactComponent as CloseIcon } from './../../../../img/icons/close.svg'
import { dummyRequest, normFile } from '../../../../helpers/files_helper'
import {ReactComponent as UploadIcon} from './../../../../img/airportDetailsForm/uploadIcon.svg'
// import {ReactComponent as PictureIcon} from './../../../../img/icons/picture.svg'
import {ReactComponent as QuestionMarkIcon} from './../../../../img/icons/questionMark.svg'

const UploadImage: React.FC<UploadImagePropTypes> = ({
  removePhoto,
  addPhoto,
  images,
  disabled,
  updateImagesData
}) => {
  const defaultMaxCount = 1

  const validImages = images.filter(img => img.action !== 'DELETE')

  const beforeUpload = (file: any) => {
    const isLt1M = file.size / 1024 / 1024 < 1
    if (!isLt1M) message.error('File must be less than 1 mb')
    return isLt1M ? true : Upload.LIST_IGNORE
  }

  return (
    <div className={classes.imagesAreaWrapper}>
      {validImages?.length > 0 && validImages.map((image, index) => (
        <div className={classes.imagePreviewWrapper} key={image?.preview?.url}>
          <div style={{position: 'relative'}}>
            <img src={image?.preview?.url} alt='preview' />
            <div className={classes.removePhotoBtn}>
              <CloseIcon onClick={() => removePhoto(image?.preview?.uid!, !!image?.file)}/>
            </div>
          </div>
          
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly'}}>
            <div style={{display: 'grid', gridTemplateColumns: '1fr 20px', alignItems: 'center', columnGap: '9px'}}>
              <Input
                placeholder='Alternative text'
                onChange={(e) => updateImagesData(images.map(i => i?.preview?.uid === image?.preview?.uid ? {...i, image_html: e.target.value, action: !!i?.id ? 'UPDATE' : undefined} : i))}
                value={validImages[index].image_html}
                style={{height: 'max-content'}}
              />
              <Popover
                content={'Short image description, that can be used by screen readers and Google crawlers'}
                placement='topRight'
              >
                <QuestionMarkIcon />
              </Popover>
            </div>
          </div>
        </div>
      ))}
      {validImages.length < defaultMaxCount &&
        <Upload.Dragger
          name='files'
          customRequest={dummyRequest}
          beforeUpload={beforeUpload}
          className={`${classes.uploadDragger}`}
          accept={'image/png, image/jpeg'}
          maxCount={defaultMaxCount}
          onChange={(e) => normFile(e, addPhoto)}
          showUploadList={false}
        >
          {/* {!!images?.length && images.length < defaultMaxCount && 
            <>
              <PictureIcon />
              <div>
                + Add more
              </div>
            </>
          } */}

          {!validImages?.length &&
            <>
              <UploadIcon />
              <div className={classes.uploadTitle}>
                Click or drag photo to this area to upload
              </div>
            </>
          }
        </Upload.Dragger>
      }
    </div>
  )
}

interface UploadImagePropTypes {
  addPhoto: (file: any) => Promise<void>
  removePhoto: (uid: string, isNew: boolean) => void
  images: FaqImageType[]
  disabled?: boolean
  updateImagesData: (images: FaqImageType[]) => void
}

export default UploadImage
