import { Button, Form, Select, Spin } from 'antd'
import { FormInstance, useForm } from 'antd/lib/form/Form'
import { pickBy, sortBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetAllCountriesThunk, CreateCityThunk, selectAllCountries, GetCityByIdThunk, selectCurrentCity, setCurrentCity, EditCityThunk } from '../../../store/locationsReducer'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import CombinedCoordinatesInput from '../../common/CombinedCoordinatesInput/CombinedCoordinatesInput'
import InputField from '../../common/formFields/InputField'
import classes from './CityDetailsForm.module.css'
import Map from '../../common/Map/Map'
import { CityType, NewCityDataTypes } from '../../../types/locationTypes'

const CityDetailsForm: React.FC<CityDetailsFormPropTypes> = ({isEditing, isModalView, onCloseModal, defaultCountryId}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [form] = useForm()
  const currentCity = useAppSelector(selectCurrentCity)
  const {id} = useParams()

  const [countryId, setCountryId] = useState<number | undefined>(defaultCountryId)
  const [coordinatesRequiredError, setCoordinatesRequiredError] = useState('')
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    !!id && isEditing && dispatch(GetCityByIdThunk(+id))

    return () => {dispatch(setCurrentCity({} as CityType))}
  }, [dispatch, id, isEditing])

  useEffect(() => {
    if (Object.keys(currentCity)?.length) {
      form.setFieldsValue(currentCity)
      !!currentCity?.country_id && setCountryId(currentCity?.country_id)
    }
  }, [currentCity, form])
  
  const onFinishFailed = (formData: any) => {
    const fullFormValues = form.getFieldsValue(true)
    if (!fullFormValues?.latitude || !fullFormValues?.longitude) {
      setCoordinatesRequiredError('Make sure coordinates data is fulfilled and right!')
    }
  }

  const handleSave = () => {
    const formData = pickBy(form.getFieldsValue(true), (_, key) => key !== 'country_id' && key !== 'country_name') as NewCityDataTypes

    if (!formData.latitude || !formData.longitude) {
      setCoordinatesRequiredError('Make sure coordinates data is fulfilled and correct!')
      return 
    }

    if (!formData?.alias?.length) formData.alias = formData.name

    const saveRequest = async() => {
      return isEditing
       ? dispatch(EditCityThunk({cityData: formData, cityId: +id!, ...(!!countryId ? {countryId: countryId} : {})}))
       : dispatch(CreateCityThunk({cityData: formData, ...(!!countryId ? {countryId: countryId} : {})}))
    }

    setIsSaving(true)
    saveRequest().then(resp => {
      setIsSaving(false)
      if (!resp.type.includes('rejected')) {
        const responseCityData = resp?.payload as CityType
        !!onCloseModal 
          ? onCloseModal(responseCityData?.name)
          : navigate('/city-country')
      }
    }) 
  }

  if (!!isEditing && !Object.keys(currentCity)?.length) {
    return <Spin className={classes.spinner} />
  }

  return (
    <Form
      name='city'
      onFinish={handleSave}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      validateTrigger='onBlur'
      form={form}
    >
      {!isModalView && 
        <div>
          <Breadcrumbs />
          <h1>
            {isEditing ? currentCity?.name : 'Adding a new city'}
          </h1>
        </div>
      }
      
      <MainInfoArea
        isModalView={isModalView}
        countryId={countryId}
        setCountryId={(id: number) => setCountryId(id)}
        coordinatesRequiredError={coordinatesRequiredError}
        setCoordinatesRequiredError={setCoordinatesRequiredError}
        form={form}
        defaultCountryId={defaultCountryId}
      />

      <div className={classes.buttonsAreaWrapper}>
        {!!onCloseModal ? (
          <Button onClick={() => onCloseModal()}>
            Cancel
          </Button>
        ) : (
          <Link to='/city-country'>
            <Button>
              Cancel
            </Button>
          </Link>
        )}
        <Button
          type='primary'
          htmlType='submit'
          loading={isSaving}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

const MainInfoArea: React.FC<MainInfoAreaPropTypes> = ({
  isModalView,
  setCountryId,
  countryId,
  coordinatesRequiredError,
  setCoordinatesRequiredError,
  form,
  defaultCountryId
}) => {
  const dispatch = useAppDispatch()
  const countries = useAppSelector(selectAllCountries)

  const [coordinates, setCoordinates] = useState('')
  const [mapCenter, setMapCenter] = useState<[number, number] | []>([])

  const latitude = form.getFieldValue('latitude')
  const longitude = form.getFieldValue('longitude')

  useEffect(() => {
    if (!!latitude && !!longitude && !coordinates.length) {
      setCoordinates(`${latitude}, ${longitude}`)
      setMapCenter([+latitude, +longitude])
    }
  }, [coordinates, form, latitude ,longitude])

  useEffect(() => {
    dispatch(GetAllCountriesThunk())
  }, [dispatch])

  const handleMapMarkerMove = (coordinates: {lat: number, lng: number}) => {
    form.setFieldsValue({latitude: coordinates.lat, longitude: coordinates.lng})
    setCoordinates(`${coordinates.lat}, ${coordinates.lng}`)
    setCoordinatesRequiredError('')
  }

  const handleCoordinatesChange = (value: string, isValueCompleted: boolean, isValueEmpty: boolean) => {
    setCoordinatesRequiredError('')
    if (isValueCompleted) {
      const [latitude, longitude] = value.split(', ')
      setCoordinates(`${latitude}, ${longitude}`)
      setMapCenter([+latitude, +longitude])
      form.setFieldsValue({latitude: +latitude, longitude: +longitude})
    } else if (isValueEmpty) {
      setCoordinates('')
      setMapCenter([0, 0])
      form.setFieldsValue({latitude: undefined, longitude: undefined})
    }
  }

  return (
    <div className={`${classes.mainInfoArea} ${!!isModalView && classes.modalView}`}>
      <div className={classes.formBlock}>
        <div>
          <div className={classes.label}>
            Country
          </div>
          <Select
            placeholder='Select country'
            showSearch
            filterOption={(input, option) => {
              const countryName = option!.children as unknown as string
              return countryName?.toLowerCase().includes(input.toLowerCase())
            }}
            onChange={(val) => setCountryId(val)}
            style={{width: '100%', marginBottom: '20px'}}
            value={countryId}
            disabled={!!defaultCountryId}
          >
            {sortBy(countries, country => country.name)?.map(country => (
              <Select.Option value={country.id} key={country.code}>{country.name}</Select.Option>
            ))}
          </Select>
        </div>
        <div className={classes.doubleInputArea}>
          <InputField
            name='name'
            requiredMessage='Please enter city name!'
            placeholder='Enter City Name'
            label='City Name'
          />
          <InputField
            name='code'
            placeholder='Enter City Code'
            label='City Code'
            rules={[
              (form) => {
                const value = form.getFieldValue('code') as string
                return value === 'N' ? {} : {min: 3, message: 'Code must include 3 letters'}
              }
            ]}
            maxLength={3}
          />
        </div>
        <CombinedCoordinatesInput
          onChange={handleCoordinatesChange}
          errorMessage={coordinatesRequiredError}
          initialValue={coordinates}
        />
        <div className={classes.doubleInputArea}>
          <InputField
            name='state_name'
            placeholder='Enter State Name'
            label='State Name'
          />
          <InputField
            name='state_code'
            placeholder='Enter State Code'
            label='State Code'
            rules={[
              (form) => {
                const value = form.getFieldValue('code') as string
                return value === 'N' ? {} : {min: 2, message: 'Code must include 2 letters'}
              }
            ]}
            maxLength={3}
          />
        </div>
      </div>
      <div className={classes.formBlock}>
        <InputField
          name='alias'
          placeholder='Enter Alias'
          label='Add an alias'
          type='text'
          rows={4}
        />
      </div>
      <div className={classes.mapWrapper}>
        <Map
          center={mapCenter.length ? mapCenter : [0, 0]}
          showMarker={!!mapCenter.length}
          className={classes.map}
          onMarkerMove={handleMapMarkerMove}
        />
      </div>
    </div>
  )
}

interface CityDetailsFormPropTypes {
  isEditing: boolean
  isModalView?: boolean
  onCloseModal?: (cityName?: string) => void
  defaultCountryId?: number
}

interface MainInfoAreaPropTypes {
  isModalView?: boolean
  setCountryId: (id: number) => void
  countryId: number | undefined
  coordinatesRequiredError: string
  setCoordinatesRequiredError: (error: string) => void
  form: FormInstance
  defaultCountryId?: number
}

export default CityDetailsForm
