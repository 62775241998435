import React, { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { Button, Divider, Form, FormInstance, Modal, Select, Spin } from 'antd'
import classes from './CityField.module.css'
import axios from './../../../../helpers/axiosHelper'
import CityDetailsForm from '../../../CityCountry/CityDetailsForm/CityDetailsForm'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { GetCitiesByCountryThunk, GetCountryThunk, selectAllCountries, selectCityList, setCities, setCurrentCity } from '../../../../store/locationsReducer'
import { geocodeByAddress } from 'react-places-autocomplete'
import { InputGoogleAPI } from '../../InputGoogleAPI/InputGoogleAPI'

const CityField: React.FC<CityNamePropTypes> = ({form, countryCodeValue, addCityId}) => {
  const dispatch = useAppDispatch()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const cities = useAppSelector(selectCityList)
  const countries = useAppSelector(selectAllCountries)

  const [searchQuery,  setSearchQuery] = useState('')
  const [inputValue,  setInputValue] = useState('')
  const [isLoading,  setIsLoading] = useState(false)
  const [cityOptions, setCityOptions] = useState<{label: string, value: number}[]>([])
  const [isModalOpen,  setIsModalOpen] = useState(false)

  useEffect(() => {
    const options = cities.map(city => ({label: `${city.name} ${!!city?.state_name?.length ? '(' + city?.state_name + ')' : ''}`, value: city.id}))
    setCityOptions(options)
  }, [cities, countryCodeValue])

  useEffect(() => {
    return () => {
      setInputValue('')
      dispatch(setCities([]))
      setSearchQuery('')
      form.setFieldsValue({city_name: null})
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const name = form.getFieldValue('city_name')
    if (!!name?.length && !inputValue.length) {
      setInputValue(name)
    }
  }, [inputValue, form])

  useEffect(() => {
    if (!!searchQuery.length) {
      setIsLoading(true)
      dispatch(GetCountryThunk({source, countryCode: countryCodeValue!, name: searchQuery}))
      dispatch(GetCitiesByCountryThunk({source, countryCode: countryCodeValue!, name: searchQuery}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    } else {
      dispatch(setCities([]))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, searchQuery])

  const handleCitySearch = (searchQuery: string) => {
    !!searchQuery.length && handleCitySearchDebounce(searchQuery.split('(')[0])
    setInputValue(searchQuery)
  }

  // eslint-disable-next-line
  const handleCitySearchDebounce = useCallback(
    debounce((searchQuery: string) => {
      setSearchQuery(searchQuery)
    }, 350), []
  )

  const handleCitySelect = (cityId: number) => {
    const cityData = cities.find(c => c.id === cityId)
    const countryData = countries.find(c => c.code === countryCodeValue)
    form.setFieldsValue({
      city_name: !!cityData?.state_name?.length ? `${cityData?.name!} (${cityData?.state_name})` : cityData?.name,
      country_name: countryData?.name,
      ...(!!cityData?.code?.length ? {city_code: cityData.code} : {}),
      ...(cityData?.state_code?.length ? {state_code: cityData.state_code} : {}),
      ...(cityData?.state_name?.length ? {state_name: cityData.state_name} : {}),
      ...(addCityId ? {city_id: cityId} : {})
    })
    if(cityData){
      dispatch(setCurrentCity(cityData))
    }
  }

  const handleModalClose = (cityName?: string) => {
    if (!!cityName?.length) {
      form.setFieldsValue({city_name: cityName})
    }
    setIsModalOpen(false)
  }

  return (
    <div>
      <div className={classes.label}>
        City
      </div>
      <Form.Item
        name='city_name'
        rules={[{ required: true, message: 'Please select city!' }]}
      >
        <Select
          placeholder={countryCodeValue ? 'Please select city' : 'Select country first'}
          showSearch
          onSelect={(_: any, option: {value: number, children: string}) => handleCitySelect(option.value)}
          onSearch={handleCitySearch}
          searchValue={inputValue}
          style={{width: '100%'}}
          filterOption={false}
          labelInValue
          disabled={!countryCodeValue}
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!searchQuery.length && !cityOptions.length && 'No results found'}
              {!searchQuery.length && !cityOptions.length && 'Start typing city name'}
            </>
          )}
          dropdownRender={(menu) => (
            <>
              {menu}
              {(!!cityOptions.length || (!!searchQuery.length && !cityOptions.length)) && 
                <>
                  <Divider style={{ margin: '8px 0' }} />
                  <Button type='text' style={{width: '100%'}} onClick={() => setIsModalOpen(true)}>
                    + Add new city
                  </Button>
                </>
              }
            </>
          )}
        >
          {cityOptions.map((option) => (
            <Select.Option value={option.value} key={option.value}>
              {option.label}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Modal
        title='Add new city'
        visible={isModalOpen}
        className={classes.modal}
        footer={false}
        closable={false}
        destroyOnClose
      >
        <CityDetailsForm
          isEditing={false}
          isModalView={true}
          onCloseModal={handleModalClose}
          defaultCountryId={countries.find(c => c.code === countryCodeValue)?.id}
        />
      </Modal>
    </div>
  )
}

export const GoogleCityField: React.FC<CityNamePropTypes> = ({form, label, style, isEditing, transportName}) => {
  const dispatch = useAppDispatch()
  const countryData = form.getFieldValue('country')

  const [inputValue, setInputValue] = useState('')
  const [nameValue, setNameValue] = useState('')

  useEffect(() => {
    return () => {
      setInputValue('')
      dispatch(setCities([]))
      form.setFieldsValue({city_name: null})
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!!isEditing) {
      const name = `${countryData?.cities[0]?.name}, ${countryData?.name}`
      if (!!countryData?.cities[0]?.name && !nameValue.length) {
        setNameValue(name)
      }
    }
    // eslint-disable-next-line
  }, [inputValue, form, isEditing, countryData])

  const getData = async(value:string) => {
    const addressData = await geocodeByAddress(value)
    form.setFieldsValue({place_id: addressData[0]?.place_id})
    if (transportName === 'port') {
      const country_code = addressData[0]?.address_components?.find(data => data.types.includes('country'))?.short_name || ''
      form.setFieldValue('country_code', country_code)
    }
  }

  return (
    <div style={style}>
      <div className={classes.label}>
        {label}
      </div>
      <InputGoogleAPI
        value={inputValue || nameValue}
        onChange={(value) => {
          setInputValue(value)
          getData(value)
        }}
        placeholder={'Start typing city name'}
        suggestionsPosition='vertical'
        searchOptions={{types: ['(cities)']}}
        style={{height: '48px', marginBottom: '20px'}}
      />
    </div>
  )
}

interface CityNamePropTypes {
  form: FormInstance
  countryCodeValue: string | undefined
  addCityId?: boolean
  label?: string
  style?: object
  isEditing?: boolean
  transportName?: string
}

export default React.memo(CityField)
