import { Button, Checkbox, Form, FormInstance, Spin, Tabs, Tooltip } from 'antd'
import { pickBy } from 'lodash'
import { ReactElement, useEffect, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './BusStationDetailsForm.module.css'
import { GetAllCountriesThunk, selectCityList, selectCountry, selectCurrentCity, setCities } from '../../../store/locationsReducer'
import redDot from './../../../img/icons/redDot.png'
import {ReactComponent as InfoIcon} from './../../../img/icons/info.svg'
import MainInformationTab from '../../common/transportItemForm/MainInformationTab/MainInformationTab'
import PhotoTab from '../../common/transportItemForm/PhotoTab/PhotoTab'
import { CreateBusStationThunk, EditBusStationThunk, GetBusStationByIdThunk, selectCurrentBusStation, setCurrentBusStations } from '../../../store/busStationsReducer'
import { BusStationType } from '../../../types/busStationTypes'

const BusStationDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentStation = useAppSelector(selectCurrentBusStation)
  const currentCity = useAppSelector(selectCurrentCity)
  const country = useAppSelector(selectCountry)
  const cities = useAppSelector(selectCityList)
  const [form] = Form.useForm()

  const [editingStationId, setEditingStationId] = useState(0)
  const [activeTabKey, setActiveTabKey] = useState('1')
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [isApproved, setIsApproved] = useState(false)

  const tabs: TabType[] = [
    {title: 'Main information', key: '1', content: <MainInformationTab isCodeOptional={true} form={form} transportName='bus station' /> },
    {title: 'Photo', key: '2', content: <PhotoTab form={form} isEditing={isEditing} />}
  ]

  useEffect(() => {
    dispatch(GetAllCountriesThunk())
  }, [dispatch])

  const handleChangeTab = (activeKey: string) => {
    setActiveTabKey(activeKey)
    setErrors([])
  }

  const checkCityNameInvalid = (formValueCityName: string) => {
    if (isEditing && currentStation?.country?.cities[0]?.name === formValueCityName) {
      return false
    } else if (!formValueCityName.length) {
      return true
    } else {
      const formValues = form.getFieldsValue(true)
      return !!cities.length && !cities.some(c => c.name === formValues?.city_name?.split(' (')[0])
    }
  }

  const onFinishFailed = (errors: any) => {
    const errorsData = errors?.errorFields?.map((error:any) => error.errors).flat(1) || []
    const formValues = form.getFieldsValue(true)
    !formValues?.country_code && errorsData.push('The address is invalid!')
    if (!formValues?.city_name || (!!cities.length && !cities.some(c => c.name === formValues?.city_name?.split(' (')[0]))) {
      errorsData.push('Wrong city name!')
    }
    setErrors(errorsData)
  }

  const sendData = async(formData: any) => {
    if (isEditing) {
      return dispatch(EditBusStationThunk({busStationData: formData, busStationId: editingStationId}))
    } else {
      return dispatch(CreateBusStationThunk(formData))
    }
  }

  const handleSave = async() => {
    let formValues = form.getFieldsValue(true)
    const emptyAddressFieldsLeft = !isEditing && (!formValues?.city_name || !formValues?.country_code)
    const wrongCityName = checkCityNameInvalid(formValues?.city_name)

    if (emptyAddressFieldsLeft || wrongCityName) {
      return onFinishFailed({})
    }

    const stationData = {
      ...pickBy(formValues, (_, key) => key !== 'photos' && key !== 'photo_urls' && key !== 'country'),
      phones: formValues?.phones?.filter((p: string) => p.length > 0) || [],
      city_name: formValues?.city_name?.split(' (')[0],
      country_id: country.id ? country.id : currentStation?.country?.id,
      city_id: currentCity.id ? currentCity.id : currentStation?.country?.cities[0]?.id
    }
    const formData = new FormData()
    formData.append('bus_station', new Blob([JSON.stringify(stationData, null, 2)], {type: 'application/json'}))
    !!formValues?.photos?.length && formValues.photos.forEach((p:any) => formData.append(isEditing ? 'add_photos' : 'photos', p.originFileObj))
    !!isEditing && !!formValues?.delete_photos?.length && formData.append('delete_photos', new Blob([JSON.stringify({urls: formValues?.delete_photos}, null, 2)], {type: 'application/json'}))

    setIsSaving(true)
    sendData(formData)
      .then((resp) => {
        setIsSaving(false)
        !resp?.type.includes('rejected') && navigate('/bus-stations')
      })
  }

  useEffect(() => {
    if (isEditing) {
      // pathname example: /bus-station/edit/47895
      const id = location.pathname.split('edit/')[1]
      setEditingStationId(+id)
      dispatch(GetBusStationByIdThunk(+id))
    }
    return () => {
      dispatch(setCurrentBusStations({} as BusStationType))
      dispatch(setCities([]))
    }
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && !!Object.keys(currentStation)?.length) {
      const stationFieldsData = {
        ...currentStation,
        country_code: currentStation?.country?.code,
        city_name: !!currentStation?.country?.cities[0]?.name
          ? currentStation?.country?.cities[0]?.name + `${currentStation?.country?.cities[0]?.state_name?.length ? ' ('+currentStation?.country?.cities[0]?.state_name+')' : ''}`
          : '',
      }
      form.setFieldsValue(stationFieldsData)
      setIsApproved(form.getFieldValue('is_approved') === null ? true : form.getFieldValue('is_approved'))
    }
  }, [currentStation, form, isEditing, dispatch])

  const handleApprovalChange = (isApproved:boolean) => {
    setIsApproved(isApproved)
    form.setFieldsValue({'is_approved': isApproved})
  }

  if (isEditing && !Object.keys(currentStation).length) {
    return <Spin className={classes.spinner} />
  }

  return (
    <Form
      name='bus'
      onFinish={handleSave}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentStation?.name : 'Adding a new bus station'}
          {currentStation?.is_approved === false &&
            <Tooltip title='Data needs to be approved'>
              <img src={redDot} alt='needs attention' className={classes.redDot}/>
            </Tooltip>
          }
        </h1>
        <Tabs activeKey={activeTabKey} onChange={(activeKey) => handleChangeTab(activeKey)}>
          {tabs.map((tab) => (
            <Tabs.TabPane tab={tab.title} key={tab.key}>
              {tab.content}
            </Tabs.TabPane>
          ))}
        </Tabs>
      </div>
      <div className={classes.navigationBtnWrapper}>
        {!!errors.length && activeTabKey === String(tabs.length) &&
          <div className={classes.errors}>
            {errors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
        }
        <div className={classes.btnsArea}>
          {!!isEditing && activeTabKey === String(tabs.length) ? (
            <div className={classes.approveData}>
              <div>
                <InfoIcon />
                Station data is approved
              </div>
              <Checkbox checked={isApproved} onChange={e => handleApprovalChange(e.target.checked)}/>
            </div>
          ) : (
              <div></div>
          )}
          <div className={classes.buttons}>
            {activeTabKey !==  '1' &&
              <div
                className={classes.backBtn}
                onClick={(e) => setActiveTabKey((+activeTabKey - 1).toString())}
              >
                Back
              </div>
            }
            <Link to='/bus-stations'>
              <Button className={classes.navigationBtn}>
                Cancel
              </Button>
            </Link>
            {activeTabKey !== String(tabs.length) ? (
              <Button
                type='primary'
                className={classes.navigationBtn}
                onClick={(e) => {
                  e.preventDefault();
                  setActiveTabKey((+activeTabKey + 1).toString())
                }}
              >
                Next
              </Button>
            ) : (
              <Button
                type='primary'
                htmlType='submit'
                className={classes.navigationBtn}
                loading={isSaving}
              >
                Save
              </Button>
            )}
          </div>
        </div>
      </div>
    </Form>
  )
}

interface TabType {
  title: string
  key: string
  content: ReactElement<any, any>
}

export interface FormTabProps {
  form: FormInstance
  isEditing: boolean
}

export default BusStationDetailsForm
