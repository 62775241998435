import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import moment from 'moment'
import { Input } from 'antd'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { GetWidgetListThunk, selectWidgetList, selectWidgetListFetchParams, selectWidgetListTotalCount, setWidgetListFetchParams } from '../../store/widgetsReducer'
import { WidgetType } from '../../types/widgetType'
import axios from './../../helpers/axiosHelper'
import classes from './Widgets.module.css'
import ItemList, { GetAdditionalColumnType } from '../common/ItemList/ItemList'
import { ReactComponent as SearchIcon } from './../../img/icons/search.svg'

const Widgets = () => {
  const dispatch = useAppDispatch()
  const widgetList = useAppSelector(selectWidgetList)
  const widgetListTotalCount = useAppSelector(selectWidgetListTotalCount)
  const fetchParams = useAppSelector(selectWidgetListFetchParams)

  const getViewItemLink = (widget: WidgetType) => {
    return '/widgets/view/' + widget.code
  }

  const [isDataLoading, setIsDataLoading] = useState(false)

  const getList = (source?:any) => {
    setIsDataLoading(true)
    dispatch(GetWidgetListThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })
  }

  useEffect(() => {
    const CancelToken = axios.CancelToken
    const source = CancelToken.source()
    getList(source)
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  // const deleteWidget = (id: number) => {
  //   dispatch(DeleteWidgetThunk(id))
  //     .then((r) => !r.type.includes('rejected') && getList())
  // }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      dispatch(setWidgetListFetchParams({
        ...fetchParams,
        filter: {...fetchParams.filter, search_value: encodeURI(searchRequest)},
        pagination: {...fetchParams.pagination!, page: 1}
      }))
    }, 350),
    [fetchParams]
  )

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Widgets
        </h1>
        <div className={classes.tools}>
          <Input
            placeholder='Search'
            suffix={<SearchIcon />}
            defaultValue={decodeURIComponent(fetchParams.filter.search_value as string)}
            onChange={(e) => handleSearchDebounce(e.target.value.trim())}
            style={{ width: '240px' }}
          />
        </div>
      </div>
      <ItemList
        items={widgetList || []}
        loading={isDataLoading}
        pagination={fetchParams.pagination!}
        setPagination={(pagination: any) => dispatch(setWidgetListFetchParams({...fetchParams, pagination}))}
        total={widgetListTotalCount}
        columnList={['actions']}
        rowIdKey='id'
        getViewItemLink={getViewItemLink}
        getAdditionalColumns={getAdditionalTableColumns}
        // deleteItem={(widget) => deleteWidget(widget.id)}
      />
    </div>
  )
}

const getAdditionalTableColumns: GetAdditionalColumnType = (getColumnSortOrder, setColumnSortOrder) => {
  return ([
    {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ellipsis: true,
      sorter: false,
      // sortOrder: getColumnSortOrder('location'),
      // onHeaderCell: (column: any) => ({
      //   onClick: () => setColumnSortOrder('location', column.sortOrder)
      // }),
      render: (_:any, item: WidgetType) => (<>{item?.location?.name}</>)
    },
    {
      title: 'Creator',
      dataIndex: 'creator_name',
      key: 'creator_name',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('creator_name'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('creator_name', column.sortOrder)
      }),
    },
    {
      title: 'Width',
      dataIndex: 'width',
      key: 'width',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('width'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('width', column.sortOrder)
      }),
      render: (_:any, item: WidgetType) => (<>{item?.width ? item?.width + 'px' : '100%'}</>)
    },
    {
      title: 'Height',
      dataIndex: 'height',
      key: 'height',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('height'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('height', column.sortOrder)
      }),
      render: (_:any, item: WidgetType) => (<>{item?.height ? item?.height + 'px' : '100%'}</>)
    },
    {
      title: 'Date of creation',
      dataIndex: 'created_date',
      key: 'created_date',
      ellipsis: true,
      sorter: true,
      sortOrder: getColumnSortOrder('created_date'),
      onHeaderCell: (column: any) => ({
        onClick: () => setColumnSortOrder('created_date', column.sortOrder)
      }),
      render: (_:any, item: WidgetType) => (<>{moment(item?.created_date).format('DD.MM.YYYY')}</>)
    },
  ])
}

export default Widgets
