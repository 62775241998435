import React, { useEffect, useMemo, useRef } from 'react'
import L from 'leaflet'
import { MapContainer, Marker, TileLayer, useMap, useMapEvents } from 'react-leaflet'
import 'leaflet/dist/leaflet.css'

const Map: React.FC<MapPropTypes> = ({center, className, showMarker, onMarkerMove}) => {
  const defaultZoom = 3

  const markerRef = useRef(null)
  const eventHandlers = useMemo(
    () => ({
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          // @ts-ignore
          onMarkerMove(marker.getLatLng())
        }
      },
    }),
    [onMarkerMove],
  )

  return (
    <MapContainer center={center} zoom={defaultZoom} className={className}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {!!showMarker &&
        <Marker
          icon={getMapMaker()}
          position={center}
          draggable={true}
          eventHandlers={eventHandlers}
          ref={markerRef}
        />
      }
      <CustomHandler center={center} showMarker={showMarker} defaultZoom={defaultZoom}/>
    </MapContainer>
  )
}

const CustomHandler: React.FC<CustomHandlerPropTypes> = ({center, showMarker, defaultZoom}) => {
  const map = useMap()
  const zoomForMarker = 13

  useEffect(() => {
    map.setView(center, showMarker ? zoomForMarker : defaultZoom);
  }, [center, map, showMarker, defaultZoom])

  const mapEvents = useMapEvents({
    click: (e) => {
      mapEvents.locate()
    },
    locationfound: (location) => {
      console.log('location found:', location)
    },
  })

  return null
}

const getMapMaker = () => L.divIcon({
  html: `<svg width='30' height='40' viewBox='0 0 41 55' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M39.5979 21.344C39.5979 29.7112 28.212 44.6074 23.2196 50.7535C22.0226 52.2182 19.8038 52.2182 18.6068 50.7535C13.5269 44.6074 2.22852 29.7112 2.22852 21.344C2.22852 11.1924 10.5938 2.96313 20.9132 2.96313C31.2288 2.96313 39.5979 11.1924 39.5979 21.344Z' fill='#3A36DB'/>
  </svg>`,
  className: '',
  iconSize: [30, 40],
  iconAnchor: [13, 35],
})

interface MapPropTypes {
  center: [number, number]
  className: string
  showMarker: boolean
  onMarkerMove: (coordinates: {lat: number, lng: number}) => void
}

interface CustomHandlerPropTypes {
  center: [number, number]
  defaultZoom: number
  showMarker: boolean
}

export default Map
