import { Button, Form, Select, Spin } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { sortBy } from 'lodash'
import { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { CreateCountryThunk, DisableCountriesForCountryThunk, EditCountryThunk, GetAllCountriesThunk, GetCountryByIdThunk, selectAllCountries, selectCurrentCountry, setCurrentCountry } from '../../../store/locationsReducer'
import { CountryType } from '../../../types/locationTypes'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import InputField from '../../common/formFields/InputField'
import classes from './CountryDetailsForm.module.css'

const CountryDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {id} = useParams()
  const [form] = useForm()
  const currentCountry = useAppSelector(selectCurrentCountry)
  const countries = useAppSelector(selectAllCountries)

  const [disabledCountries, setDisabledCountries] = useState<number[]>([])
  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    dispatch(GetAllCountriesThunk())
  }, [dispatch])

  useEffect(() => {
    !!id && !!isEditing && dispatch(GetCountryByIdThunk(+id))

    return () => {dispatch(setCurrentCountry({} as CountryType))}
  }, [id, isEditing, dispatch])

  useEffect(() => {
    if (!!isEditing && !!Object.keys(currentCountry)?.length) {
      form.setFieldsValue(currentCountry)
      setDisabledCountries(currentCountry.blockedCountries?.map(country => country.id))
    }
  }, [form, isEditing, currentCountry])

  const handleDisableCountryChange = (action: 'add' | 'remove', countryIds: number[]) => {
    if (action === 'add') {
      setDisabledCountries(countryIds)
    } else {
      setDisabledCountries(disabledCountries.filter(countryId => countryId !== countryIds[0]))
    }
  }

  const handleSave = (formData: any) => {
    const countryData = {...formData, code: formData.code.toUpperCase()}
    const saveRequest = async() => {
      return isEditing
        ? dispatch(EditCountryThunk({countryData, countryId: +id!}))
        : dispatch(CreateCountryThunk(countryData))
    }
    setIsSaving(true)
    saveRequest()
      .then((resp) => {
        const respData = resp.payload as CountryType
        !resp.type.includes('rejected') && dispatch(DisableCountriesForCountryThunk({countryId: respData?.id, countryIds: disabledCountries}))
        return resp
      })
      .then((resp) => {
        setIsSaving(false)
        !resp.type.includes('rejected') && navigate('/city-country')
      })
  }

  if (!!isEditing && !Object.keys(currentCountry)?.length) {
    return <Spin className={classes.spinner} />
  }
  
  return (
    <Form
      name='country'
      onFinish={handleSave}
      autoComplete='off'
      validateTrigger='onBlur'
      form={form}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentCountry?.name : 'Adding a new country'}
        </h1>
      </div>
      <div className={classes.formBlock}>
        <InputField
          name='name'
          requiredMessage='Please enter country name!'
          placeholder='Enter Name'
          label='The name of the country'
        />
        <InputField
          name='code'
          requiredMessage='Please enter country code!'
          placeholder='Enter Code'
          label='Code'
          maxLength={2}
          rules={[{pattern: new RegExp(/^[a-zA-Z]+$/i), message: 'Only letters are allowed'}]}
        />
        <div style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.85)'}}>
          Select countries to disable their visibility
        </div>
        <Select
          mode='multiple'
          style={{ width: '100%' }}
          placeholder='Select countries'
          onChange={(val: number[]) => handleDisableCountryChange('add', val)}
          value={disabledCountries}
          showSearch
          filterOption={(input, option) => {
            const countryName = option!.children as unknown as string
            return countryName?.toLowerCase().includes(input?.toLowerCase())
          }}
          onDeselect={(val: number) => handleDisableCountryChange('remove', [val])}

        >
          {sortBy(countries, country => country.name).map(country => (
            <Select.Option value={country.id} key={country.id}>
              {country.name}
            </Select.Option>
          ))}
        </Select>
      </div>
      <div className={classes.buttonsAreaWrapper}>
        <Link to='/city-country'>
          <Button>
            Cancel
          </Button>
        </Link>
        <Button
          type='primary'
          htmlType='submit'
          loading={isSaving}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

export default CountryDetailsForm
