import { Upload, message } from "antd"

export const dummyRequest = ({ onSuccess }:any) => {
  setTimeout(() => {
    onSuccess('ok')
  }, 0)
}

export const getBase64 = (file:any) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}

export const getImageUrl = async(file:any): Promise<string> => {
  let imageUrl = await getBase64(file.originFileObj) as string
  return imageUrl
}

export const beforeUpload = (file:any) => {
  const isLt2M = file.size / 1024 / 1024 < 2
  if (!isLt2M) message.error('File must be less than 2 mb')
  return isLt2M ? true : Upload.LIST_IGNORE
}

export const normFile = (e: any, addPhoto: any) => {
  addPhoto(e.file)
  if (Array.isArray(e)) {
    return e
  }
  return e?.fileList
}

export const isValidHTML = (htmlString: string) => {
  let parser = new DOMParser();
  let doc = parser.parseFromString(htmlString, "application/xml");
  return !doc.querySelector('parsererror');
}

export const getHTMLValidationError = (htmlString: string) => {
  const isValid = isValidHTML(htmlString)
  if (!htmlString.length) {
    return 'HTML code is empty!'
  } else if (isValid) {
    return ''
  } else {
    return 'HTML code is invalid!'
  }
}

