import { Checkbox, FormInstance } from 'antd'
import { useEffect, useState } from 'react'
import commonClasses from './../ConnectionField.module.css'
import { ServiceType, ServiceUserLocationItemType } from '../../../../types/serviceTypes'
import SelectCityCountry from '../../../common/SelectCityCountry/SelectCityCountry'
import { AdvertisementEditingType } from '../../../../types/advertisingTypes'

const connectionOptions = ['global', 'country', 'city']

const UserLocationConnectionBlock: React.FC<UserLocationConnectionBlockPropTypes> = ({
  form,
  isEditing,
  currentEditingData,
  blockTitle,
  itemForConnection,
  disabled
}) => {
  const [selectedConnectionOptions, setSelectedConnectionOptions] = useState<string[]>(['global'])
  const [selectedLocations, setSelectedLocations] = useState<ServiceUserLocationItemType[]>([])

  useEffect(() => {
    if (isEditing) {
      const userLocationsData = currentEditingData?.allowed_user_location
      if (!!userLocationsData?.length) {
        setSelectedConnectionOptions([
          ...(userLocationsData.some(location => location.location_name.includes(',')) ? ['city'] : []),
          ...(userLocationsData.some(location => !location.location_name.includes(',')) ? ['country'] : [])
        ])
        setSelectedLocations(userLocationsData)
      } else {
        setSelectedConnectionOptions(['global'])
      }
    }
  }, [isEditing, form, currentEditingData])
  
  const handleConnectionTypeChange = (val: string, checked: boolean) => {
    if (val === 'global') {
      setSelectedConnectionOptions(['global'])
      form.setFieldsValue({allowed_user_location: []})
    } else {
      if (selectedConnectionOptions.includes('global')) {
        setSelectedConnectionOptions([val])
        form.setFieldsValue({allowed_user_location: selectedLocations})
      } else {
        if (!!checked) {
          setSelectedConnectionOptions([...selectedConnectionOptions, val])
          form.setFieldsValue({allowed_user_location: selectedLocations})
        } else {
          const updatedOptions = selectedConnectionOptions.filter(opt => opt !== val)
          !!updatedOptions.length
            ? setSelectedConnectionOptions(updatedOptions)
            : setSelectedConnectionOptions(['global'])
          const updatedLocations = selectedLocations.filter(location => val === 'city' 
            ? !location.location_name.includes(',')
            : location.location_name.includes(',')
          )
          form.setFieldsValue({allowed_user_location: updatedLocations})
          setSelectedLocations(updatedLocations)
        }
      }
    }
  }

  return (
    <div>
      <div className={`${commonClasses.blockTitle} ${itemForConnection === 'advertisement' ? commonClasses.advertisement : ''}`}>
        {blockTitle}
      </div>
      <div
        className={commonClasses.optionsWrapper}
        style={{flexDirection: 'column'}}
      >
        {connectionOptions.map(opt => (
          <div key={opt}>
            <Checkbox
              onChange={(e) => handleConnectionTypeChange(opt, e.target.checked)}
              checked={!!selectedConnectionOptions.includes(opt)}
              disabled={disabled}
            >
              {opt.charAt(0).toUpperCase() + opt.slice(1)}
            </Checkbox>
            {opt === 'country' && !!selectedConnectionOptions.includes(opt) &&  
              <SelectCityCountry
                form={form}
                fieldType={'country'}
                setLocations={setSelectedLocations}
                selectedLocations={selectedLocations}
                disabled={disabled}
              />
            }
            {opt === 'city' && !!selectedConnectionOptions.includes(opt) &&  
              <SelectCityCountry
                form={form}
                fieldType={'city'}
                setLocations={setSelectedLocations}
                selectedLocations={selectedLocations}
                disabled={disabled}
              />
            }
          </div>
        ))}
      </div>
    </div>
  )
}

interface UserLocationConnectionBlockPropTypes {
  form: FormInstance
  isEditing?: boolean
  currentEditingData?: ServiceType | AdvertisementEditingType | null
  blockTitle: string
  itemForConnection: 'service' | 'advertisement'
  disabled?: boolean
}

export default UserLocationConnectionBlock
