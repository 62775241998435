import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { widgetAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { WidgetListFetchParamsType, WidgetType } from '../types/widgetType'

const widgetListFetchParamsDefaultValue: WidgetListFetchParamsType = {
  pagination: {page: 1, size: 10 as 10, sort: [{field: 'created_date', direction: 'ASC'}]},
  filter: {search_value: ''}
}

interface InitialStateType {
  widgetList: null | WidgetType[]
  widgetListTotalCount: number
  widgetListFetchParams: WidgetListFetchParamsType
  currentWidget: null | WidgetType
}

const initialState: InitialStateType = {
  widgetList: null,
  widgetListTotalCount: 0,
  widgetListFetchParams: widgetListFetchParamsDefaultValue,
  currentWidget: null
}

export const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    setWidgetList: (state, action: PayloadAction<null | WidgetType[]>) => {state.widgetList = action.payload},
    setWidgetListTotalCount: (state, action: PayloadAction<number>) => {state.widgetListTotalCount = action.payload},
    setWidgetListFetchParams: (state, action: PayloadAction<WidgetListFetchParamsType>) => {state.widgetListFetchParams = action.payload},
    setCurrentWidget: (state, action: PayloadAction<null | WidgetType>) => {state.currentWidget = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetWidgetListThunk.fulfilled, (state, action) => {
        state.widgetList = action.payload.widgets
        state.widgetListTotalCount = action.payload.total_count
      })
      .addCase(GetWidgetByCodeThunk.fulfilled, (state, action) => {
        state.currentWidget = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.widgetList = null
        state.widgetListTotalCount = 0
        state.widgetListFetchParams = widgetListFetchParamsDefaultValue
      })
  }
})

export const {
  setWidgetList,
  setWidgetListTotalCount,
  setWidgetListFetchParams,
  setCurrentWidget,
} = widgetsSlice.actions

export const selectWidgetList = (state: RootState): null | WidgetType[] => state.widgets.widgetList
export const selectWidgetListTotalCount = (state: RootState): number => state.widgets.widgetListTotalCount
export const selectWidgetListFetchParams = (state: RootState): WidgetListFetchParamsType => state.widgets.widgetListFetchParams
export const selectCurrentWidget = (state: RootState): null | WidgetType => state.widgets.currentWidget

export const GetWidgetListThunk = createAsyncThunk<{widgets: WidgetType[], total_count: number}, {fetchParams: WidgetListFetchParamsType, source: any}, AsyncThunkConfig>(
  'widgets/getWidgetList',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const { status, data } = await widgetAPI.getWidgetList(fetchParams.pagination, fetchParams.filter.search_value, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetWidgetByCodeThunk = createAsyncThunk<WidgetType, string, AsyncThunkConfig>(
  'widgets/getEventByCode',
  async (widgetCode, thunkAPI) => {
    try {
      const { status, data } = await widgetAPI.getWidgetByCode(widgetCode)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const DeleteWidgetThunk = createAsyncThunk<void, number, AsyncThunkConfig>(
  'widgets/getEventByCode',
  async (widgetId, thunkAPI) => {
    try {
      const { status, data } = await widgetAPI.deleteWidget(widgetId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Widget has been successfully deleted'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default widgetsSlice.reducer
 