import { FormInstance, message, Modal, Upload } from 'antd'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import classes from './UploadLogoField.module.css'
import { dummyRequest, getImageUrl } from '../../../../helpers/files_helper'
import {ReactComponent as UploadIcon} from './../../../../img/airportDetailsForm/uploadIcon.svg'
import {ReactComponent as CloseIcon} from './../../../../img/icons/close.svg'
import { selectCurrentService } from '../../../../store/servicesReducer'
import { useAppSelector } from '../../../../app/hooks'
import ColorExtractor from '../../../common/ColorExtractor/ColorExtractor'

const UploadLogoField: React.FC<UploadLogoFieldPropTypes> = ({form, isEditing, setDeleteLogo, setBannerColor}) => {
  const currentService = useAppSelector(selectCurrentService)
  const [preview, setPreview] = useState<ImagePreviewType | null>(null)
  const [bannerColorModalOpen, setBannerColorModalOpen] = useState(false)

  useEffect(() => {
    if (!!isEditing) {
      const value = currentService?.logo_url
      setPreview({url: value, uid: value})
    }
  }, [isEditing, currentService])

  function beforeUpload(file:any) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) message.error('File must be less than 2 mb')
    return isLt2M ? true : Upload.LIST_IGNORE
  }

  const normFile = (e: any) => {
    addPhoto(e.file)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const addPhoto = async(file:any) => {
    const imageUrl = await getImageUrl(file)
    setPreview({uid: file.uid, url: imageUrl})
    form.setFieldsValue({logo: file})
    setDeleteLogo(false)
    setBannerColorModalOpen(true)
  }

  const removePhoto = () => {
    setPreview(null)
    form.setFieldsValue({logo: null})
    setDeleteLogo(true)
  }

  return (
    <div>
      <div className={classes.label}>
        Logo
      </div>
      <Upload.Dragger
        name='files'
        customRequest={dummyRequest}
        beforeUpload={beforeUpload}
        className={classes.uploadDragger}
        accept={'image/png, image/jpeg'}
        maxCount={10}
        onChange={normFile}
        showUploadList={false}
      >
        {preview?.url?.length ? (
          <div className={classes.imagePreviewWrapper}>
            <img src={preview.url} alt='preview' />
            <div className={classes.removePhotoBtn}>
              <CloseIcon onClick={(e) => {e.stopPropagation(); removePhoto()}}/>
            </div>
          </div>
        ) : (
          <>
            <UploadIcon />
            <div>
              Click or drag logo to this area to upload
            </div>
          </>
        )}
      </Upload.Dragger>
      {bannerColorModalOpen &&
        <BannerColorModal
          setBannerColor={setBannerColor}
          logoUrl={preview?.url || ''}
          setBannerColorModalOpen={setBannerColorModalOpen}
        />
      }
    </div>
  )
}

const BannerColorModal: React.FC<BannerColorModalPropTypes> = ({setBannerColor, logoUrl, setBannerColorModalOpen}) => {
  const onColorSelection = (color: string) => {
    setBannerColor(color)
    setBannerColorModalOpen(false)
  }
  return (
    <Modal
      visible={true}
      footer={false}
      closable={false}
      destroyOnClose
      style={{zIndex: 100}}
    >
      <div style={{fontWeight: 600, fontSize: '24px', textAlign: 'center', marginBottom: '20px'}}>
        Banner for service
      </div>
      <ColorExtractor imageUrl={logoUrl} onColorSelection={onColorSelection}/>
    </Modal>
  )
}

interface UploadLogoFieldPropTypes {
  form: FormInstance
  isEditing: boolean
  setDeleteLogo: Dispatch<SetStateAction<boolean>>
  setBannerColor: Dispatch<SetStateAction<string>>
}

interface ImagePreviewType {
  uid: string
  url: string
}

interface BannerColorModalPropTypes {
  setBannerColor: Dispatch<SetStateAction<string>>
  logoUrl: string
  setBannerColorModalOpen: Dispatch<SetStateAction<boolean>>
}

export default UploadLogoField
