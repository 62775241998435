import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { cruiseLineAPI } from '../app/api'
import { CruiseLineFetchParamsType, CruiseLineType, CruiseLineTypeType } from '../types/cruiseType'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

interface InitialStateType {
  cruise_lines: CruiseLineType[]
  cruise_line_types: CruiseLineTypeType[]
  cruise_lines_total_count: number

  current_cruise_line: CruiseLineType | null
}

const initialState: InitialStateType = {
  cruise_lines: [],
  cruise_line_types: [],
  cruise_lines_total_count: 0,

  current_cruise_line: null
}

export const cruiseLinesSlice = createSlice({
  name: 'cruiseLines',
  initialState,
  reducers: {
    setCruiseLines: (state, action: PayloadAction<CruiseLineType[]>) => {state.cruise_lines = action.payload},
    setCruiseLineTypes: (state, action: PayloadAction<CruiseLineTypeType[]>) => {state.cruise_line_types = action.payload},
    setCurrentCruiseLine: (state, action: PayloadAction<CruiseLineType>) => {state.current_cruise_line = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllCruiseLinesThunk.fulfilled, (state, action) => {
        state.cruise_lines = action.payload.cruise_lines
        state.cruise_lines_total_count = action.payload.total_count
      })
      .addCase(GetCruiseLineByIdThunk.fulfilled, (state, action) => {
        state.current_cruise_line = action.payload
      })
      .addCase(CreateCruiseLineThunk.fulfilled, (state, action) => {
        state.cruise_lines = [...state.cruise_lines, action.payload]
      })
      .addCase(EditCruiseLineThunk.fulfilled, (state, action) => {
        state.cruise_lines = state.cruise_lines.map(cruise_line => {
          return cruise_line.id === action.payload.id ? action.payload : cruise_line
        })
      })
      .addCase(DeleteCruiseLineThunk.fulfilled, (state, action) => {
        state.cruise_lines = state.cruise_lines.filter(cruise_line => cruise_line.id !== action.payload)
      })
      .addCase(GetCruiseLineTypesThunk.fulfilled, (state, action) => {
        state.cruise_line_types = action.payload
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.cruise_lines = []
        state.cruise_line_types = []
        state.current_cruise_line = null
      })
  }
})

export const {
    setCruiseLines,
    setCruiseLineTypes,
    setCurrentCruiseLine,
  } = cruiseLinesSlice.actions

export const selectCruiseLines = (state: RootState): CruiseLineType[] => state.cruiseLines.cruise_lines
export const selectCruiseLineTotalCount = (state: RootState): number => state.cruiseLines.cruise_lines_total_count
export const selectCruiseLineTypes = (state: RootState): CruiseLineTypeType[] => state.cruiseLines.cruise_line_types
export const selectCurrentCruiseLine = (state: RootState): CruiseLineType | null => state.cruiseLines.current_cruise_line

export const GetAllCruiseLinesThunk = createAsyncThunk<{cruise_lines: CruiseLineType[], total_count: number}, {fetchParams: CruiseLineFetchParamsType, source: any}, AsyncThunkConfig>(
  'cruiseLines/getAllCruiseLines',
  async (requestData, thunkAPI) => {
    try {
      const { status, data } = await cruiseLineAPI.getCruiseLineList(requestData.fetchParams, requestData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetCruiseLineByIdThunk = createAsyncThunk<CruiseLineType, number, AsyncThunkConfig>(
  'cruiseLines/getCruiseLineById',
  async (cruiseLineId, thunkAPI) => {
    try {
      const { status, data } = await cruiseLineAPI.getCruiseLineById(cruiseLineId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateCruiseLineThunk = createAsyncThunk<CruiseLineType, CruiseLineType, AsyncThunkConfig> (
  'cruiseLines/createCruiseLine',
  async (cruiseLineData, thunkAPI) => {
    try {
      const { status, data } = await cruiseLineAPI.createCruiseLine(cruiseLineData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Cruise Line has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditCruiseLineThunk = createAsyncThunk<CruiseLineType, CruiseLineType, AsyncThunkConfig> (
  'cruiseLines/editCruiseLine',
  async (cruiseLineData, thunkAPI) => {
    try {
      const { status, data } = await cruiseLineAPI.editCruiseLine(cruiseLineData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Cruise Line has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteCruiseLineThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'cruiseLines/deleteCruiseLine',
  async (cruiseId, thunkAPI) => {
    try {
      const { status, data } = await cruiseLineAPI.deleteCruiseLine(cruiseId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(cruiseId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const GetCruiseLineTypesThunk = createAsyncThunk<CruiseLineTypeType[], void, AsyncThunkConfig>(
  'cruiseLines/getCruiseLineTypes',
  async (_, thunkAPI) => {
    try {
      const { status, data } = await cruiseLineAPI.getCruiseLineTypes()
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.cruise_line_types, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default cruiseLinesSlice.reducer
 