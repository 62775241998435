import { Button, Input, Select } from 'antd'
import { Link } from 'react-router-dom'
import ItemList from '../common/ItemList/ItemList'
import axios from './../../helpers/axiosHelper'
import classes from './TerminalList.module.css'
import {ReactComponent as SearchIcon} from './../../img/icons/search.svg'
import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { TerminalFetchParams, TerminalType } from '../../types/terminalTypes'
import { DeleteTerminalThunk, GetAllTerminalsThunk, selectTerminalList, selectTerminalsTotalCount, terminalNodeTypes } from '../../store/terminalsReducer'
import { selectTerminalFetchParams, setTerminalFetchParams } from '../../store/appStatusReducer'

const TerminalList = () => {
  const dispatch = useAppDispatch()
  const terminals = useAppSelector(selectTerminalList)
  const terminals_total_count = useAppSelector(selectTerminalsTotalCount)
  const fetchParams = useAppSelector(selectTerminalFetchParams)
  const [tableData, setTableData] = useState<TerminalType[]>([])
  const [isDataLoading, setIsDataLoading] = useState(false)

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    setIsDataLoading(true)
    dispatch(GetAllTerminalsThunk({fetchParams, source}))
      .then((resp) => {
        if(!resp.type.includes('rejected')) {
          setIsDataLoading(false)
        }
      })

    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, fetchParams])

  useEffect(() => {
    setTableData(terminals)
  }, [terminals])

  const setFetchParams = (params: TerminalFetchParams) => {
    dispatch(setTerminalFetchParams(params))
  }

  // eslint-disable-next-line
  const handleSearchDebounce = useCallback(
    debounce((searchRequest: string) => {
      setFetchParams({...fetchParams, search_value: encodeURI(searchRequest)})
    }, 350),
    [fetchParams]
  )

  const deleteItem = (terminal: TerminalType): void => {
    dispatch(DeleteTerminalThunk(terminal.id))
  }
  
  const editItemLink = '/terminals/edit/'

  return (
    <div>
      <div className={classes.pageHeader}>
        <h1>
          Terminals
        </h1>
        <Link to='/terminals/create'>
          <Button type='primary'>+ Add New</Button>
        </Link>
      </div>
      <div className={classes.tools}>
          <div>
            <Select
              value={fetchParams.node_type}
              onChange={(val) => setFetchParams({...fetchParams, node_type: val})}
              style={{width: '200px', marginRight: '10px'}}
              className={classes.resultsFilter}
            >
              <Select.Option value={null}>All node types</Select.Option>
              {terminalNodeTypes.map((type) => (
                <Select.Option value={type.value} key={type.value}>{type.title}</Select.Option>
              ))}
            </Select>
          </div>
          <div>
            <Select
              value={fetchParams.search_filter}
              onChange={(val) => setFetchParams({...fetchParams, search_filter: val})}
              style={{width: '175px', marginRight: '10px'}}
              className={classes.resultsFilter}
            >
              <Select.Option value={'ALL'}>Show all</Select.Option>
              <Select.Option value={'NOT_APPROVED'}>Not approved only</Select.Option>
            </Select>
          </div>
          <div>
            <Input.Group compact style={{display:'flex'}}>
              <Select
                style={{ width: '150px' }}
                value={fetchParams.search_param}
                onChange={(val) => setFetchParams({...fetchParams, search_param: val as 'NAME' | 'CODE' | 'TERMINAL'})}
              >
                <Select.Option value={'TERMINAL'}>By name</Select.Option>
                <Select.Option value={'NAME'}>By node name</Select.Option>
                <Select.Option value={'CODE'}>By node code</Select.Option>
              </Select>
              <Input
                placeholder='Search'
                suffix={<SearchIcon />}
                defaultValue={decodeURIComponent(fetchParams.search_value as string)}
                onChange={(e) => handleSearchDebounce(e.target.value.trim())}
                style={{width: '240px'}}
              />
            </Input.Group>
          </div>
        </div>
      <ItemList
        items={tableData}
        editItemLink={editItemLink}
        deleteItem={deleteItem}
        loading={isDataLoading}
        pagination={fetchParams.pagination}
        setPagination={(pagination: any) => setFetchParams({...fetchParams, pagination})}
        total={terminals_total_count}
        columnList={['name', 'latitude', 'longitude', 'node_type', 'node_name', 'node_code', 'comment', 'actions']}
        rowIdKey='id'
      />
    </div>
  )
}

export default TerminalList
