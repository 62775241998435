import { Button, Checkbox, Form, FormInstance, Select, Spin, Tooltip } from 'antd'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { debounce } from 'lodash'
import axios from './../../../helpers/axiosHelper'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import Breadcrumbs from '../../common/Breadcrumbs/Breadcrumbs'
import classes from './TerminalDetailsForm.module.css'
import redDot from './../../../img/icons/redDot.png'
import {ReactComponent as InfoIcon} from './../../../img/icons/info.svg'
import { CreateTerminalThunk, EditTerminalThunk, GetNodeOptionsThunk, GetTerminalByIdThunk, selectCurrentTerminal, selectNodeOptions, setCurrentTerminal, setNodeOptions, terminalNodeTypes } from '../../../store/terminalsReducer'
import { TerminalType } from '../../../types/terminalTypes'
import InputField from '../../common/formFields/InputField'
import Map from '../../common/Map/Map'
import CoordinatesField from '../../common/formFields/CoordinatesField/CoordinatesField'

const TerminalDetailsForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const currentTerminal = useAppSelector(selectCurrentTerminal)
  const [form] = Form.useForm()

  const [editingTerminalId, setEditingTerminalId] = useState(0)
  const [isSaving, setIsSaving] = useState(false)
  const [errors, setErrors] = useState([])
  const [isApproved, setIsApproved] = useState(false)

  const [mapCenter, setMapCenter] = useState<[number, number] | []>([])
  const [coordinates, setCoordinates] = useState('')

  const onFinishFailed = (errors: any) => {
    const errorsData = errors?.errorFields?.map((error:any) => error.errors).flat(1) || []
    setErrors(errorsData)
  }

  const handleMapMarkerMove = (coordinates: {lat: number, lng: number}) => {
    form.setFieldsValue({'latitude': coordinates.lat, 'longitude': coordinates.lng})
    setCoordinates(`${coordinates.lat}, ${coordinates.lng}`)
  }

  const sendData = async(formData: any) => {
    if (isEditing) {
      return dispatch(EditTerminalThunk({terminal_request: formData, terminalId : editingTerminalId}))
    } else {
      return dispatch(CreateTerminalThunk(formData))
    }
  }

  const handleSave = async() => {
    const terminalData = form.getFieldsValue(true)
    const formData = new FormData()
    formData.append('terminal_request', new Blob([JSON.stringify(terminalData, null, 2)], {type: 'application/json'}))

    setIsSaving(true)
    sendData(formData)
      .then((resp) => {
        setIsSaving(false)
        !resp?.type.includes('rejected') && navigate('/terminals')
      })
  }

  useEffect(() => {
    if (isEditing) {
      // pathname example: /terminals/edit/47895
      const id = location.pathname.split('edit/')[1]
      setEditingTerminalId(+id)
      dispatch(GetTerminalByIdThunk(+id))
    }
    return () => {
      dispatch(setCurrentTerminal({} as TerminalType))
    }
  }, [isEditing, dispatch, location])

  useEffect(() => {
    if (isEditing && !!Object.keys(currentTerminal)?.length) {
      const terminalFieldsData = {
        ...currentTerminal,
        node_type: currentTerminal.node?.label,
        node_name: currentTerminal.node?.name
      }
      form.setFieldsValue(terminalFieldsData)
      setIsApproved(form.getFieldValue('is_approved') === null ? true : form.getFieldValue('is_approved'))
    } else if (!isEditing) {
      form.setFieldsValue({is_approved: true})
      setIsApproved(true)
    }
  }, [currentTerminal, form, isEditing, dispatch])

  const handleApprovalChange = (isApproved:boolean) => {
    setIsApproved(isApproved)
    form.setFieldsValue({'is_approved': isApproved})
  }

  if (isEditing && !Object.keys(currentTerminal).length) {
    return <Spin className={classes.spinner} />
  }

  return (
    <Form
      name='terminal'
      onFinish={handleSave}
      onFinishFailed={onFinishFailed}
      autoComplete='off'
      validateTrigger='onBlur'
      className={classes.wrapper}
      form={form}
    >
      <div>
        <Breadcrumbs />
        <h1>
          {isEditing ? currentTerminal?.name : 'Adding a new terminal'}
          {currentTerminal?.is_approved === false &&
            <Tooltip title='Data needs to be approved'>
              <img src={redDot} alt='needs attention' className={classes.redDot}/>
            </Tooltip>
          }
        </h1>
        <div className={classes.blocksWrapper}>
          <div className={classes.formBlock}>
            <InputField
              name='name'
              requiredMessage={`Please enter terminal name!`}
              placeholder='Enter Name'
              label={`Terminal Name`}
            />
            <div>
              <div className={classes.label}>
                Node Type
              </div>
              <Form.Item
                name='node_type'
                rules={[ {required: true, message: 'Please select node type!'} ]}
              >
                <Select
                  style={{width: '100%'}}
                  placeholder='Node Type'
                >
                  {terminalNodeTypes.map((type) => (
                    <Select.Option value={type.value} key={type.value}>{type.title}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </div>
            <NodeNameField form={form}/>
          
            <CoordinatesField
              form={form}
              setMapCenter={setMapCenter}
              coordinates={coordinates}
              setCoordinates={setCoordinates}
            />
          </div>
          <div className={classes.formBlock}>
            <InputField
              name='comment'
              placeholder='Enter Comment'
              label={`Add a Comment`}
              rows={6}
            />
          </div>
        </div>
        <div className={classes.mapWrapper}>
          <Map
            center={mapCenter.length ? mapCenter : [0, 0]}
            showMarker={!!mapCenter.length}
            className={classes.map}
            onMarkerMove={handleMapMarkerMove}
          />
        </div>
      </div>
      <div className={classes.navigationBtnWrapper}>
        {!!errors.length &&
          <div className={classes.errors}>
            {errors.map(error => (
              <div key={error}>{error}</div>
            ))}
          </div>
        }
        <div className={classes.btnsArea}>
          <div className={classes.approveData}>
            <div>
              <InfoIcon />
              Terminal data is approved
            </div>
            <Checkbox checked={isApproved} onChange={e => handleApprovalChange(e.target.checked)}/>
          </div>

          <div className={classes.buttons}>
            <Link to='/terminals'>
              <Button className={classes.navigationBtn}>
                Cancel
              </Button>
            </Link>
            <Button
              type='primary'
              htmlType='submit'
              className={classes.navigationBtn}
              loading={isSaving}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

const usePrevious = (value: any) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const NodeNameField: React.FC<{form: FormInstance}> = ({form}) => {
  const dispatch = useAppDispatch()
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  const nodeOptions = useAppSelector(selectNodeOptions)
  const nodeType = Form.useWatch('node_type', form)
  const nodeName = form.getFieldValue('node_name')
  const currentTerminal = useAppSelector(selectCurrentTerminal)

  const [searchQuery,  setSearchQuery] = useState('')
  const [inputValue,  setInputValue] = useState('')
  const [isLoading,  setIsLoading] = useState(false)
  const [options, setOptions] = useState<{label: string, value: number}[]>([])

  useEffect(() => {
    const options = nodeOptions.map(node => ({label: node.name, value: node.id}))
    setOptions(options)
  }, [nodeOptions])

  const prevNodeType = usePrevious(nodeType)

  useEffect(() => {
    if (!!prevNodeType && prevNodeType !== nodeType) {
      setInputValue('')
      dispatch(setNodeOptions([]))
      form.setFieldsValue({node_name: null})
    }
  }, [nodeType])

  useEffect(() => {
    if (!!searchQuery.length) {
      setIsLoading(true)
      dispatch(GetNodeOptionsThunk({name: searchQuery, type: form.getFieldValue('node_type'), source}))
        .then((resp) => !resp.type.includes('rejected') && setIsLoading(false))
    } else {
      dispatch(setNodeOptions([]))
    }
    return () => {source.cancel()}
    // eslint-disable-next-line
  }, [dispatch, searchQuery])

  useEffect(() => {
    if (!!nodeName?.length && !inputValue.length && !searchQuery) {
      setInputValue(nodeName)
    }
  }, [inputValue, form, currentTerminal, nodeName])

  useEffect(() => {
    return () => {
      setInputValue('')
      dispatch(setNodeOptions([]))
      setSearchQuery('')
    }
    // eslint-disable-next-line
  }, [])

  const handleNodeSearch = (searchQuery: string) => {
    !!searchQuery.length && handleNodeSearchDebounce(searchQuery.split('(')[0])
    setInputValue(searchQuery)
  }

  // eslint-disable-next-line
  const handleNodeSearchDebounce = useCallback(
    debounce((searchQuery: string) => {
      setSearchQuery(searchQuery)
    }, 350), []
  )

  return (
    <div>
      <div className={classes.label}>
        Node Name
      </div>
      <Form.Item rules={[ {required: true, message: 'Please select node name!'} ]} name='node_name'>
        <Select
          style={{width: '100%'}}
          placeholder={!!nodeType ? 'Node Name' : 'Select node type first'}
          showSearch
          disabled={!nodeType}
          onSearch={handleNodeSearch}
          searchValue={inputValue}
          filterOption={false}
          labelInValue
          onSelect={(optionData:any) => form.setFieldsValue({'node_id': optionData.value, 'node_name': optionData.label})}
          notFoundContent={isLoading ? (
            <Spin size='small' />
          ) : (
            <>
              {!!searchQuery.length && !options.length && 'No results found'}
              {!searchQuery.length && !options.length && 'Start typing node name'}
            </>
          )}
        >
          {nodeOptions.map(opt => (
            <Select.Option value={opt.id} key={opt.id}>
              {opt.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  )
}

export default TerminalDetailsForm
