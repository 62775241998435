import CryptoJS from 'crypto-js'

export const slugify = (string: string) => {
  return string
    .toString()
    .normalize('NFD')
    .toLowerCase()
    .replace(/[^\w\s-]/g, ' ') 
    .trim()
    .replace(/\s+/g, '_')
}

export const encryptPassword = (text: string): string => {
  const base64Key = 'iXegCsz6L7NnfzkagWOo1yhNTgt+VpJ8KAqEl8v8w/4='
  const key = CryptoJS.enc.Base64.parse(base64Key)
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  })
  return encrypted.toString()
}
