import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { busStationAPI } from '../app/api'
import { TransportCategoryFetchParams } from '../types/appTypes'
import { BusStationType } from '../types/busStationTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

interface InitialStateType {
  bus_stations: BusStationType[]
  bus_stations_total_count: number
  current_bus_station: BusStationType
}

const initialState: InitialStateType = {
  bus_stations: [],
  bus_stations_total_count: 0,
  current_bus_station: {} as BusStationType
}

export const busStationsSlice = createSlice({
  name: 'busStations',
  initialState,
  reducers: {
    setBusStations: (state, action: PayloadAction<BusStationType[]>) => {state.bus_stations = action.payload},
    setCurrentBusStations: (state, action: PayloadAction<BusStationType>) => {state.current_bus_station = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllBusStationsThunk.fulfilled, (state, action) => {
        state.bus_stations = action.payload.bus_stations
        state.bus_stations_total_count = action.payload.total_count
      })
      .addCase(GetBusStationByIdThunk.fulfilled, (state, action) => {
        state.current_bus_station = action.payload
      })
      .addCase(CreateBusStationThunk.fulfilled, (state, action) => {
        state.bus_stations = [...state.bus_stations, action.payload]
      })
      .addCase(EditBusStationThunk.fulfilled, (state, action) => {
        state.bus_stations = state.bus_stations.map(busStation => {
          return busStation.id === action.payload.id ? action.payload : busStation
        })
      })
      .addCase(DeleteBusStationThunk.fulfilled, (state, action) => {
        state.bus_stations = state.bus_stations.filter(busStation => busStation.id !== action.payload)
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.bus_stations = []
        state.current_bus_station = {} as BusStationType
      })
  }
})

export const { setBusStations, setCurrentBusStations } = busStationsSlice.actions

export const selectBusStationList = (state: RootState): BusStationType[] => state.busStations.bus_stations
export const selectBusStationTotalCount = (state: RootState): number => state.busStations.bus_stations_total_count
export const selectCurrentBusStation = (state: RootState): BusStationType => state.busStations.current_bus_station

export const GetAllBusStationsThunk = createAsyncThunk<{bus_stations: BusStationType[], total_count: number}, {fetchParams: TransportCategoryFetchParams, source: any}, AsyncThunkConfig>(
  'busStations/getAllBusStations',
  async (fetchParamsData, thunkAPI) => {
    try {
      const { status, data } = await busStationAPI.getAllBusStations(fetchParamsData.fetchParams, fetchParamsData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetBusStationByIdThunk = createAsyncThunk<BusStationType, number, AsyncThunkConfig>(
  'busStations/getBusStationById',
  async (busStationId, thunkAPI) => {
    try {
      const { status, data } = await busStationAPI.getBusStationById(busStationId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateBusStationThunk = createAsyncThunk<BusStationType, any, AsyncThunkConfig> (
  'busStations/createBusStation',
  async (busStationData, thunkAPI) => {
    try {
      const { status, data } = await busStationAPI.createBusStation(busStationData)
      if (status === 201 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Bus Station has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditBusStationThunk = createAsyncThunk<BusStationType, {busStationData: any, busStationId: number}, AsyncThunkConfig> (
  'busStations/editBusStation',
  async (busStationData, thunkAPI) => {
    try {
      const { status, data } = await busStationAPI.editBusStation(busStationData.busStationData, busStationData.busStationId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Bus Station has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteBusStationThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'busStations/deleteBusStation',
  async (busStationId, thunkAPI) => {
    try {
      const { status, data } = await busStationAPI.deleteBusStation(busStationId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(busStationId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default busStationsSlice.reducer
 