import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getImageUrl } from '../../../../../helpers/files_helper'
import { Button, Form, FormInstance } from 'antd'
import UploadFileField from '../../../../common/UploadFileField/UploadFileField'
import InputField from '../../../../common/formFields/InputField'
import classes from './../../AdvertisingDetailsForm.module.css'
import localClasses from './GenerateBannerBlock.module.css'
import { SketchPicker } from 'react-color'
import { ImagePreviewType } from '../../../../../types/appTypes'

const GenerateBannerBlock: React.FC<GenerateBannerBlockPropTypes> = ({form, preview, setPreview, isEditing}) => {
  const [bannerSettings, setBannerSettings] = useState<BannerSettingsType>({} as BannerSettingsType)
  const [pickersVisibility, setPickersVisibility] = useState({color: false, bgColor: false})

  const bannerComment = Form.useWatch('banner_comment', form)

  useEffect(() => {
    if (!bannerSettings.banner_bg_color && !bannerSettings.banner_color) {
      setBannerSettings({
        banner_bg_color: form.getFieldValue('banner_bg_color'),
        banner_color: form.getFieldValue('banner_color')
      })
    }
  }, [isEditing, form, bannerSettings])

  const addBannerImage = async(file:any) => {
    const imageUrl = await getImageUrl(file)
    setPreview({uid: file.uid, url: imageUrl})
    form.setFieldsValue({banner: file})
  }

  const saveColorChange = (field: 'banner_color' | 'banner_bg_color', color: string) => {
    form.setFieldValue(field, color)
    setBannerSettings({...bannerSettings, [field]: color})
  }

  return (
    <div>
      <div className={classes.doubleInputArea}>
        <div>
          <div className={classes.label}>
            Image
          </div>
          <UploadFileField
            addBannerImage={addBannerImage}
            preview={preview}
          />
        </div>
        <InputField
          name='banner_comment'
          placeholder='Comment for banner'
          label='Enter text for banner'
          rows={6}
          maxLength={20}
        />
      </div>
      <div className={classes.doubleInputArea} style={{marginBottom: '10px'}}>
        <div style={{position: 'relative'}}>
          <div className={classes.label}>
            Text color
          </div>
          <div
            className={localClasses.colorExample}
            style={{backgroundColor: bannerSettings.banner_color, cursor: 'pointer'}}
            onClick={() => setPickersVisibility({...pickersVisibility, color: true})}
          ></div>
          <PickerBlock
            isVisible={pickersVisibility.color}
            color={bannerSettings.banner_color}
            onChange={(color: string) => saveColorChange('banner_color', color)}
            closePicker={() => setPickersVisibility({...pickersVisibility, color: false})}
          />
          <Form.Item
            name='banner_color'
            style={{display: 'none'}}
          ></Form.Item>
        </div>
        <div style={{position: 'relative'}}>
          <div className={classes.label}>
            Background color
          </div>
          <div
            className={localClasses.colorExample}
            style={{backgroundColor: bannerSettings.banner_bg_color, cursor: 'pointer'}}
            onClick={() => setPickersVisibility({...pickersVisibility, bgColor: true})}
          ></div>
          <PickerBlock
            isVisible={pickersVisibility.bgColor}
            color={bannerSettings.banner_bg_color}
            onChange={(color: string) => saveColorChange('banner_bg_color', color)}
            closePicker={() => setPickersVisibility({...pickersVisibility, bgColor: false})}
          />
          <Form.Item
            name='banner_bg_color'
            style={{display: 'none'}}
          ></Form.Item>
        </div>
      </div>
      {preview?.url &&
      <div style={{height: '62px', width: '350px'}}>
        <BigBanner
          bannerData={{imagePreview: preview?.url || '', bannerText: bannerComment}}
          bannerSettings={bannerSettings}
        />
      </div>
      }
    </div>
  )
}

const PickerBlock:React.FC<PickerBlockPropTypes> = ({isVisible, color, onChange, closePicker}) => {
  return (
    <div className={`${localClasses.sketchPicker} ${isVisible ? localClasses.visible : ''}`}>            
      <SketchPicker
        color={ color }
        onChangeComplete={(color) => onChange(color.hex)}
      />
      <div style={{backgroundColor:'white', display: 'flex', justifyContent: 'end', borderTop: '0.5px solid #e4dede'}}>
        <Button onClick={() => closePicker()}>
          Close
        </Button>
      </div>
    </div>
  )
}

export const BigBanner:React.FC<{bannerSettings: BannerSettingsType, bannerData: {imagePreview: string, bannerText: string}}> = ({bannerSettings, bannerData}) => {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        backgroundColor: bannerSettings.banner_bg_color,
        color: bannerSettings.banner_color,
        borderRadius: '8px',
        fontSize: '14px',
        display: 'grid',
        gridTemplateColumns: bannerData.bannerText?.length ? '60px 1fr' : '1fr',
        gridTemplateRows: '100%',
        overflow: 'hidden',
      }}
    >
      <img
        src={bannerData?.imagePreview}
        style={{
          height: '100%',
          objectFit: 'scale-down',
          ...(!!bannerData.bannerText?.length ? {
            borderTopLeftRadius: '8px',
            borderBottomLeftRadius: '8px',
          } : {
            borderRadius: '8px',
          }),
          width: '100%',
        }}
        alt='banner'
      />
      {!!bannerData.bannerText?.length &&
        <div
          style={{
            padding: '8px 10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '18px',
          }}
        >
          {bannerData.bannerText}
        </div>
      }
    </div>
  )
}

// const SmallBanner:React.FC<{bannerSettings: BannerSettingsType, bannerData: {imagePreview: ImagePreviewType, bannerText: string}}> = ({bannerSettings, bannerData}) => {
//   return (
//     <div style={{width: '74px', height: '63px'}}>
//       <img src={bannerData?.imagePreview?.url} style={{width: '74px', height: '63px', objectFit: 'cover', borderRadius: '8px'}} alt='banner'/>
//       <div style={{padding: '2px 5px', textAlign: 'center', left: '0', right: '0', color: bannerSettings.banner_color}}>
//         {bannerData.bannerText}
//       </div>
//     </div>
//   )
// }

interface GenerateBannerBlockPropTypes {
  form: FormInstance
  preview: ImagePreviewType | null
  setPreview: Dispatch<SetStateAction<ImagePreviewType | null>>
  isEditing: boolean
}

interface BannerSettingsType {
  banner_color: string
  banner_bg_color: string
  // size: 'big' | 'small'
}

interface PickerBlockPropTypes {
  isVisible: boolean
  color: string
  closePicker: () => void
  onChange: (color: string) => void
}


export default GenerateBannerBlock
