import { Tag } from 'antd'
import { Dispatch, SetStateAction, useState } from 'react'
import { geocodeByAddress } from 'react-places-autocomplete'
import { ServiceUserLocationItemType } from '../../../types/serviceTypes'
import { InputGoogleAPI } from '../InputGoogleAPI/InputGoogleAPI'
import { FormInstance } from 'antd/es/form/Form'
import classes from './SelectCityCountry.module.css'

const SelectCityCountry: React.FC<SelectCityCountryPropTypes> = ({form, fieldType, setLocations, selectedLocations, disabled}) => {
  const [inputValue, setInputValue] = useState('')

  const fieldTitle = fieldType.charAt(0).toUpperCase() + fieldType.slice(1)
  
  const addLocation = async(location: string) => {
    const addressData = await geocodeByAddress(location)
    const selectedLocations = form.getFieldValue('allowed_user_location') || [] as ServiceUserLocationItemType[]
    if (selectedLocations.some((l: ServiceUserLocationItemType) => l.place_id === addressData[0]?.place_id)) {
      return
    }
    form.setFieldsValue({allowed_user_location: [...selectedLocations, {place_id: addressData[0]?.place_id, location_name: location}]})
    setLocations([...selectedLocations, {place_id: addressData[0]?.place_id, location_name: location}])
  }

  const removeLocation = (placeId: string) => {
    const updatedLocations = selectedLocations.filter(location => location.place_id !== placeId)
    setLocations(updatedLocations)
    form.setFieldsValue({allowed_user_location: updatedLocations})
  }

  return (
    <>
      <div className={classes.explanationText}>
        Select {fieldTitle} (You can select one or many options)
      </div>
      <InputGoogleAPI
        value={inputValue}
        onChange={(value) => {
          addLocation(value)
          setInputValue('')
        }}
        placeholder={`Start typing ${fieldTitle} name`}
        suggestionsPosition='vertical'
        clearAfterSelect
        searchOptions={{types: fieldType === 'city' ? ['(cities)'] : ['country']}}
        style={{height: '48px'}}
        disabled={disabled}
      />
      <div style={{marginBottom: '20px'}}>
        {selectedLocations
          .filter(loc => fieldType === 'city' ? loc.location_name.includes(',') : !loc.location_name.includes(','))
          .map(location => (
            <Tag
              closable={!disabled}
              onClose={() => removeLocation(location.place_id)}
              key={location.location_name}
            >
              {location.location_name}
            </Tag>
          ))}
      </div>
    </>
  )
}

interface SelectCityCountryPropTypes {
  fieldType: 'city' | 'country'
  setLocations: Dispatch<SetStateAction<ServiceUserLocationItemType[]>>
  selectedLocations: ServiceUserLocationItemType[]
  form: FormInstance
  isEditing?: boolean
  disabled?: boolean
}

export default SelectCityCountry
