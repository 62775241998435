import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { airportAPI } from '../app/api'
import { AirportType } from '../types/airportTypes'
import { TransportCategoryFetchParams } from '../types/appTypes'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'

interface InitialStateType {
  airports: AirportType[]
  airports_total_count: number
  currentAirport: AirportType
}

const initialState: InitialStateType = {
  airports: [],
  airports_total_count: 0,
  currentAirport: {} as AirportType
}

export const airportsSlice = createSlice({
  name: 'airports',
  initialState,
  reducers: {
    setAirports: (state, action: PayloadAction<AirportType[]>) => {state.airports = action.payload},
    setCurrentAirport: (state, action: PayloadAction<AirportType>) => {state.currentAirport = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllAirportsThunk.fulfilled, (state, action) => {
        state.airports = action.payload.airports
        state.airports_total_count = action.payload.total_count
      })
      .addCase(GetAirportByIdThunk.fulfilled, (state, action) => {
        state.currentAirport = action.payload
      })
      .addCase(CreateAirportThunk.fulfilled, (state, action) => {
        state.airports = [...state.airports, action.payload]
      })
      .addCase(EditAirportThunk.fulfilled, (state, action) => {
        state.airports = state.airports.map(airport => {
          return airport.id === action.payload.id ? action.payload : airport
        })
      })
      .addCase(DeleteAirportThunk.fulfilled, (state, action) => {
        state.airports = state.airports.filter(airport => airport.id !== action.payload)
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.airports = []
        state.currentAirport = {} as AirportType
      })
  }
})

export const { setAirports, setCurrentAirport } = airportsSlice.actions

export const selectAirportList = (state: RootState): AirportType[] => state.airports.airports
export const selectAirportTotalCount = (state: RootState): number => state.airports.airports_total_count
export const selectCurrentAirport = (state: RootState): AirportType => state.airports.currentAirport

export const GetAllAirportsThunk = createAsyncThunk<{airports: AirportType[], total_count: number}, {fetchParams: TransportCategoryFetchParams, source: any}, AsyncThunkConfig>(
  'airports/getAllAirportList',
  async (fetchParamsData, thunkAPI) => {
    try {
      const { status, data } = await airportAPI.getAllAirports(fetchParamsData.fetchParams, fetchParamsData.source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetAirportByIdThunk = createAsyncThunk<AirportType, number, AsyncThunkConfig>(
  'airports/getAirportById',
  async (airportId, thunkAPI) => {
    try {
      const { status, data } = await airportAPI.getAirportById(airportId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const CreateAirportThunk = createAsyncThunk<AirportType, any, AsyncThunkConfig> (
  'airports/createAirport',
  async (airportData, thunkAPI) => {
    try {
      const { status, data } = await airportAPI.createAirport(airportData)
      if (status === 201 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Airport has been added'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const EditAirportThunk = createAsyncThunk<AirportType, {airportData: any, airportId: number}, AsyncThunkConfig> (
  'airports/editAirport',
  async (airportData, thunkAPI) => {
    try {
      const { status, data } = await airportAPI.editAirport(airportData.airportData, airportData.airportId)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.succeeded, appMessage: 'Airport has been edited'})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export const DeleteAirportThunk = createAsyncThunk<number, number, AsyncThunkConfig> (
  'airports/deleteAirport',
  async (airportId, thunkAPI) => {
    try {
      const { status, data } = await airportAPI.deleteAirport(airportId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(airportId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message)
    }
  }
)

export default airportsSlice.reducer
 