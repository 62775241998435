import { Form, Input, InputNumber, Popover } from 'antd'
import { Rule } from 'antd/lib/form'
import TextArea from 'antd/lib/input/TextArea'
import { ReactNode } from 'react'
import {ReactComponent as QuestionMarkIcon} from './../../../img/icons/questionMark.svg'

const InputField: React.FC<InputFieldProps> = (props) => {
  const matchWithValidator:Rule= ({ getFieldValue }) => ({
    validator(_:any, value:any) {
      if (!value || getFieldValue(props.matchWith!.field) === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(props.matchWith!.message))
    }
  })

  return (
    <div>
      {!!props.label &&
        <div style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.85)'}}>
          {props.label}
          {props.fieldExplanation &&
          <Popover content={props.fieldExplanation}>
            <QuestionMarkIcon style={{marginLeft: '5px', cursor: 'pointer', width: '10px', position: 'absolute'}}/>
          </Popover>
          }
        </div>
      }
      <Form.Item
        name={props.name}
        dependencies={props.dependencies}
        rules={[
          ...(!!props.requiredMessage ? [{required: true, message: props.requiredMessage}] : []),
          ...(!!props.matchWith ? [matchWithValidator] : []),
          ...(props.rules?.length ? props.rules : [])
        ]}
      >
        {props.rows || props.autoSize ? (
          <TextArea
            rows={props.rows}
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            maxLength={props.maxLength}
            disabled={props.disabled}
            autoSize={props.autoSize}
            showCount={props.showCount}
          />
        ) : (props.type === 'password' ? (
          <Input.Password
            placeholder={props.placeholder}
            autoComplete={props.autoComplete}
            maxLength={props.maxLength}
            disabled={props.disabled}
          />
        ) : (props.type === 'number' ? (
          <InputNumber
            placeholder={props.placeholder}
            addonBefore={props.addonBefore}
            disabled={props.disabled}
            min={props.minNumber}
            max={props.maxNumber}
            controls={false}
          />
        ) : (
          <Input
            placeholder={props.placeholder}
            addonBefore={props.addonBefore}
            autoComplete={props.autoComplete}
            maxLength={props.maxLength}
            disabled={props.disabled}
            prefix={props.prefix}
          />
        )))}
      </Form.Item>
    </div>
  )
}

interface InputFieldProps {
  label?: string
  name: string | string[]
  placeholder?: string
  dependencies?: string[]
  requiredMessage?: string
  matchWith?: {field: string, message: string}
  rules?: Rule[],
  addonBefore?: string | ReactNode
  prefix?: ReactNode
  rows?: number
  type?: 'text' | 'password' | 'number'
  autoComplete?: string
  maxLength?: number
  disabled?: boolean
  fieldExplanation?: string
  minNumber?: number
  maxNumber?: number
  autoSize?: { minRows: number, maxRows: number }
  showCount?: boolean
}

export default InputField
