import { FormInstance, message, Upload } from 'antd'
import { useEffect, useState } from 'react'
import { dummyRequest, getImageUrl } from '../../../../helpers/files_helper'
import classes from './PhotoTab.module.css'
import {ReactComponent as UploadIcon} from './../../../../img/airportDetailsForm/uploadIcon.svg'
import {ReactComponent as CloseIcon} from './../../../../img/icons/close.svg'
import {ReactComponent as PictureIcon} from './../../../../img/icons/picture.svg'

const PhotoTab: React.FC<PhotoTabPropTypes> = ({form, isEditing}) => {
  const [previews, setPreviews] = useState<ImagePreviewType[]>([])

  useEffect(() => {
    if (!!isEditing) {
      setPreviews(form.getFieldValue('photo_urls')?.map((url: string) => ({url, uid: url})))
    }
  }, [form, isEditing])

  function beforeUpload(file:any) {
    const isLt2M = file.size / 1024 / 1024 < 2
    if (!isLt2M) message.error('File must be less than 2 mb')
    return isLt2M ? true : Upload.LIST_IGNORE
  }

  const normFile = (e: any) => {
    addPhoto(e.file)
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const addPhoto = async(file:any) => {
    const imageUrl = await getImageUrl(file)
    const isUnique = !form.getFieldValue('photos')?.some((item:any) => item.uid === file.uid)
    if (isUnique) {
      setPreviews([...previews, {uid: file.uid, url: imageUrl}])
      const prevPhotos = form.getFieldValue('photos')
      form.setFieldsValue({photos: [...(prevPhotos?.length ? prevPhotos : []), file]})
    }
  }

  const removePhoto = (uid: string) => {
    setPreviews(previews?.filter(photo => photo.uid !== uid))

    if (isEditing) {
      const photosForDeleting = form.getFieldValue('delete_photos')
      form.setFieldsValue({'delete_photos': [...(photosForDeleting?.length ? photosForDeleting : []), uid]})
    } else {
      form.setFieldsValue({
        photos: form.getFieldValue('photos')?.filter((photo:any) => photo.uid !== uid)
      })
    }
  }

  return (
    <div>          
      <div className={`${classes.wrapper} ${previews?.length && classes.previews}`}>
        <h2>
          Add photo
        </h2>
        <div className={classes.imagesAreaWrapper}>
          {previews?.length > 0 && previews.map(({url, uid}) => (
            <div className={classes.imagePreviewWrapper} key={url}>
              <img src={url} alt='preview' />
              <div className={classes.removePhotoBtn}>
                <CloseIcon onClick={() => removePhoto(uid)}/>
              </div>
            </div>
          ))}
          <Upload.Dragger
            name='files'
            customRequest={dummyRequest}
            beforeUpload={beforeUpload}
            className={`${classes.uploadDragger} ${previews?.length > 0 && classes.addMore}`}
            accept={'image/png, image/jpeg'}
            maxCount={10}
            onChange={normFile}
            showUploadList={false}
          >
            {previews?.length ? (
              <>
                <PictureIcon />
                <div>
                  + Add more
                </div>
              </>
            ) : (
              <>
                <UploadIcon />
                <div className={classes.uploadTitle}>
                  Click or drag photo to this area to upload
                </div>
                <div className={classes.uploadDescription}>
                  Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
                </div>
              </>
            )}
          </Upload.Dragger>
        </div>
      </div>
    </div>
  )
}

interface PhotoTabPropTypes {
  form: FormInstance
  isEditing: boolean
}

interface ImagePreviewType {
  uid: string
  url: string
}

export default PhotoTab
