import { Button, Divider, Form, FormInstance, Input, Modal, Radio, Select } from 'antd'
import { orderBy } from 'lodash'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import classes from './../ServiceDetailsForm.module.css'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { CreateServiceCategoryThunk, EditServiceCategoryThunk, GetServiceCategorySearchTypesThunk, selectServiceCategories, selectServiceCategorySearchTypes } from '../../../../store/servicesReducer'
import {ReactComponent as EditIcon} from './../../../../img/icons/edit.svg'
import InputField from '../../../common/formFields/InputField'
import UploadLogoField from '../UploadLogoField/UploadLogoField'
import { NewServiceCategoryType, ServiceCategoryType } from '../../../../types/serviceTypes'
import { isValidHTML } from '../../../../helpers/files_helper'

const MainData: React.FC<MainDataPropTypes> = ({isEditing, form, setDeleteLogo, setBannerColor, setSelectedBannerOption, selectedBannerOption}) => {
  const htmlCode = Form.useWatch('banner_html', form)

  const [bannerCodeForGeneration, setBannerCodeForGeneration] = useState('')
  const [bannerCodeValidationError, setBannerCodeValidationError] = useState(false)

  useEffect(() => {
    if (isEditing && selectedBannerOption === 'html' && htmlCode?.length && !bannerCodeForGeneration.length) {
      generateBannerFromHTML()
    }
    // eslint-disable-next-line
  }, [isEditing, htmlCode, selectedBannerOption, bannerCodeForGeneration])

  const onBannerTypeChange = (type: 'generated' | 'html') => {
    form.setFieldValue('active_banner_type', type === 'generated' ? 'generated' : 'html')
    setSelectedBannerOption(type)
  }

  const generateBannerFromHTML = () => {
    const isValid = isValidHTML(htmlCode)

    if (!isValid) {
      setBannerCodeValidationError(true)
      setBannerCodeForGeneration('')
    } else {
      setBannerCodeValidationError(false)
      setBannerCodeForGeneration(htmlCode)
    }
  }

  return (
    <div className={classes.formBlock} style={{overflow: 'hidden'}}>
      <div className={classes.doubleInputArea}>
        <InputField
          name='name'
          requiredMessage='Please enter Company ID!'
          placeholder='Enter Company ID'
          label='Company ID'
        />
        <InputField
          name='display_name'
          requiredMessage='Please enter service display name!'
          placeholder='Enter Display Name'
          label='Display Name'
        />
      </div>

      <div className={classes.doubleInputArea}>
        <InputField
          name='ranking'
          requiredMessage='Please enter service ranking!'
          placeholder='Enter Ranking'
          label='Ranking'
          type='number'
          fieldExplanation='Higher value means displayed earlier. 0 means do not display'
          minNumber={0}
        />
        <CategoryField form={form}/>
      </div>

      <div style={{marginBottom: '10px'}}>
        <Radio.Group onChange={e => onBannerTypeChange(e.target.value)} value={selectedBannerOption}>
          <Radio value='generated'>Generate a banner</Radio>
          <Radio value='html'>HTML code</Radio>
        </Radio.Group>
      </div>
      
      {selectedBannerOption === 'generated' ? (
        <div className={classes.doubleInputArea}>
          <InputField
            name='comment'
            placeholder='Enter Comment'
            label='Comment'
            rows={6}
          />
          <UploadLogoField
            form={form}
            isEditing={isEditing}
            setDeleteLogo={setDeleteLogo}
            setBannerColor={setBannerColor}
          />
        </div>
      ) : (
        <>
          <InputField
            name='banner_html'
            placeholder='HTML code'
            label='Enter HTML code (required height: 82px)'
            rows={3}
          />
          <Button
            type='default'
            disabled={!htmlCode?.length}
            style={{marginBottom: '10px', marginTop: '-10px'}}
            onClick={generateBannerFromHTML}
          >
            Generate
          </Button>
          {bannerCodeForGeneration?.length ? (
            <div className={classes.bannerFromCode} dangerouslySetInnerHTML={{__html: bannerCodeForGeneration}}></div>
          ) : (
            <div className={classes.bannerFromCodeEmpty}>
              {bannerCodeValidationError
                ? 'HTML code is invalid! Check your code and press "Generate" again'
                : 'Here will be displayed banner from HTML code'
              }
            </div>
          )}
        </>
      )}
    </div>
  )
}

const CategoryField: React.FC<{form: FormInstance}> = ({form}) => {
  const dispatch = useAppDispatch()
  const serviceCategories = useAppSelector(selectServiceCategories)

  const [isLoading,  setIsLoading] = useState(false)
  const [isModalOpen,  setIsModalOpen] = useState(false)
  const [editingCategory,  setEditingCategory] = useState<ServiceCategoryType | null>()

  const onCloseModal = () => {
    setEditingCategory(null)
    setIsModalOpen(false)
  }

  const editCategory = (e: any, category: ServiceCategoryType) => {
    e.stopPropagation()
    setEditingCategory(category)
    setIsModalOpen(true)
  }

  const handleCategorySave = (category: ServiceCategoryType | NewServiceCategoryType) => {
    const add_relations_with_type_ids = !!editingCategory
      ? category.search_type_list.filter(t => !editingCategory?.search_type_list.some(type => type.id === t.id)).map(type => type.id)
      : category.search_type_list.map(t => t.id)
    const delete_relations_with_type_ids = !!editingCategory
    ? editingCategory?.search_type_list.filter(t => !category.search_type_list.some(type => type.id === t.id)).map(type => type.id)
    : []

    const formData = new FormData()
    formData.append('update_settings', new Blob([JSON.stringify({add_relations_with_type_ids, delete_relations_with_type_ids}, null, 2)], {type: 'application/json'}))
    formData.append('service_category', new Blob([JSON.stringify({name: category.name}, null, 2)], {type: 'application/json'}))
    setIsLoading(true)
    dispatch(!editingCategory ? CreateServiceCategoryThunk(formData) : EditServiceCategoryThunk({formData, service_category_id: editingCategory.service_category_id}))
    .then(resp => {
      setIsLoading(false)
      if (!resp.type.includes('rejected')) {
        setIsModalOpen(false)
        setEditingCategory(null)
      }
    })
  }

  return (
    <div style={{ minWidth: 0, minHeight: 0}}>
      <div className={classes.label}>
        Category
      </div>
      <Form.Item
        name='category_id'
        rules={[{ required: true, message: 'Please select category!' }]}
      >
        <Select
          placeholder='Please select'
          optionLabelProp='label'
          dropdownStyle={{zIndex: 1}}
          dropdownRender={(menu) => (
            <>
              {menu}
              <Divider style={{ margin: '8px 0' }} />
              <Button type='text' style={{width: '100%'}} onClick={() => setIsModalOpen(true)}>
                + Add new category
              </Button>
            </>
          )}
        >
          {orderBy(serviceCategories, [type => type.name?.toLowerCase()])?.map(category => (
            <Select.Option
              value={category?.service_category_id}
              label={category?.name}
              key={category?.service_category_id}
              className={classes.categoryOption}
            >
              <div style={{whiteSpace: 'initial'}}>{category?.name}</div>
              <EditIcon onClick={(e) => editCategory(e, category)}/>
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Modal
        title={!!editingCategory ? 'Edit category' : 'Add new category'}
        visible={isModalOpen}
        className={classes.modal}
        footer={false}
        closable={false}
        destroyOnClose
        style={{zIndex: 100}}
      >
        <ServiceCategoryForm
          isSaving={isLoading}
          onCloseModal={onCloseModal}
          category={editingCategory}
          handleCategorySave={handleCategorySave}
        />
      </Modal>
    </div>
  )
}

const ServiceCategoryForm: React.FC<ServiceCategoryFormPropTypes> = ({onCloseModal, isSaving, category, handleCategorySave}) => {
  const dispatch = useAppDispatch()
  const serviceCategories = useAppSelector(selectServiceCategories)
  const serviceCategorySearchTypes = useAppSelector(selectServiceCategorySearchTypes)
    const ref = useRef();
  const [formValues,  setFormValues] = useState<ServiceCategoryType | NewServiceCategoryType>({name: '', search_type_list: []})
  const [error,  setError] = useState('')

  useEffect(() => {
    dispatch(GetServiceCategorySearchTypesThunk())
  }, [dispatch])

  useEffect(() => {
    if (!!category) {
      setFormValues(category!)
    }
  }, [category, serviceCategories])

  const handleCategorySearchTypeChange = (actionType: 'select' | 'deselect', types: number[], ref: any) => {
    if (actionType === 'select') {
      setFormValues({...formValues, search_type_list: serviceCategorySearchTypes.filter(t => types.includes(t.id))})
    } else {
      setFormValues({...formValues, search_type_list: formValues?.search_type_list.filter(type => type.id !== types[0])})
    }
    ref.current.blur()
  }

  const handleSave = () => {
    if (!formValues?.name?.length) {
      setError('Name is required!')
    } else {
      handleCategorySave(formValues)
    }
  }

  return (
    <>
      <Input
        placeholder='Category Name'
        onChange={(e) => {
          setError('')
          setFormValues({...formValues, name: e.target.value})
        }}
        value={formValues.name}
        style={{ width: '100%', marginBottom: '30px' }}
      />
      <div className={classes.label}>
        Active for search types:
      </div>
        {/*// @ts-ignore*/}
      <Select mode='multiple' style={{ width: '100%' }} placeholder='Please select' ref={ref}
        value={formValues.search_type_list.map(type => type.id)}
        onChange={(values) => handleCategorySearchTypeChange('select', values, ref)}
        onDeselect={(val: number) => handleCategorySearchTypeChange('deselect', [val], ref) }
      >
        {serviceCategorySearchTypes.map(type => (
          <Select.Option key={type.id} value={type.id}>
            {type.name}
          </Select.Option>
        ))}
      </Select>
      {!!error &&
        <div className={classes.categoryFormError}>{error}</div>
      }
      <div className={classes.buttonsAreaWrapper}>
        <Button onClick={() => onCloseModal()}>
          Cancel
        </Button>
        <Button
          type='primary'
          htmlType='submit'
          loading={isSaving}
          onClick={handleSave}
        >
          Save
        </Button>
      </div>
    </>
  )
}

interface MainDataPropTypes {
  form: FormInstance
  isEditing: boolean
  setDeleteLogo: Dispatch<SetStateAction<boolean>>
  setBannerColor: Dispatch<SetStateAction<string>>
  selectedBannerOption: 'generated' | 'html'
  setSelectedBannerOption: Dispatch<SetStateAction<'generated' | 'html'>>
}

interface ServiceCategoryFormPropTypes {
  onCloseModal: () => void
  isSaving: boolean
  category?: ServiceCategoryType | null
  handleCategorySave: (category: ServiceCategoryType | NewServiceCategoryType) => void
}

export default MainData
