import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { faqAPI } from '../app/api'
import { AppStatusType } from './appStatusReducer'
import { AsyncThunkConfig, RootState } from './store'
import { SignOutThunk } from './userReducer'
import { FaqEntriesFetchParams, FaqEntryType, NewFaqEntryType } from '../types/faqTypes'

const faqEntriesFetchParamsDefaultValue: FaqEntriesFetchParams = {
  search_value: ''
}

interface InitialStateType {
  faqEntries: null | FaqEntryType[]
  faqEntriesTotalCount: number
  faqEntriesFetchParams: FaqEntriesFetchParams
  currentFaqEntry: null | FaqEntryType
}

const initialState: InitialStateType = {
  faqEntries: [],
  faqEntriesTotalCount: 0,
  faqEntriesFetchParams: faqEntriesFetchParamsDefaultValue,
  currentFaqEntry: null,
}

export const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFaqEntries: (state, action: PayloadAction<null | FaqEntryType[]>) => {state.faqEntries = action.payload},
    setFaqEntriesTotalCount: (state, action: PayloadAction<number>) => {state.faqEntriesTotalCount = action.payload},
    setFaqEntriesFetchParams: (state, action: PayloadAction<FaqEntriesFetchParams>) => {state.faqEntriesFetchParams = action.payload},
    setCurrentFaqEntry: (state, action: PayloadAction<FaqEntryType | null>) => {state.currentFaqEntry = action.payload},
  },
  extraReducers: (builder) => {
    builder
      .addCase(GetAllFaqEntriesThunk.fulfilled, (state, action) => {
        state.faqEntries = action.payload
        // state.faqEntriesTotalCount = action.payload.blog_entries_total_count
      })
      .addCase(GetFaqEntryByIdThunk.fulfilled, (state, action) => {
        state.currentFaqEntry = action.payload
      })
      .addCase(CreateFaqEntryThunk.fulfilled, (state, action) => {
        state.faqEntries = [...state.faqEntries || [], action.payload]
      })
      // .addCase(EditFaqEntryThunk.fulfilled, (state, action) => {
      //   state.faqEntries = state.faqEntries?.map(entry => {
      //     return entry.id === action.payload.id ? action.payload : entry
      //   }) || []
      // })
      .addCase(DeleteFaqEntryThunk.fulfilled, (state, action) => {
        state.faqEntries = state.faqEntries?.filter(entry => entry.id !== action.payload) || []
      })
      .addCase(SignOutThunk.fulfilled, (state) => {
        state.faqEntries = null
        state.faqEntriesTotalCount = 0
        state.faqEntriesFetchParams = faqEntriesFetchParamsDefaultValue
      })
  }
})

export const {
  setFaqEntries,
  setFaqEntriesTotalCount,
  setFaqEntriesFetchParams,
  setCurrentFaqEntry,
} = faqSlice.actions

export const selectFaqEntries = (state: RootState): null | FaqEntryType[] => state.faq.faqEntries
export const selectFaqEntriesTotalCount = (state: RootState): number => state.faq.faqEntriesTotalCount
export const selectFaqEntriesFetchParams = (state: RootState): FaqEntriesFetchParams => state.faq.faqEntriesFetchParams
export const selectCurrentFaqEntry = (state: RootState): FaqEntryType | null => state.faq.currentFaqEntry

export const GetAllFaqEntriesThunk = createAsyncThunk<FaqEntryType[], {fetchParams: FaqEntriesFetchParams, source: any}, AsyncThunkConfig>(
  'faq/getAllBlogEntries',
  async ({fetchParams, source}, thunkAPI) => {
    try {
      const { status, data } = await faqAPI.getFaqList(fetchParams.search_value, source)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data.page_sections, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const GetFaqEntryByIdThunk = createAsyncThunk<FaqEntryType, number, AsyncThunkConfig>(
  'faq/getFaqEntryById',
  async (entryId, thunkAPI) => {
    try {
      const { status, data } = await faqAPI.getFaqEntryById(entryId)
      console.log('data', data)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const CreateFaqEntryThunk = createAsyncThunk<FaqEntryType, {content: NewFaqEntryType, images?: any[]}, AsyncThunkConfig>(
  'faq/createFaqEntry',
  async ({content, images}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('page_section', new Blob([JSON.stringify(content, null, 2)], {type: 'application/json'}))
      images?.length && images.forEach((p) => formData.append('images', p?.file?.originFileObj))
      const { status, data } = await faqAPI.createFaqEntry(formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const EditFaqEntryThunk = createAsyncThunk<FaqEntryType, {entryId: number, faqEntry: NewFaqEntryType, images: any[]}, AsyncThunkConfig>(
  'faq/editFaqEntry',
  async ({entryId, faqEntry, images}, thunkAPI) => {
    try {
      const formData = new FormData()
      formData.append('page_section', new Blob([JSON.stringify(faqEntry, null, 2)], {type: 'application/json'}))
      images.forEach((p) => formData.append('images', p.originFileObj))
      const { status, data } = await faqAPI.editFaqEntry(entryId, formData)
      if (status === 200 && data) {
        return thunkAPI.fulfillWithValue(data, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export const DeleteFaqEntryThunk = createAsyncThunk<number, number, AsyncThunkConfig>(
  'faq/deleteFaqEntry',
  async (faqEntryId, thunkAPI) => {
    try {
      const { status, data } = await faqAPI.deleteFaqEntry(faqEntryId)
      if (status === 200) {
        return thunkAPI.fulfillWithValue(faqEntryId, {appStatus: AppStatusType.idle})
      } else {
        return thunkAPI.rejectWithValue(data)
      }
    } catch (error: any)  {
      return thunkAPI.rejectWithValue(error?.response?.data?.message || error.message)
    }
  }
)

export default faqSlice.reducer
 